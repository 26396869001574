import { TColumns } from '^/chadmin';
import React from 'react';
import { Link } from 'react-router-dom';

import { ClassListClass } from '^/app/authenticated/classes/types';
import { formatDate } from '^/common/utils/format-date';

export const classListConfig: TColumns<ClassListClass> = [
  {
    name: 'name',
    display_name: 'Name',
    type: 'text',
  },
  {
    name: 'student_count',
    display_name: 'Students',
    type: 'text',
  },
  {
    name: 'created_display',
    display_name: 'Date Created',
    type: 'custom',
    customItemHandler(item) {
      return formatDate(item.created);
    },
  },
  {
    name: 'is_extracurricular_display',
    display_name: 'Group?',
    type: 'custom',
    customItemHandler(item) {
      return item.is_extracurricular ? 'Yes' : 'No';
    },
  },
  {
    name: 'action',
    display_name: ' ',
    type: 'custom',
    customItemHandler(item) {
      const url = `/classes/edit-class/${item.id}`;
      return (
        <Link to={url} className="button pill hollow">
          View
        </Link>
      );
    },
  },
];

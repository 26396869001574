import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'underscore';
import { GET_COLLECTION } from '@dabapps/redux-api-collections';
import { isPending } from '@dabapps/redux-requests';

import { FilterOption } from '^/app/authenticated/activities/overview/students/filters/types';
import { STUDENTS_COLLECTION } from '^/app/authenticated/activities/overview/students/list/constants';
import { collections } from '^/common/collections';
import LoadingSpinner from '^/common/loading-spinner';
import { CurrentSchool } from '^/common/schools/reducers';
import { StoreState } from '^/store/types';
import {
  INPUT_DEBOUNCE_TIME,
  SEARCH_FIELD_MAXLENGTH,
} from '^/common/constants';
import { ACTIVITIES_LABELS } from '^/common/labels-english';
import ErrorMessage from '^/common/error-handling/error-message';

import {
  addFilterOption,
  removeFilterOption,
  updateSearchValue,
} from './actions';
import {
  ASSESSMENT_STATUS_FILTER,
  CLASS_FILTER,
  EAL_FILTER,
  ealOptions,
  ETHNICITY_FILTER,
  ethnicityOptions,
  GENDER_FILTER,
  genderOptions,
  PUPIL_PREMIUM_FILTER,
  pupilPremiumOptions,
  SEND_FILTER,
  sendOptions,
  statusOptions,
  YEAR_FILTER,
  GROUP_FILTER,
} from './constants';
import Filter from './filter';
import SelectedFilter from './selected-filter';

import CommonApis from '^/api-services/common/common.service';
import {
  IYearGroupsRequest,
  IYearGroupsResponse,
} from '^/api-services/common/types';
import useAPI from '^/api-services/useApi';
import { BasicSearchField } from '^/app/components';

const {
  actions: { getCollection },
} = collections;

interface StateProps extends Pick<StoreState, 'selectedDashboardFilters'> {
  currentSchool: CurrentSchool;
  isLoading: boolean;
}

interface DispatchProps {
  getCollection: typeof getCollection;
  addFilterOption: typeof addFilterOption;
  removeFilterOption: typeof removeFilterOption;
  updateSearchValue: typeof updateSearchValue;
}

interface IFilterProps {
  changePage(page: number, pageSize?: number): void;
}

type Props = StateProps & DispatchProps & IFilterProps;

const Filters: React.FC<Props> = (props: Props) => {
  const [classGroups, setClassGroups] = useState<FilterOption[]>([]);
  const [yearGroups, setYearGroups] = useState<FilterOption[]>([]);
  const [otherGroups, setOtherGroups] = useState<FilterOption[]>([]);
  const { callAPI: getClassGroups, ...getClassGroupsResponse } = useAPI<
    IYearGroupsRequest,
    IYearGroupsResponse
  >(CommonApis.getClassGroups);

  const { callAPI: getYearGroups, ...getYearGroupsResponse } = useAPI<
    IYearGroupsRequest,
    IYearGroupsResponse
  >(CommonApis.getYearGroups);

  const { callAPI: getOtherGroups, ...getOtherGroupsResponse } = useAPI<
    IYearGroupsRequest,
    IYearGroupsResponse
  >(CommonApis.getOtherGroups);
  // fetch class groups and format
  useEffect(() => {
    if (currentSchool) {
      getClassGroups({ school: currentSchool });
      getYearGroups({ school: currentSchool });
      getOtherGroups({ school: currentSchool });
    }
  }, []);

  useEffect(() => {
    if (getClassGroupsResponse.response) {
      const classGroups: FilterOption[] = [];
      getClassGroupsResponse.response.results.forEach((item) => {
        classGroups.push({ label: item.name, value: item.id });
      });
      setClassGroups(classGroups);
    }
  }, [getClassGroupsResponse.response]);

  useEffect(() => {
    if (getOtherGroupsResponse.response) {
      const classGroups: FilterOption[] = [];
      getOtherGroupsResponse.response.results.forEach((item) => {
        classGroups.push({ label: item.name, value: item.id });
      });
      setOtherGroups(classGroups);
    }
  }, [getOtherGroupsResponse.response]);

  useEffect(() => {
    if (getYearGroupsResponse.response) {
      const yearGroups: FilterOption[] = [];
      getYearGroupsResponse.response.results.forEach((item) => {
        yearGroups.push({ label: item.name, value: item.id });
      });
      setYearGroups(yearGroups);
    }
  }, [getYearGroupsResponse.response]);

  const { currentSchool, isLoading, selectedDashboardFilters } = props;
  const { filters, searchQuery } = selectedDashboardFilters;

  const resetPage = () => {
    // this will make parent component to perform api call
    props.changePage(1, undefined);
  };

  const onSelectFilterOption = (filter: string, option: FilterOption) => {
    props.addFilterOption(filter, option);
    resetPage();
  };

  const onRemoveFilterOption = (filter: string, option: FilterOption) => {
    props.removeFilterOption(filter, option);
    resetPage();
  };

  const throttledGetUserApi = useCallback(
    debounce(() => {
      resetPage();
    }, INPUT_DEBOUNCE_TIME),
    [selectedDashboardFilters]
  );

  const onSearchQueryInput = (input: string) => {
    props.updateSearchValue(input);
    throttledGetUserApi();
  };

  if (getClassGroupsResponse.error) {
    return <ErrorMessage />;
  }

  if (getClassGroupsResponse.loading) {
    return <LoadingSpinner className="small" />;
  }

  return (
    <div className="dashboard-filters margin-bottom-base">
      <div className="d-flex">
        <Filter
          title="Year"
          filter={YEAR_FILTER}
          options={yearGroups}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[YEAR_FILTER] || []}
          width={66}
        />
        <Filter
          title="Class"
          filter={CLASS_FILTER}
          options={classGroups}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[CLASS_FILTER] || []}
          width={66}
        />
        <Filter
          title="Groups"
          filter={GROUP_FILTER}
          options={otherGroups}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[GROUP_FILTER] || []}
          width={82}
        />
        <Filter
          title="Gender"
          filter={GENDER_FILTER}
          options={genderOptions}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[GENDER_FILTER] || []}
          width={82}
        />
        <Filter
          title="SEND"
          filter={SEND_FILTER}
          options={sendOptions}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[SEND_FILTER] || []}
          width={80}
        />
        <Filter
          title="Ethnicity"
          filter={ETHNICITY_FILTER}
          options={ethnicityOptions}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[ETHNICITY_FILTER] || []}
          width={89}
        />
        <Filter
          title="E.A.L."
          filter={EAL_FILTER}
          options={ealOptions}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[EAL_FILTER] || []}
          width={68}
        />

        <Filter
          title="Pupil Premium"
          filter={PUPIL_PREMIUM_FILTER}
          options={pupilPremiumOptions}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[PUPIL_PREMIUM_FILTER] || []}
          width={129}
        />
        <Filter
          title="Assessment Status"
          filter={ASSESSMENT_STATUS_FILTER}
          options={statusOptions}
          onSelectOption={onSelectFilterOption}
          selectedOptions={filters[ASSESSMENT_STATUS_FILTER] || []}
          width={155}
        />
        <BasicSearchField
          containerClassName="ml-auto"
          className="user-search-input"
          placeholder={ACTIVITIES_LABELS.SEARCH_STUDENTS}
          maxLength={SEARCH_FIELD_MAXLENGTH}
          value={searchQuery}
          onChange={(e) => onSearchQueryInput(e.target.value)}
          allowClear={true}
          onSearch={() => throttledGetUserApi()}
          size="small"
          style={{ width: '18em' }}
          loading={searchQuery ? isLoading : false}
        />
      </div>
      <hr className="large margin-top-x-small margin-bottom-none" />
      <div>
        {Object.keys(filters).map((filter) =>
          filters[filter].map((option) => (
            <SelectedFilter
              key={`${filter}-${option.value}`}
              option={option}
              filter={filter}
              onRemove={onRemoveFilterOption}
            />
          ))
        )}
      </div>
    </div>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  const { currentSchool, responses, selectedDashboardFilters } = state;
  return {
    currentSchool,
    selectedDashboardFilters,
    isLoading: isPending(responses, GET_COLLECTION, STUDENTS_COLLECTION),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  addFilterOption,
  removeFilterOption,
  updateSearchValue,
})(Filters);

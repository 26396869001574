import React from 'react';
import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input/Search';
import './basic-search-field-style.less';

export const BasicSearchField: React.FC<IBasicSearchField> = (
  props: IBasicSearchField
) => {
  const { containerClassName, ...rest } = props;
  const { Search } = Input;
  return (
    <span className={'basic-search-wrapper ' + containerClassName}>
      <Search {...rest} />
    </span>
  );
};

interface IBasicSearchField extends SearchProps {
  containerClassName?: string;
}

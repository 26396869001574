import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Column, Container, ContentBox, Row, SpacedGroup } from '@dabapps/roe';
import { Pagination, Table, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'antd/node_modules/moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'underscore';

import { BasicSearchField } from '^/app/components';
import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import {
  IGetUserResponse,
  IGetUsersRequest,
  IUserGeneric,
  UserListUser,
} from '^/app/authenticated/users/types';
import { StoreState } from '^/store/types';
import { DownloadOutlined } from '@ant-design/icons';
import useAPI from '^/api-services/useApi';
import UsersApi from '^/api-services/users/users.service';
import {
  INPUT_DEBOUNCE_TIME,
  SEARCH_FIELD_MAXLENGTH,
  PAGE_SIZE,
  DATE_RANGE_FORMAT,
} from '^/common/constants';

import { USER_LABELS } from '^/common/labels-english';
import './users-list-styles.less';
import { LoggedInUser, UserRoles } from '^/common/authentication/types';
import { loggedInUserIsTeacher } from '^/common/utils/roles';

interface StateProps {
  loggedInUser: LoggedInUser | null;
  currentSchool: string | null;
}

type Props = StateProps;

export function Users(props: Props) {
  const { callAPI: fireGetUsersApi, ...getUsersResponse } = useAPI<
    IGetUsersRequest,
    IGetUserResponse<IUserGeneric>
  >(UsersApi.getUsersApi);

  const [searchQuery, setSearchQuery] = useState('');
  // this state is needed to avoid api call on page number change which happens on user search
  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: PAGE_SIZE,
  });

  const {
    loading: isLoading,
    response = {} as IGetUserResponse<IUserGeneric>,
    error: requestHasFailed,
  } = getUsersResponse;

  const { currentSchool, loggedInUser } = props;

  useEffect(() => {
    fetchUsers({
      pageNumber: pageInfo.page,
      school: currentSchool,
      pageSize: pageInfo.pageSize,
      search: searchQuery,
    });
  }, [pageInfo]);

  // api call for getting users
  const fetchUsers = (data: IGetUsersRequest) => {
    if (currentSchool) {
      fireGetUsersApi(data);
    }
  };

  const resetPageInfo = () => {
    setPageInfo({
      page: 1,
      pageSize: PAGE_SIZE,
    });
  };

  const throttledGetUserApi = useCallback(
    debounce(resetPageInfo, INPUT_DEBOUNCE_TIME),
    [currentSchool]
  );

  const onSearchQueryInput = (input: string) => {
    setSearchQuery(input);
    throttledGetUserApi();
  };

  const action = (user: UserListUser) => {
    return (
      <Space>
        <Link
          className="button pill"
          to={{
            pathname: `users/edit-user/${user.id}`,
          }}
        >
          {USER_LABELS.edit}
        </Link>
      </Space>
    );
  };

  const columnLabels = USER_LABELS.columns;

  const getColumns = useMemo(() => {
    const columns: ColumnsType<UserListUser> = [
      {
        title: columnLabels.name,
        dataIndex: 'name',
        fixed: 'left',
        // eslint-disable-next-line react/display-name
        render: (_: undefined, row: UserListUser) => (
          <>
            <p className="margin-none">
              {row.first_name} {row.last_name}
            </p>
          </>
        ),
      },
      {
        title: columnLabels.role,
        dataIndex: 'role_display',
        // eslint-disable-next-line react/display-name
        render: (value: string) => value,
      },
      {
        title: columnLabels.dateCreated,
        dataIndex: 'created',
        // eslint-disable-next-line react/display-name
        render: (value: string) => moment(value).format(DATE_RANGE_FORMAT),
      },
      {
        title: columnLabels.action,
        dataIndex: 'action',
        // eslint-disable-next-line react/display-name
        render: (_: undefined, row: UserListUser) =>
          loggedInUserIsTeacher(loggedInUser) &&
          row.role === UserRoles.LEAD_TEACHER
            ? null
            : action(row),
      },
    ];

    return columns;
  }, []);

  const changePage = (page: number, pageSize?: number) => {
    setPageInfo({
      page: page,
      pageSize: pageSize ? pageSize : pageInfo.pageSize,
    });
  };

  return (
    <Container className="margin-top-large">
      <Row>
        <Column sm={12} md={3}>
          <Breadcrumbs breadcrumbs={[{ label: 'Users', path: '/users' }]} />
        </Column>
        <Column sm={12} md={9}>
          <SpacedGroup className="display-block text-align-right">
            <BasicSearchField
              className="user-search-input"
              placeholder={USER_LABELS.searchUsers}
              maxLength={SEARCH_FIELD_MAXLENGTH}
              value={searchQuery}
              onChange={(e) => onSearchQueryInput(e.target.value)}
              allowClear={true}
              onSearch={(e) => throttledGetUserApi(e)}
              size="small"
              style={{ width: '16em' }}
              loading={searchQuery ? isLoading : false}
            />
            <Link className="button hollow pill" to="/users/import-users">
              {USER_LABELS.importStudents}
            </Link>
            <a
              className="button hollow pill"
              href={`/api/schools/student-export/?school=${currentSchool}&search=${searchQuery}`}
            >
              {USER_LABELS.exportStudents}{' '}
              <DownloadOutlined className="icon icon-button" />
            </a>
            <Link to="/users/add-user" className="button pill">
              {USER_LABELS.addUser}
            </Link>
          </SpacedGroup>
        </Column>
      </Row>

      <ContentBox>
        <Pagination
          current={pageInfo.page}
          total={getUsersResponse.response?.count}
          pageSize={pageInfo.pageSize}
          onChange={changePage}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger
          disabled={getUsersResponse.loading}
        />
        <Table
          rowKey="id"
          columns={getColumns}
          dataSource={getUsersResponse.response?.results || []}
          pagination={false}
          loading={getUsersResponse.loading}
        />
      </ContentBox>
    </Container>
  );
}

export function mapStateToProps(state: StoreState): StateProps {
  return {
    loggedInUser: state.loggedInUser,
    currentSchool: state.currentSchool,
  };
}

export default connect(mapStateToProps)(Users);

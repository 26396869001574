import { ACTIVITY_COLUMNS } from '^/app/authenticated/activities/activity-page/config';
import { AdminList } from '^/chadmin';
import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import React, { useEffect, useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import ActivityApi from '^/api-services/activities/activities.service';
import {
  IActivityListItem,
  IStudentActivitiesRequest,
  Pagination,
} from '^/api-services/activities/types';
import useAPI from '^/api-services/useApi';
import { ModalPopup } from '^/app/components';

import './activities-table-styles.less';
import { PAGE_SIZE } from '^/common/constants';

interface Props {
  userId: string;
}

export const ActivitiesTable: React.FC<Props> = (props: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isPreviewOpen, setPreviewOpen] = useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = useState<string>('');
  const [previewType, setPreviewType] = useState<string>('PHOTO');
  const {
    callAPI: getStudentActivities,
    ...studentActivitiesResponse
  } = useAPI<IStudentActivitiesRequest, Pagination<IActivityListItem>>(
    ActivityApi.getStudentActivities
  );

  const fetchActivities = (page = 1) => {
    getStudentActivities({
      student: props.userId,
      page: page,
      page_size: PAGE_SIZE,
    });
  };

  useEffect(() => {
    if (props.userId) {
      fetchActivities();
    }
  }, [props.userId]);

  const { response, loading, error } = studentActivitiesResponse;

  const changePage = (page: number) => {
    setCurrentPage(page);
    fetchActivities(page);
  };

  const openPreview = (photoUrl: string, type = 'PHOTO') => {
    setPreviewType(type);
    setPhotoUrl(photoUrl);
    setPreviewOpen(true);
  };

  return loading || !response ? (
    <LoadingSpinner />
  ) : error ? (
    <div className="padding-vertical-large">
      <ErrorMessage />
    </div>
  ) : (
    <div className="table-top-align student-activities">
      <AdminList
        items={response.results}
        itemCount={response.count}
        columns={ACTIVITY_COLUMNS(openPreview)}
        listName="list"
        changePage={changePage}
        page={currentPage}
        pageSize={PAGE_SIZE}
      />
      <ModalPopup
        visible={isPreviewOpen}
        className="image-preview"
        onCancel={() => setPreviewOpen(false)}
        footer={null}
        width="auto"
        closeIcon={<CloseCircleFilled />}
      >
        {previewType === 'PHOTO' ? (
          <img src={photoUrl} alt="activity image" />
        ) : (
          <video src={photoUrl} controls={true} />
        )}
      </ModalPopup>
    </div>
  );
};

import React from 'react';
import { Space, Button, Popconfirm } from 'antd';
import {
  SaveOutlined,
  CloseCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { IClusterAdminRecord, ClusterAdminColumnsIndex } from './types';
import { CLUSTER_ADMIN_LABELS } from '^/common/labels-english';

interface IClusterAdminColumns {
  isEditing: (record: IClusterAdminRecord) => boolean;
  save: (key: React.Key) => void;
  editingKey: string;
  edit: (record: IClusterAdminRecord) => void;
  onDelete: (record: IClusterAdminRecord) => void;
  cancel: (record: IClusterAdminRecord) => void;
}

export const ClusterAdminColumns = (params: IClusterAdminColumns) => {
  const { cancel, save, isEditing, editingKey, edit, onDelete } = params;
  const { columns } = CLUSTER_ADMIN_LABELS;
  return [
    {
      title: CLUSTER_ADMIN_LABELS.fName,
      dataIndex: ClusterAdminColumnsIndex.FNAME,
      width: '22.5%',
      editable: true,
    },
    {
      title: CLUSTER_ADMIN_LABELS.lName,
      dataIndex: ClusterAdminColumnsIndex.LNAME,
      width: '22.5%',
      editable: true,
    },
    {
      title: CLUSTER_ADMIN_LABELS.email,
      dataIndex: ClusterAdminColumnsIndex.EMAIL,
      width: '20%',
      inputType: 'email',
      editable: true,
    },
    {
      title: CLUSTER_ADMIN_LABELS.tempPassword,
      dataIndex: ClusterAdminColumnsIndex.TEMP,
      width: '22%',
      editable: true,
    },
    {
      title: CLUSTER_ADMIN_LABELS.isClusterManager,
      dataIndex: ClusterAdminColumnsIndex.ISMANAGER,
      width: '5%',
      inputType: 'boolean',
      editable: true,
    },
    {
      title: '',
      dataIndex: ClusterAdminColumnsIndex.OPERATION,
      width: '8%',
      render: function renderActions(_: any, record: IClusterAdminRecord) {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button
              onClick={() => save(record.key)}
              shape="circle"
              icon={<SaveOutlined />}
              size="large"
              title={columns.save}
            />
            <Popconfirm
              title={CLUSTER_ADMIN_LABELS.areYouSure}
              onConfirm={() => cancel(record)}
              okText={CLUSTER_ADMIN_LABELS.yes}
              cancelText={CLUSTER_ADMIN_LABELS.no}
              overlayClassName="cluster-popover"
            >
              <Button
                shape="circle"
                icon={<CloseCircleOutlined />}
                size="large"
                title={columns.cancel}
              />
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <Button
              shape="circle"
              type="link"
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              icon={<EditOutlined />}
              size="large"
              title={columns.edit}
            />
            <Popconfirm
              title={CLUSTER_ADMIN_LABELS.areYouSure}
              okText={CLUSTER_ADMIN_LABELS.yes}
              cancelText={CLUSTER_ADMIN_LABELS.no}
              onConfirm={() => onDelete(record)}
              disabled={editingKey !== ''}
              overlayClassName="cluster-popover"
            >
              <Button
                shape="circle"
                type="link"
                disabled={editingKey !== ''}
                icon={<DeleteOutlined />}
                size="large"
                title={columns.delete}
              />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
};

import React from 'react';
import {
  Bar,
  BarChart,
  Brush,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LegendProps,
  Tooltip,
  Label,
} from 'recharts';
import { GRAPH_TOOLTIP_COLOR } from '^/common/constants';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';

import './graph-container-styles.less';
import { IGradeBreakdownGraphProps, TrendsDataKeys } from './types';

export default function GradeBreakdownBarGraph(
  props: IGradeBreakdownGraphProps
) {
  const barProps = {
    barSize: 15,
    radius: [99, 99, 99, 99],
  };

  const { data, colors, handleClick, is_year_wise } = props;

  return (
    <div className="bar-chart-new">
      {Array.isArray(data) && data.length ? (
        <ResponsiveContainer height="99%" width="99%">
          <BarChart
            data={data}
            margin={{
              top: 0,
              right: 25,
              left: 25,
              bottom: 0,
            }}
            barGap={10}
            style={{ cursor: 'pointer', hover: 'none' }}
          >
            <XAxis dataKey="grade" />
            <YAxis>
              <Label
                value={DASHBOARD_PAGE_LABELS.no_of_students_full}
                position="insideLeft"
                angle={-90}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Brush
              dataKey="grade"
              height={25}
              stroke="#8884d8"
              className="brush"
            />
            <Bar
              dataKey={TrendsDataKeys.ACTIVE}
              fill={colors[0]}
              name={DASHBOARD_PAGE_LABELS.active_students}
              {...barProps}
              onClick={(e) => {
                // console.log(e)
                handleClick
                  ? handleClick({
                      type: 'ACTIVE_INACTIVE',
                      selectedType: {
                        grade: e?.grade,
                        is_year_wise: is_year_wise,
                        active: true,
                      },
                    })
                  : null;
              }}
            />
            <Bar
              dataKey={TrendsDataKeys.INACTIVE}
              fill={colors[1]}
              legendType="circle"
              name={DASHBOARD_PAGE_LABELS.inactive_students}
              {...barProps}
              onClick={(e) => {
                console.log(e);
                handleClick
                  ? handleClick({
                      type: 'ACTIVE_INACTIVE',
                      selectedType: {
                        grade: e?.grade,
                        is_year_wise: is_year_wise,
                        active: false,
                      },
                    })
                  : null;
              }}
            />
            <Legend
              layout="vertical"
              verticalAlign="middle"
              align="right"
              content={<BarChartLegend />}
              iconType="circle"
            />
            <Tooltip
              cursor={{ fill: GRAPH_TOOLTIP_COLOR }}
              itemStyle={{ margin: 0 }}
              wrapperStyle={{ padding: 0 }}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <p className="text-center">{DASHBOARD_PAGE_LABELS.no_data}</p>
      )}
    </div>
  );
}

const BarChartLegend: React.FC<LegendProps> = ({ payload }: LegendProps) => {
  if (Array.isArray(payload)) {
    return (
      <div className="legend-container">
        {payload.map((data, index) => {
          const { color, value } = data;
          return (
            <div className="legend-item" key={`pie-chart-lengend-${index}`}>
              <div className="circle" style={{ backgroundColor: color }}></div>
              <span className="legend-text">{value}</span>
            </div>
          );
        })}
      </div>
    );
  }
  return <> </>;
};

import React from 'react';

export default function ProfileIcon() {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13">
      <path
        fillRule="nonzero"
        d="M7.86 7.625c.562 0 1.085.14 1.57.422.484.281.867.664 1.148 1.148.281.485.422 1.008.422 1.57v.61c0 .312-.11.578-.328.797a1.085 1.085 0 0 1-.797.328h-8.25c-.313 0-.578-.11-.797-.328a1.085 1.085 0 0 1-.328-.797v-.61c0-.562.14-1.085.422-1.57A3.115 3.115 0 0 1 2.07 8.047a3.074 3.074 0 0 1 1.57-.422c.204 0 .47.055.797.164.22.063.399.11.54.14.234.048.492.071.773.071s.539-.023.773-.07c.141-.032.32-.078.54-.14.328-.11.593-.165.796-.165zm2.015 3.75v-.61c0-.546-.2-1.019-.598-1.417A1.937 1.937 0 0 0 7.86 8.75c-.093 0-.25.031-.468.094a5.142 5.142 0 0 1-1.63.281 5.356 5.356 0 0 1-1.652-.281c-.234-.063-.39-.094-.468-.094-.547 0-1.02.2-1.418.598a1.937 1.937 0 0 0-.598 1.418v.609h8.25zM5.75 7.25c-.61 0-1.172-.152-1.688-.457a3.44 3.44 0 0 1-1.23-1.23 3.258 3.258 0 0 1-.457-1.688c0-.61.152-1.172.457-1.687a3.44 3.44 0 0 1 1.23-1.23A3.258 3.258 0 0 1 5.75.5c.61 0 1.172.152 1.687.457a3.44 3.44 0 0 1 1.23 1.23c.306.516.458 1.079.458 1.688 0 .61-.152 1.172-.457 1.688a3.44 3.44 0 0 1-1.23 1.23 3.258 3.258 0 0 1-1.688.457zm0-5.625a2.17 2.17 0 0 0-1.594.656A2.17 2.17 0 0 0 3.5 3.875c0 .625.219 1.156.656 1.594a2.17 2.17 0 0 0 1.594.656 2.17 2.17 0 0 0 1.594-.656A2.17 2.17 0 0 0 8 3.875a2.17 2.17 0 0 0-.656-1.594 2.17 2.17 0 0 0-1.594-.656z"
      />
    </svg>
  );
}

import * as React from 'react';

import { Button, Column, Row } from '@dabapps/roe';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { FormErrors } from '../../types';
import { getFieldErrors } from '../../utils';
import ChadminField from './Field';
import { IItemConfig } from './field-types/types';
import Messages from './Messages';

export const normalizeBoolean = (value: any) => Boolean(value);

export interface ISubmitButtonProps {
  readOnly?: boolean;
  isPending?: boolean;
  saveButtonText?: string;
}

export const SubmitButton = ({
  readOnly,
  isPending,
  saveButtonText,
}: ISubmitButtonProps) =>
  !readOnly && !isPending ? (
    <Column>
      <Button
        type="submit"
        className="primary float-right margin-vertical-base"
      >
        {saveButtonText || 'Save'}
      </Button>
    </Column>
  ) : (
    <noscript />
  );

export interface IExternalFormProps {
  title?: string;
  fieldConfig: IItemConfig;
  fieldClassName?: string;
  hideMessages?: boolean;
  isPending?: boolean;
  className?: string;
  readOnly?: boolean;
  hasSucceeded?: boolean;
  hasFailed?: boolean;
  errors?: FormErrors;
  setPendingUploadInForm?: (file?: File | string | undefined) => void;
  formName: string;
  isEditing?: boolean;
  saveButtonText?: string;
  fields: ReadonlyArray<string>;
  additionalSuccessMessage?: React.ReactNode;
  additionalFailureMessage?: React.ReactNode;
}

export type FormProps<T> = IExternalFormProps &
  InjectedFormProps<T, IExternalFormProps>;

export class Form<T> extends React.PureComponent<FormProps<T>, {}> {
  public render() {
    const {
      fieldConfig,
      readOnly,
      hideMessages,
      saveButtonText,
      className,
      fieldClassName,
      setPendingUploadInForm,
      formName,
      isEditing,
      fields,
      isPending,
      hasSucceeded,
      hasFailed,
      errors,
      additionalSuccessMessage,
      additionalFailureMessage,
      handleSubmit,
    } = this.props;
    return (
      <Row>
        <form className={className} onSubmit={handleSubmit}>
          {fields.map(
            fieldName =>
              fieldConfig[fieldName] ? (
                <Field
                  key={fieldName}
                  component={ChadminField}
                  name={fieldName}
                  fieldConfig={fieldConfig[fieldName]}
                  readOnly={readOnly}
                  className={fieldClassName}
                  setUpload={setPendingUploadInForm}
                  formName={formName}
                  isEditing={isEditing}
                  errors={errors}
                />
              ) : (
                <noscript />
              )
          )}
          {!hideMessages && (
            <Messages
              isPending={isPending}
              hasSucceeded={hasSucceeded}
              hasFailed={hasFailed}
              isEditing={isEditing}
              errors={getFieldErrors(errors, 'non_field_errors')}
              additionalSuccessMessage={additionalSuccessMessage}
              additionalFailureMessage={additionalFailureMessage}
            />
          )}
          <SubmitButton
            readOnly={readOnly}
            isPending={isPending}
            saveButtonText={saveButtonText}
          />
        </form>
      </Row>
    );
  }
}

export default reduxForm<any, IExternalFormProps>({})(Form);

import { useState, useCallback } from 'react';
import axios from './axiosInstance';

const useAPI = <T, R>(
  service: (data: T) => R | Promise<R>
): {
  loading: boolean;
  error?: Error;
  response?: R;
  callAPI: (
    data: T,
    onSuccess?: (response?: R) => void,
    onError?: (error: never) => void
  ) => void;
} => {
  const [state, setState] = useState<{
    loading: boolean;
    error: Error | undefined;
    response: R | undefined;
  }>({
    loading: false,
    error: undefined,
    response: undefined,
  });

  const callAPI = useCallback(
    async (
      data: T,
      onSuccess?: (response?: R) => void,
      onError?: (error: never) => void
    ) => {
      try {
        setState((prevState) => ({
          ...prevState,
          loading: true,
          error: undefined,
        }));
        const response: R = await service(data);

        setState((prevState) => ({
          ...prevState,
          loading: false,
          response: response,
          error: undefined,
        }));

        if (onSuccess) {
          onSuccess(response);
        }
      } catch (error) {
        // if axios cancelled api error. Then continue loading and return error back.
        if (axios.default.isCancel(error)) {
          setState((prevState) => ({ ...prevState, loading: true, error }));
        } else {
          setState((prevState) => ({ ...prevState, loading: false, error }));
          if (onError) {
            onError(error as never);
          }
        }
      }
    },
    [service]
  );

  return {
    loading: state.loading,
    error: state.error,
    response: state.response,
    callAPI,
  };
};

export default useAPI;

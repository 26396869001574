import { LoggedInUser, UserRoles } from '^/common/authentication/types';

export function loggedInUserIsAdmin(
  loggedInUser: LoggedInUser | null
): boolean {
  return Boolean(loggedInUser && loggedInUser.role === UserRoles.MIMOVE_ADMIN);
}

export function loggedInUserIsTeacher(
  loggedInUser: LoggedInUser | null
): boolean {
  return Boolean(
    loggedInUser &&
      (loggedInUser.role === UserRoles.TEACHER ||
        loggedInUser.role === UserRoles.LEAD_TEACHER)
  );
}

export function loggedInUserIsClusterAdmin(
  loggedInUser: LoggedInUser | null
): boolean {
  return Boolean(loggedInUser && loggedInUser.role === UserRoles.CLUSTER_ADMIN);
}

export function loggedInUserIsClusterManager(
  loggedInUser: LoggedInUser | null
): boolean {
  return Boolean(
    loggedInUser && loggedInUser.role === UserRoles.CLUSTER_MANAGER
  );
}

import { Dict } from '@dabapps/simple-records';
import { AxiosResponse } from 'axios';
import { AnyAction } from 'redux';

export function hasPayload(action: AnyAction) {
  return action.hasOwnProperty('payload') && action.payload !== undefined;
}

export function isAxiosResponse(
  response?: Dict<any>
): response is AxiosResponse {
  if (!response) {
    return false;
  }
  return (
    response.hasOwnProperty('config') &&
    response.hasOwnProperty('request') &&
    response.hasOwnProperty('status')
  );
}

export function getResponseFromAction(action: AnyAction): AxiosResponse | null {
  const response =
    hasPayload(action) && action.payload.hasOwnProperty('response')
      ? action.payload.response
      : null;

  return isAxiosResponse(response) ? response : null;
}

export function isServerError(response: AxiosResponse) {
  return response.status >= 500 && response.status <= 599;
}

export function isSuccessResponse(response: AxiosResponse) {
  return response.status >= 200 && response.status <= 299;
}

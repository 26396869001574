export const ActivitiesNames = [
  { type: 'ACTIVE_PLAY', label: 'Active play' },
  { type: 'ACTIVE_TRAVEL', label: 'Active travel' },
  { type: 'AMERICAN_FOOTBALL', label: 'American Football' },
  { type: 'BADMINTON', label: 'Badminton' },
  { type: 'BALLET', label: 'Ballet' },
  { type: 'BASEBALL', label: 'Baseball' },
  { type: 'BASKETBALL', label: 'Basketball' },
  { type: 'BMX', label: 'BMX' },
  { type: 'BOXING', label: 'Boxing' },
  { type: 'CHEERLEADING', label: 'Cheerleading' },
  { type: 'CLIMBING', label: 'Climbing' },
  { type: 'CRICKET', label: 'Cricket' },
  { type: 'CYCLING', label: 'Cycling' },
  { type: 'DIVING', label: 'Diving' },
  { type: 'EQUESTRIAN', label: 'Equestrian' },
  { type: 'EXERCISE_CLASS', label: 'Exercise Class' },
  { type: 'FENCING', label: 'Fencing' },
  { type: 'FITNESS_TRAINING', label: 'Fitness Training' },
  { type: 'FOOTBALL', label: 'Football' },
  { type: 'GO_KARTING', label: 'Go Karting' },
  { type: 'GOLF', label: 'Golf' },
  { type: 'GYMNASTICS', label: 'Gymnastics' },
  { type: 'HANDBALL', label: 'Handball' },
  { type: 'HIKING', label: 'Hiking' },
  { type: 'HOCKEY', label: 'Hockey' },
  { type: 'HURLING', label: 'Hurling' },
  { type: 'ICE_SKATING', label: 'Ice Skating' },
  { type: 'JUDO', label: 'Judo' },
  { type: 'KARATE', label: 'Karate' },
  { type: 'KAYAKING', label: 'Kayaking' },
  { type: 'KINBALL', label: 'Kinball' },
  { type: 'LACROSSE', label: 'Lacrosse' },
  { type: 'LAWN_BOWLS', label: 'Lawn Bowls' },
  { type: 'NETBALL', label: 'Netball' },
  { type: 'OTHER_DANCE', label: 'Other Dance' },
  { type: 'OTHER_MARTIAL_ART', label: 'Other Martial Art' },
  { type: 'PADDLE_BOARDING', label: 'Paddle Boarding' },
  { type: 'PARKOUR', label: 'Parkour' },
  { type: 'PHYSICALLY_ACTIVE_LEARNING', label: 'Physically Active Learning' },
  { type: 'PICKLE_BALL', label: 'Pickle Ball' },
  { type: 'ROLLER_OR_INLINE_SKATING', label: 'Skating' },
  { type: 'ROUNDERS', label: 'Rounders' },
  { type: 'ROWING', label: 'Rowing Outdoors' },
  { type: 'ROWING_INDOORS', label: 'Rowing Indoors' },
  { type: 'RUGBY', label: 'Rugby' },
  { type: 'RUNNING', label: 'Running' },
  { type: 'RUNNING_TREADMILL', label: 'Running Treadmill' },
  { type: 'SAILING', label: 'Sailing' },
  { type: 'SKATEBOARDING', label: 'Skateboarding' },
  { type: 'SKIING', label: 'Skiing' },
  { type: 'SNOOKER', label: 'Snooker' },
  { type: 'SNOWBOARDING', label: 'Snowboarding' },
  { type: 'SOFTBALL', label: 'Softball' },
  { type: 'SPEED_SKATING', label: 'Speed Skating' },
  { type: 'SPIKE_BALL', label: 'Spike Ball' },
  { type: 'SQUASH', label: 'Squash' },
  { type: 'STREET_DANCE', label: 'Street Dance' },
  { type: 'SURFING', label: 'Surfing' },
  { type: 'SWIMMING', label: 'Swimming' },
  { type: 'TABLE_TENNIS', label: 'Table Tennis' },
  { type: 'TEN_PIN_BOWLING', label: 'Ten Pin Bowling' },
  { type: 'TENNIS', label: 'Tennis' },
  { type: 'TRACK_AND_FIELD_ATHLETICS', label: 'Track and Field Athletics' },
  { type: 'TRAMPOLINING', label: 'Trampolining' },
  { type: 'VOLLEYBALL', label: 'Volleyball' },
  { type: 'WALKING_HIKING', label: 'Walking/Hiking' },
  { type: 'WALKING', label: 'Walking' },
  { type: 'WATER_POLO', label: 'Water Polo' },
  { type: 'WIND_SURFING', label: 'Wind Surfing' },
  { type: 'WRESTLING', label: 'Wrestling' },
  { type: 'YOGA', label: 'Yoga' },
  { type: 'OTHER', label: 'Other - Please State' },
];

export const ActivityLocationNames = [
  { type: 'INSIDE_SCHOOL_PE', label: 'At School in PE' },
  { type: 'INSIDE_SCHOOL_CLUB', label: 'At School in a Club' },
  { type: 'INSIDE_SCHOOL_BREAK', label: 'At School in a Breaktime' },
  { type: 'OUTSIDE_SCHOOL_CLUB', label: 'Out of School in a Club or Class' },
  { type: 'OUTSIDE_SCHOOL_FREE_PLAY', label: 'Out of School in a Free Time' },
  {
    type: 'OUTSIDE_SCHOOL_HOLIDAY_CAMP',
    label: 'Out of School in a Holiday Camp',
  },
];

export const ActivityTimeOptions = [
  { duration: 15, label: '15 min' },
  { duration: 30, label: '30 min' },
  { duration: 45, label: '45 min' },
  { duration: 60, label: '1 hour' },
  { duration: 75, label: '1 hour 15 mins' },
  { duration: 90, label: '1 hour 30 mins' },
  { duration: 105, label: '1 hour 45 mins' },
  { duration: 120, label: '2 hours +' },
];

export const DayOptions = [
  { id: '0', name: 'Sunday' },
  { id: '1', name: 'Monday' },
  { id: '2', name: 'Tuesday' },
  { id: '3', name: 'Wednesday' },
  { id: '4', name: 'Thursday' },
  { id: '5', name: 'Friday' },
  { id: '6', name: 'Saturday' },
];

export enum LogLevel {
  None,
  Errors,
  Debug,
  Trace,
}

enum defaultLogLevels {
  development = LogLevel.Trace,
  production = LogLevel.Errors,
  test = LogLevel.Debug,
}

//TODO: set REACT_APP_BASE_URL in .env file
const BaseUrl = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : window.location.origin;

export class AppSettings {
  public static readonly server = {
    baseUrl: BaseUrl,
  };
  public static readonly logging = {
    // TODO make it dynamic based on env
    logLevel:
      process.env.NODE_ENV === 'development'
        ? defaultLogLevels[process.env.NODE_ENV]
        : defaultLogLevels.production,
  };
}

import { Moment, utc } from 'moment';

import {
  MOMENT_DATE_FORMAT,
  MONTH_FORMAT,
  MONTH_YEAR_FORMAT,
} from '^/common/constants';

export const formatDate = (date: string | Date) => {
  return utc(date).format(MOMENT_DATE_FORMAT);
};

export function formatMonthDisplay(date: Moment): string {
  return date.format(MONTH_FORMAT).toUpperCase();
}

export function formatMonthYear(date: Moment): string {
  return date.format(MONTH_YEAR_FORMAT);
}

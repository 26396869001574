import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { closeModal } from '^/common/modals/actions';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { NewMessage } from './types';

export const CREATE_MESSAGE = makeAsyncActionSet('CREATE_MESSAGE');
export function createMessage(
  data: NewMessage,
  students: ReadonlyArray<string>,
  filters = {}
) {
  return (dispatch: Dispatch) => {
    return request(CREATE_MESSAGE, '/api/messages/', 'POST', {
      ...data,
      students,
      filters,
    })(dispatch).then(() => {
      toast.success(`Message sent`);
      setTimeout(() => {
        dispatch(closeModal());
      }, 5000);
    });
  };
}

// Generates url: {AppSettings.service.baseUrl}/{this.serviceApi}
// DO NOT append or prepend '/' here!
// different main modules according to BE goes here
export enum ServiceType {
  ACTIVITY = 'activities',
  USERS = 'users',
  COHORTS = 'cohorts',
  DASHBOARD = 'dashboard',
  SCHOOLS = 'schools',
  PROFILE = 'profile',
  CLUSTER = 'cluster',
  MESSAGES = 'messages',
}

import * as React from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';

interface Props {
  className?: string;
}

export default function LoadingSpinner({ className }: Props) {
  return (
    <div
      className={className ? `loading-spinner ${className}` : 'loading-spinner'}
    >
      <Loading3QuartersOutlined />
    </div>
  );
}

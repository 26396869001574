import { SimpleRecord } from '@dabapps/simple-records';
import {
  IGradeWiseBreakdown,
  ITrendsData,
  IEmotionData,
  IDashboardUIHistogram,
} from '^/api-services/dashboard/types';
import { Feeling } from '^/app/authenticated/activities/constants';

export interface ITrendsGraphProps {
  data: Array<ITrendsData>;
  colors: string[];
  handleClick?: (props: any) => void;
}

export interface IEmotionalResponseGraphProps {
  data: Array<IEmotionData>;
  colors: string[];
  handleClick?: (props: any) => void;
}

export interface IActivityHistogramGraphProps {
  title: string;
  data: Array<IDashboardUIHistogram>;
  colors: string[];
  valueKey?: string;
  customFormatter?: (val: string) => string;
  seriesLabelPrefix?: string;
  handleClick?: (props: any) => void;
}

export interface IActivityLocationData {
  name: string;
  value: any;
  color: string;
}
export interface IActivityLocationGraphProps {
  data: IActivityLocationData[];
  handleClick?: (props: any) => void;
}

export interface IGenericPieChartGraphProps {
  data: IDashboardUIHistogram[];
  title: string;
  valueKey?: string;
  width?: string;
  height?: string;
  handleClick?: (props: any) => void;
}

export interface IGradeBreakdownGraphProps {
  data: IGradeWiseBreakdown[];
  colors: string[];
  handleClick?: (props: any) => void;
  is_year_wise?: boolean;
}

export enum TrendsDataKeys {
  INACTIVE = 'inactive_students_count',
  ACTIVE = 'active_students_count',
  AVG_MINS = 'average_minutes_per_week',
}

export enum EmotionalBarGraphDataKeys {
  GREAT = 'GREAT',
  GOOD = 'GOOD',
  OK = 'OK',
  HORRIBLE = 'HORRIBLE',
  NOT_GOOD = 'NOT_GOOD',
}

export interface GraphUserListUser {
  readonly id: string;
  readonly student_id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly feeling: Feeling;
  readonly created: string;
}
export const GraphUserListUser = SimpleRecord<GraphUserListUser>({
  id: '',
  student_id: '',
  first_name: '',
  last_name: '',
  feeling: 'GOOD',
  created: '',
});

export enum GenderDataMappings {
  Male = 'MALE',
  Female = 'FEMALE',
  Nobt = 'NON_BINARY',
}

import React from 'react';
import { Divider, Pagination, Select, Typography } from 'antd';
import { SelectProps } from 'antd/lib/select';

import './select-filter-styles.less';

export interface SelectOption {
  id: string | number;
  name: string;
}

interface Props extends SelectProps<string> {
  optionList: SelectOption[];
  label?: string;
  totalCount?: number;
  onPageChange?: (pageNumber: number) => void;
  currentPageNumber?: number;
  customFilterCB?: (input: string, options: any) => boolean;
  paginationFooter?: string;
  value?: string | string[];
}

export const SelectFilter: React.FC<Props> = (props: Props) => {
  const {
    optionList,
    label,
    totalCount,
    onPageChange,
    customFilterCB,
    currentPageNumber,
    paginationFooter,
    onClear,
    ...selectProps
  } = props;

  const filterOptions = (input: string, options: any) => {
    if (customFilterCB) {
      return customFilterCB(input, options);
    }
    return options && options.children
      ? (options.children as string).toLowerCase().includes(input)
      : false;
  };

  const { Text } = Typography;
  return (
    <div className="select-filter-container">
      {label && <Text className="label">{label}</Text>}
      <Select
        placeholder="Select"
        allowClear={onClear ? true : false}
        showArrow={true}
        bordered={false}
        filterOption={filterOptions}
        dropdownRender={(menu) => {
          return currentPageNumber && totalCount && onPageChange ? (
            <div className="dropdown-render-div">
              {menu}
              <Divider className="divider" />
              {paginationFooter && (
                <Text type="secondary" className="ml-1 mr-1 small-font">
                  {paginationFooter}
                </Text>
              )}
              <div className="pagination-container">
                <div className="d-flex justify-content-center flex-1 pb-1">
                  <Pagination
                    size="small"
                    current={currentPageNumber}
                    total={totalCount}
                    onChange={onPageChange}
                    showSizeChanger={false}
                    showTitle={false}
                    hideOnSinglePage
                    showLessItems
                  />
                </div>
              </div>
            </div>
          ) : (
            menu
          );
        }}
        {...selectProps}
      >
        {optionList.map(
          (option) =>
            option.id &&
            option.name && (
              <Select.Option value={option.id} key={option.id}>
                {option.name}
              </Select.Option>
            )
        )}
      </Select>
    </div>
  );
};

import React from 'react';

import { FilterOption } from './types';
import { filterOptionsToUnselected } from './utils';

interface Props {
  options: ReadonlyArray<FilterOption>;
  selectedOptions: ReadonlyArray<FilterOption>;
  title: string;
  filter: string;

  // NOTE: these are hard-coded so we can keep native select inputs for accessibility
  // whilst retaining a consistent spacing between the custom dropdown arrow and the text
  width: number;

  onSelectOption(filter: string, selectedOption: FilterOption): void;
}

export default class Filter extends React.PureComponent<Props> {
  public render() {
    const { options, selectedOptions, title, width } = this.props;
    const filteredOptions = filterOptionsToUnselected(options, selectedOptions);
    return (
      <select
        onChange={this.onChange}
        style={{
          width,
        }}
        className="filter-input"
        value=""
      >
        <option value="">{title}</option>
        {filteredOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  public onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = this.props.options.find(
      (option) => option.value === event.target.value
    );
    if (selectedOption && Boolean(selectedOption.value)) {
      this.props.onSelectOption(this.props.filter, selectedOption);
    }
  };
}

import React from 'react';
import { connect } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { changeFullAssessmentPage } from '^/app/authenticated/assessments/actions';
import {
  FULL_ASSESSMENT_ATTITUDE_CONFIG,
  FULL_ASSESSMENT_MOVEMENT_CONFIG,
  FULL_ASSESSMENT_THINKING_CONFIG,
} from '^/app/authenticated/assessments/config';
import FullAssessmentPage from '^/app/authenticated/assessments/full-assessment-page';
import { Status } from '^/app/authenticated/assessments/types';
import { StoreState } from '^/store/types';

export const FULL_ASSESSMENT_FORM = 'full-assessment';

export interface FullAssessmentValues {
  movement_status?: Status;
  thinking_status?: Status;
  attitude_status?: Status;
}

interface ExternalProps {
  assessmentValues: FullAssessmentValues;
}

interface StateProps {
  fullAssessmentPageNumber: number;
}

interface DispatchProps {
  changeFullAssessmentPage(pageNumber?: number): void;
}

export type FullAssessmentFormProps = ExternalProps &
  StateProps &
  DispatchProps &
  InjectedFormProps<FullAssessmentValues, ExternalProps>;

export class FullAssessmentForm extends React.PureComponent<
  FullAssessmentFormProps
> {
  public componentWillMount() {
    if (this.props.fullAssessmentPageNumber !== 1) {
      this.props.changeFullAssessmentPage(1);
    }
  }

  public render() {
    const {
      handleSubmit,
      fullAssessmentPageNumber,
      assessmentValues,
    } = this.props;
    return (
      <form onSubmit={handleSubmit} className="assessment-form">
        <FullAssessmentPage
          config={FULL_ASSESSMENT_MOVEMENT_CONFIG}
          currentPageNumber={fullAssessmentPageNumber}
          value={assessmentValues.movement_status}
        />
        <FullAssessmentPage
          config={FULL_ASSESSMENT_THINKING_CONFIG}
          currentPageNumber={fullAssessmentPageNumber}
          value={assessmentValues.thinking_status}
        />
        <FullAssessmentPage
          config={FULL_ASSESSMENT_ATTITUDE_CONFIG}
          currentPageNumber={fullAssessmentPageNumber}
          value={assessmentValues.attitude_status}
        />
      </form>
    );
  }
}

function mapStateToProps(state: StoreState) {
  const { fullAssessmentPageNumber } = state.assessmentModal;
  return {
    fullAssessmentPageNumber,
  };
}

const connected = connect(
  mapStateToProps,
  {
    changeFullAssessmentPage,
  }
)(FullAssessmentForm);

export default reduxForm<FullAssessmentValues, ExternalProps>({
  form: FULL_ASSESSMENT_FORM,
})(connected);

import React from 'react';

import {
  isResponseErrorList,
  ResponseErrorList,
  ResponseErrors,
} from '^/common/utils/errors';

interface DispatchProps {
  formErrors: ResponseErrors | undefined;
  formErrorsKey: string;
}

type Props = DispatchProps;

export default class FormErrorRenderer extends React.PureComponent<Props> {
  public renderErrors(errors: ResponseErrorList) {
    return (
      <ul className="error-list">
        {errors.map((error, i) => (
          <li className="error" key={i}>
            {error}
          </li>
        ))}
      </ul>
    );
  }

  public render() {
    const { formErrors, formErrorsKey } = this.props;

    if (!formErrors || !Object.keys(formErrors).length) {
      return null;
    }

    const error = formErrors[formErrorsKey];

    if (typeof error === 'string') {
      return this.renderErrors([error]);
    }

    if (isResponseErrorList(error)) {
      return this.renderErrors(error);
    }

    return null;

    // TODO: Handle nested response errors
  }
}

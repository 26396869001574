import classNames from 'classnames';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { FormErrors } from '../../types';
import { getFieldErrors } from '../../utils';
import FieldChar from './field-types/FieldChar';
import FieldMany from './field-types/FieldMany';
import FieldNumber from './field-types/FieldNumber';
import FieldRadio from './field-types/FieldRadio';
import FieldSelect from './field-types/FieldSelect';
import { IConfig, TWrappedFieldProps } from './field-types/types';

interface IRenderFieldProps {
  fieldConfig: IConfig;
  fieldProps: TWrappedFieldProps;
}

function RenderField({ fieldConfig, fieldProps }: IRenderFieldProps) {
  switch (fieldConfig.type) {
    case 'integer':
      return <FieldNumber {...fieldProps} />;
    case 'many':
      return <FieldMany {...fieldProps} />;
    case 'radio':
      return <FieldRadio {...fieldProps} />;
    case 'text':
    case 'password':
    default:
      if (fieldConfig.choices) {
        return <FieldSelect {...fieldProps} />;
      } else {
        return <FieldChar {...fieldProps} />;
      }
  }
}

export interface IFieldExternalProps {
  isEditing?: boolean;
  fieldConfig: IConfig;
  formName: string;
  readOnly?: boolean;
  errors?: FormErrors;
  setUpload?(file: File): void;
}

export type FieldProps = IFieldExternalProps &
  WrappedFieldProps &
  React.HTMLProps<JSX.Element>;

export default class Field extends React.PureComponent<FieldProps, {}> {
  public render() {
    const {
      readOnly,
      fieldConfig,
      input,
      errors,
      isEditing,
      formName,
      setUpload,
    } = this.props;

    const fieldProps = {
      ...input,
      config: fieldConfig,
      errors: getFieldErrors(errors, input && input.name),
      readOnly: readOnly || fieldConfig.readOnly,
      isEditing,
      formName,
      setUpload,
    };

    const { help_text, fieldWrapperClassName } = fieldConfig;

    return (
      <div className={classNames(fieldWrapperClassName)}>
        {help_text && <p className="admin-helptext">{help_text}</p>}
        <RenderField fieldConfig={fieldConfig} fieldProps={fieldProps} />
      </div>
    );
  }
}

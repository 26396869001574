import { getCollectionByName } from '@dabapps/redux-api-collections';
import { GET_COLLECTION } from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import * as React from 'react';
import { connect } from 'react-redux';

import { ASSESSMENT_COLUMNS } from '^/app/authenticated/activities/activity-page/config';
import { AssessmentListItem } from '^/app/authenticated/assessments/types';
import { AdminList } from '^/chadmin';
import { collections } from '^/common/collections';
import { PAGE_SIZE } from '^/common/constants';
import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import { StoreState } from '^/store/types';

const {
  actions: { getCollection },
} = collections;

export const ASSESSMENT_LIST_ROUTE = 'assessments';

interface ExternalProps {
  userId: string;
}

interface StateProps {
  assessments: ReadonlyArray<AssessmentListItem>;
  isLoading: boolean;
  hasFailedAssessments: boolean;
  count: number;
  currentPage: number;
}

interface DispatchProps {
  getCollection: typeof getCollection;
}

type Props = ExternalProps & StateProps & DispatchProps;

export class AssessmentsTable extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.userId && this.fetchAssessments();
  }

  componentDidUpdate(prevProps: Props) {
    const { userId } = this.props;
    if (prevProps.userId !== userId) {
      this.fetchAssessments();
    }
  }

  public render() {
    const {
      assessments,
      isLoading,
      count,
      currentPage,
      hasFailedAssessments,
    } = this.props;
    return isLoading ? (
      <div className="padding-large">
        <LoadingSpinner />
      </div>
    ) : hasFailedAssessments ? (
      <div className="padding-vertical-large">
        <ErrorMessage />
      </div>
    ) : (
      <div className="table-top-align">
        <AdminList
          items={assessments}
          itemCount={count}
          columns={ASSESSMENT_COLUMNS}
          listName="list"
          changePage={this.changePage}
          page={currentPage}
          pageSize={PAGE_SIZE}
        />
      </div>
    );
  }

  private changePage = (page: number) => {
    this.fetchAssessments(page);
  };

  private fetchAssessments = (page = 1) => {
    this.props.getCollection(
      ASSESSMENT_LIST_ROUTE,
      {
        filters: {
          student: this.props.userId,
        },
        pageSize: PAGE_SIZE,
        page,
      },
      this.props.userId
    );
  };
}

function mapStateToProps(state: StoreState, props: ExternalProps) {
  const assessmentCollection = getCollectionByName(
    state.collections,
    ASSESSMENT_LIST_ROUTE,
    props.userId
  );
  return {
    assessments: assessmentCollection.results as ReadonlyArray<
      AssessmentListItem
    >,
    count: assessmentCollection.count,
    currentPage: assessmentCollection.page,
    isLoading: isPending(
      state.responses,
      GET_COLLECTION,
      ASSESSMENT_LIST_ROUTE
    ),
    hasFailedAssessments: hasFailed(
      state.responses,
      GET_COLLECTION,
      ASSESSMENT_LIST_ROUTE
    ),
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(AssessmentsTable);

import {
  EMOJI_LIST,
  EMOJI_TO_CLASSNAME,
} from '^/app/authenticated/messaging/constants';
import { Emoji } from '^/app/authenticated/messaging/types';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

export const EmojiPicker: React.SFC<WrappedFieldProps> = ({
  input: { onChange, value },
}) => {
  const renderEmoji = (emoji: Emoji) => {
    const handleClick = () => {
      onChange(emoji);
    };

    const createClassName = `emoji ${EMOJI_TO_CLASSNAME[emoji]}${
      emoji === value ? '-selected' : ''
    }`;

    return (
      <button
        key={emoji}
        type="button"
        className={createClassName}
        onClick={handleClick}
      />
    );
  };

  return (
    <div className="emoji-picker">
      {EMOJI_LIST.map(emoji => renderEmoji(emoji))}
    </div>
  );
};

export default EmojiPicker;

import { Button, FormGroup, ModalFooter } from '@dabapps/roe';
import React from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import { ResponseErrors } from '^/common/utils/errors';
import EmojiPicker from '../../../common/forms/fields/emoji-picker';
import { MESSAGE_PRESETS } from './constants';
import { NewMessage } from './types';

export const CREATE_MESSAGE_FORM = 'createMessage';

export interface MessagePresetOption {
  value: string;
  label: string;
}

export interface ExternalProps {
  isLoading: boolean;
  hasFailed: boolean;
  errors?: ResponseErrors;
  updateMessageText(option: ValueType<MessagePresetOption>): void;
}

export type FormProps = ExternalProps &
  InjectedFormProps<NewMessage, ExternalProps>;

export class MessageForm extends React.PureComponent<FormProps> {
  public render() {
    const {
      handleSubmit,
      isLoading,
      hasFailed,
      errors,
      updateMessageText,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormGroup block>
          <label>Choose message preset</label>
          <Select
            className="select"
            options={MESSAGE_PRESETS.map(messagePreset => ({
              value: messagePreset,
              label: messagePreset,
            }))}
            isLoading={isLoading}
            onChange={updateMessageText}
            name="text"
            placeholder="Choose message preset"
          />
        </FormGroup>
        <FormGroup block>
          <label>Message text</label>
          <Field
            name="text"
            placeholder="Type something encouraging to your student(s) here"
            component="textarea"
            disabled={isLoading}
          />
          <FormErrorRenderer formErrors={errors} formErrorsKey={'text'} />
        </FormGroup>
        <FormGroup block>
          <label>Choose an emoji</label>
          <Field name="emoji" component={EmojiPicker} disabled={isLoading} />
          <FormErrorRenderer formErrors={errors} formErrorsKey={'emoji'} />
        </FormGroup>
        <ModalFooter>
          {hasFailed && (
            <FormErrorRenderer
              formErrors={{ error: 'Failed to send message' }}
              formErrorsKey={'error'}
            />
          )}
          {isLoading ? (
            <span>Loading&hellip;</span>
          ) : (
            <Button type="submit" disabled={isLoading} className="pill">
              Send message
            </Button>
          )}
        </ModalFooter>
      </form>
    );
  }
}

export default reduxForm<NewMessage, ExternalProps>({
  form: CREATE_MESSAGE_FORM,
})(MessageForm);

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { NewClass } from '^/app/authenticated/classes/class-management/types';
import { isSuccessResponse } from '^/common/utils/responses';

export const CREATE_CLASS = makeAsyncActionSet('CREATE_CLASS');
export function createClass(data: Partial<NewClass>) {
  return (dispatch: Dispatch) => {
    return request(
      CREATE_CLASS,
      '/api/cohorts/class-groups/',
      'POST',
      data
    )(dispatch).then((response) => {
      if (response && isSuccessResponse(response)) {
        dispatch(push('/classes'));
        const data = response.data;
        if (data.is_extracurricular) {
          toast.success(`Group ${data.name} created`);
        } else {
          toast.success(`Class ${data.name} created`);
        }
      }
    });
  };
}

import React, { Key } from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  LegendProps,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  GRAPH_TOOLTIP_COLOR,
  TRENDS_TOOLTIP_LABEL_FORMAT,
} from '^/common/constants';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';

import { toTitleCase } from '^/common/utils/text-utils';
import { ITrendsGraphProps, TrendsDataKeys } from './types';
import moment from 'antd/node_modules/moment';

export default function TrendsTimeSeriesGraph({
  data,
  colors,
  handleClick,
}: ITrendsGraphProps) {
  const XAxisTickFormater = (val: string) => {
    return toTitleCase(val, ' ');
  };

  const underScoreFormatter = (val: string) => {
    return toTitleCase(val, '_');
  };

  const generateTooltipLabel = (name: Key): string => {
    const selectedData = data.find((ele) => ele.name === name);
    if (selectedData)
      return `${underScoreFormatter(selectedData?.name)} (${moment(
        selectedData.start_date
      ).format(TRENDS_TOOLTIP_LABEL_FORMAT)} to ${moment(
        selectedData.end_date
      ).format(TRENDS_TOOLTIP_LABEL_FORMAT)})`;
    return '';
  };

  const defaultLineProps = {
    dot: { strokeWidth: 5, r: 2 },
    strokeWidth: 2,
    isAnimationActive: false,
  };

  return (
    <div className="timeseries--container">
      <ResponsiveContainer height="100%" width="98%">
        <LineChart
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 10,
          }}
          onClick={(e) => {
            // console.log(e);
            handleClick
              ? handleClick({
                  type: 'TRENDS',
                  selectedType: {
                    startDate: e.activePayload[0]?.payload?.start_date,
                    endDate: e.activePayload[0]?.payload?.end_date,
                  },
                })
              : null;
          }}
          style={{ cursor: 'pointer' }}
        >
          <CartesianGrid vertical={false} />
          <Line
            dataKey={TrendsDataKeys.ACTIVE}
            stroke={colors[0]}
            yAxisId="left"
            name={DASHBOARD_PAGE_LABELS.active_students}
            {...defaultLineProps}
          />
          {/* <Line
            dataKey={TrendsDataKeys.INACTIVE}
            stroke={colors[1]}
            yAxisId="left"
            name={DASHBOARD_PAGE_LABELS.inactive_students}
            {...defaultLineProps}
          />
          <Line
            dataKey={TrendsDataKeys.AVG_MINS}
            stroke={colors[2]}
            yAxisId="right"
            {...defaultLineProps}
          /> */}
          <XAxis dataKey="name" tickFormatter={XAxisTickFormater} />
          <YAxis yAxisId="left">
            <Label
              value={DASHBOARD_PAGE_LABELS.no_of_students}
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <YAxis yAxisId="right" orientation="right">
            <Label
              value={DASHBOARD_PAGE_LABELS.trends_avg_mins}
              position="insideRight"
              angle={90}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          {/* <Brush
            dataKey="name"
            height={25}
            stroke="#8884d8"
            className="brush"
          /> */}
          <Tooltip
            formatter={(value, name) => [
              underScoreFormatter(value.toString()),
              underScoreFormatter(name.toString()),
            ]}
            labelFormatter={(val) => generateTooltipLabel(val)}
            itemStyle={{ margin: 0 }}
            wrapperStyle={{ padding: 0 }}
            cursor={{ fill: GRAPH_TOOLTIP_COLOR }}
          />
          <Legend
            iconType="circle"
            formatter={underScoreFormatter}
            wrapperStyle={{ padding: 0, margin: 0 }}
            content={TrendsLegend}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

const TrendsLegend: React.FC<LegendProps> = ({ payload }: LegendProps) => {
  if (Array.isArray(payload)) {
    return (
      <div className="legend-container">
        {payload.map((data, index) => {
          const { color, value } = data;
          return (
            <div className="legend-item" key={`pie-chart-lengend-${index}`}>
              <div className="circle" style={{ backgroundColor: color }}></div>
              <span className="legend-text">{toTitleCase(value, '_')}</span>
            </div>
          );
        })}
      </div>
    );
  }
  return <> </>;
};

import LoadingSpinner from '^/common/loading-spinner';
import * as React from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { WrappedFieldProps } from 'redux-form';
import SelectedOption from './search-select-selected-option';
import { SearchSelectOption } from './types';

interface ExternalProps {
  options: SearchSelectOption[];
  onInputChange: (query: string) => void;
  isLoading: boolean;
  onSelectChange: (newValue: ReadonlyArray<string>) => void;
}

type Props = WrappedFieldProps & ExternalProps;

export function isSearchSelectOptionArray(
  value: ValueType<SearchSelectOption>
): value is SearchSelectOption[] {
  return Array.isArray(value);
}

const noop = () => null;

export default class SearchSelect extends React.PureComponent<Props> {
  public render() {
    const { options, onInputChange, isLoading } = this.props;

    return (
      <>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <ul className="search-select-list">
            {this.getValue().map(option => (
              <SelectedOption
                key={option.value}
                option={option}
                removeOption={this.removeOption}
              />
            ))}
          </ul>
        )}
        <Select
          className="select"
          options={options}
          onChange={this.onChange}
          value={this.getValue()}
          onInputChange={onInputChange}
          isLoading={isLoading}
          components={{
            MultiValue: noop,
          }}
          isMulti
        />
      </>
    );
  }

  private removeOption = (optionIdToRemove: string) => {
    const valueWithOptionRemoved = this.props.input.value.filter(
      (optionId: string) => optionId !== optionIdToRemove
    );
    this.props.input.onChange(valueWithOptionRemoved);
    this.props.onSelectChange(valueWithOptionRemoved);
  };

  private getValue = () => {
    const { input, options } = this.props;
    return options.filter(option => input.value.indexOf(option.value) !== -1);
  };

  private onChange = (value: ValueType<SearchSelectOption>) => {
    if (isSearchSelectOptionArray(value)) {
      this.props.input.onChange(value.map(option => option.value));
      this.props.onSelectChange(value.map(option => option.value));
    }
  };
}

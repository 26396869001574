import { Dict } from '@dabapps/simple-records';
import * as _ from 'underscore';

import { SelectedDashboardFilters } from './reducers';
import { FilterOption } from './types';

export function transformFilterOptionsToCollectionFilter(
  filters: SelectedDashboardFilters
): Dict<string> {
  return _.mapObject(filters.filters, (options) =>
    options.map((option) => option.value).join(',')
  );
}

export function addFilterToFilters(
  state: SelectedDashboardFilters,
  filter: string,
  option: FilterOption
): SelectedDashboardFilters {
  const currentFilterOptions = state.filters[filter] || [];
  return {
    ...state,
    filters: {
      ...state.filters,
      [filter]: [...currentFilterOptions, option],
    },
  };
}

export function removeFilterFromFilters(
  state: SelectedDashboardFilters,
  filter: string,
  option: FilterOption
): SelectedDashboardFilters {
  const { [filter]: currentFilterOptions = [], ...rest } = state.filters;
  const optionsWithFilterExcluded = currentFilterOptions.filter(
    (each) => each.value !== option.value
  );
  return optionsWithFilterExcluded.length > 0
    ? { ...state, filters: { ...rest, [filter]: optionsWithFilterExcluded } }
    : { ...state, filters: { ...rest } };
}

export function filterOptionsToUnselected(
  options: ReadonlyArray<FilterOption>,
  selectedOptions: ReadonlyArray<FilterOption>
): ReadonlyArray<FilterOption> {
  return options.filter(
    (option) =>
      !Boolean(selectedOptions.find((each) => each.value === option.value))
  );
}

export function generateBooleanFilterOptions(
  displayPrefix: string
): ReadonlyArray<FilterOption> {
  return [
    { value: 'True', label: `${displayPrefix} Yes` },
    { value: 'False', label: `${displayPrefix} No` },
  ];
}

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { EditCreateUser } from '^/app/authenticated/users/user-management/types';

export const CREATE_USER = makeAsyncActionSet('CREATE_USER');
export function createUser(data: Partial<EditCreateUser>, school: string) {
  return (dispatch: Dispatch) => {
    return request(CREATE_USER, '/api/users/', 'POST', {
      ...data,
      school,
    })(dispatch).then(response => {
      if (response) {
        dispatch(push('/users'));
        toast.success(`User ${data.username} created`);
      }
    });
  };
}

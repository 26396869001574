import { SimpleRecord } from '@dabapps/simple-records';

export interface NewClass {
  readonly name: string;
  readonly users: ReadonlyArray<string>;
  readonly is_extracurricular: boolean;
}
export const NewClass = SimpleRecord<NewClass>({
  name: '',
  users: [],
  is_extracurricular: false,
});

export interface EditClassFormClass {
  readonly id: string;
  readonly name: string;
  readonly users: ReadonlyArray<string>;
  readonly members: ReadonlyArray<string>;
  readonly is_extracurricular: boolean;
}
export const EditClassFormClass = SimpleRecord<EditClassFormClass>({
  id: '',
  name: '',
  users: [],
  is_extracurricular: false,
  members: [],
});

import { SimpleRecord } from '@dabapps/simple-records';

export interface BasicSchool {
  readonly id: string;
  readonly name: string;
  readonly location: string;
  readonly sso: string;
}

export const BasicSchool = SimpleRecord<BasicSchool>({
  id: '',
  name: '',
  location: '',
  sso: '',
});

export interface SchoolOption {
  value: string;
  label: string;
}

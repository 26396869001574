import React from 'react';
import { connect } from 'react-redux';

import { logout } from '^/common/authentication/actions';
import LogoutIcon from '^/common/icons/logout-icon';

interface DispatchProps {
  logout: typeof logout;
}

export class LogoutButton extends React.PureComponent<DispatchProps> {
  public render() {
    return (
      <a onClick={this.onLogout} className="primary">
        <LogoutIcon />
        Log out
      </a>
    );
  }
  private onLogout = () => {
    this.props.logout();
  };
}

export default connect(
  undefined,
  { logout }
)(LogoutButton);

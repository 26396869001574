import { Button, ContentBoxFooter, FormGroup } from '@dabapps/roe';
import FileField from '^/common/forms/fields/file-field';
import LoadingSpinner from '^/common/loading-spinner';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { ImportUsersCsvFile } from './types';

import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import { ResponseErrors } from '^/common/utils/errors';

export interface ExternalProps {
  isLoading: boolean;
  queueImportErrors?: ResponseErrors;
  importErrors: ResponseErrors | null;
}

export type FormProps = ExternalProps &
  InjectedFormProps<ImportUsersCsvFile, ExternalProps>;

export class ImportUsersForm extends React.PureComponent<FormProps> {
  public render() {
    const {
      handleSubmit,
      isLoading,
      queueImportErrors,
      importErrors,
    } = this.props;

    return (
      <form className="file-upload-form" onSubmit={handleSubmit}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <FormGroup block>
              <FormErrorRenderer
                formErrors={queueImportErrors}
                formErrorsKey="non_field_errors"
              />
              <label>Upload file</label>
              <Field name="csv_file" component={FileField} />
              <FormErrorRenderer
                formErrors={queueImportErrors}
                formErrorsKey="csv_file"
              />
            </FormGroup>
            <p>
              <a href="/static/mimove-import-students-template.csv">
                Download an example CSV template
              </a>{' '}
              to get started.
            </p>
            {importErrors && (
              <div>
                <p className="error">
                  Please correct the following errors in your CSV:
                </p>
                <FormErrorRenderer
                  formErrorsKey="non_field_errors"
                  formErrors={importErrors}
                />
              </div>
            )}
            <ContentBoxFooter>
              <Button type="submit" className="pill">
                Upload File
              </Button>
            </ContentBoxFooter>
          </div>
        )}
      </form>
    );
  }
}

export default reduxForm<ImportUsersCsvFile, ExternalProps>({
  form: 'import-users',
})(ImportUsersForm);

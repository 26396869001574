import ApiService from '../apiService';
import { ServiceType } from '../serviceTypes';
import {
  IGetUsersRequest,
  IGetUserResponse,
  IUserGeneric,
  IUserStudent,
} from '^/app/authenticated/users/types';
import { PAGE_SIZE } from '^/chadmin/list/AdminList';
import { IActivityPageUserDetail, IClusterAdmin } from './types';

class UsersApiClass {
  service: ApiService = new ApiService(ServiceType.USERS);
  public getUsersApi = ({
    school,
    pageNumber,
    pageSize,
    search,
  }: IGetUsersRequest):
    | Promise<IGetUserResponse<IUserGeneric>>
    | IGetUserResponse<IUserGeneric> => {
    return this.service.get<IGetUserResponse<IUserGeneric>>({
      route: [],
      query: {
        school,
        page: pageNumber,
        page_size: pageSize ? pageSize : PAGE_SIZE,
        search: search,
      },
    });
  };

  public getActivityPageUserDetails = (
    id: string
  ): IActivityPageUserDetail | Promise<IActivityPageUserDetail> => {
    return this.service.get({
      route: ['activity-page-user-detail', id],
    });
  };

  public getStudentsFromSchool = ({
    school,
    pageNumber,
    pageSize,
    ...otherFilters
  }: IGetUsersRequest) => {
    return this.service.get<IGetUserResponse<IUserStudent>>({
      route: ['students'],
      query: {
        school,
        page: pageNumber,
        page_size: pageSize,
        ...otherFilters,
      },
    });
  };

  public modifyClusterAdmin = (clusterAdmin: IClusterAdmin) => {
    return this.service.patch(
      {
        route: [clusterAdmin.id],
      },
      clusterAdmin
    );
  };
}

const UsersApi = new UsersApiClass();

export default UsersApi;

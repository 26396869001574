import { Button } from '@dabapps/roe';
import * as React from 'react';

interface ExternalProps {
  className?: string;
  confirmText: string;
  label: string;
  onConfirm: () => void;
}

export default class ActionConfirmation extends React.PureComponent<
  ExternalProps
> {
  public render() {
    const { className, label } = this.props;

    return (
      <Button onClick={this.openConfirmation} className={className || ''}>
        {label}
      </Button>
    );
  }

  private openConfirmation = () => {
    const { confirmText, onConfirm } = this.props;

    const confirmedByUser = window.confirm(confirmText);
    if (confirmedByUser) {
      onConfirm();
    }
  };
}

import * as React from 'react';

import { TColumns, TResponse } from '../types';
import { isPending } from '../utils';
import AdminListItems from './AdminListItems';

export const PAGE_SIZE = 12;
export const DEFAULT_PAGE = 1;
export const LIST_NAME_PREFIX = 'ADMINLIST_';

export interface IProps<T> {
  items: ReadonlyArray<T>;
  changePage?: (pageNumber: number) => void;
  itemCount: number;
  pageSize?: number;
  page?: number;
  selectedItems?: ReadonlyArray<T>;
  columns: TColumns<T>;
  listName: string;
  response?: TResponse;
}

export default class AdminList<T> extends React.PureComponent<IProps<T>, {}> {
  public constructor(props: IProps<T>) {
    super(props);
  }

  public render() {
    const {
      columns,
      changePage,
      response,
      items,
      pageSize,
      page,
      itemCount,
      selectedItems,
    } = this.props;

    return (
      <div>
        <AdminListItems
          items={items}
          page={page || DEFAULT_PAGE}
          itemCount={itemCount}
          columns={columns}
          isLoading={isPending(response)}
          pageSize={pageSize || PAGE_SIZE}
          changePage={changePage}
          selectedItems={selectedItems}
        />
      </div>
    );
  }
}

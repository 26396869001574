import { PAGE_SIZE } from '^/common/constants';
import ApiService from '../apiService';
import { ServiceType } from '../serviceTypes';
import {
  IBlobExport,
  IDashboardGraphDataRequest,
  IDashboardGraphDataResponse,
  IDashboardHeaderResponse,
  IDashboardSummaryDataResponse,
  IGlobalDashboardHeaderResponse,
  IGlobalDashboardSchool,
  IGraphDetailsRequest,
  IGraphDetailsResponse,
} from './types';

class DashboardApiClass {
  public fetchDashboardGraphData = ({
    cancelToken,
    school,
    className = '',
    grade = '',
    SEND = '',
    gender = '',
    ethnicity = '',
    start_date,
    end_date,
    year,
    otherGroups = '',
    eal = '',
    pupil_premium = '',
  }: IDashboardGraphDataRequest):
    | Promise<IDashboardGraphDataResponse>
    | IDashboardGraphDataResponse => {
    const service: ApiService = new ApiService(ServiceType.DASHBOARD);
    return service.get<IDashboardGraphDataResponse>(
      {
        route: [],
        query: {
          school,
          class: className,
          is_send_registered: SEND,
          is_eal: eal,
          is_pupil_premium: pupil_premium,
          gender,
          ethnicity,
          start_date,
          end_date,
          grade,
          year,
          other_group: otherGroups,
        },
      },
      false,
      cancelToken
    );
  };

  public fetchSummaryData = ({
    cancelToken,
    school,
    className = '',
    grade = '',
    SEND = '',
    gender = '',
    ethnicity = '',
    start_date,
    end_date,
    year,
    otherGroups = '',
    eal = '',
    pupil_premium = '',
  }: IDashboardGraphDataRequest):
    | Promise<IDashboardSummaryDataResponse>
    | IDashboardSummaryDataResponse => {
    const service: ApiService = new ApiService(ServiceType.DASHBOARD);
    return service.get<IDashboardSummaryDataResponse>(
      {
        route: ['activity-summary'],
        query: {
          school,
          class: className,
          is_send_registered: SEND,
          is_eal: eal,
          is_pupil_premium: pupil_premium,
          gender,
          ethnicity,
          start_date,
          end_date,
          grade,
          year,
          other_group: otherGroups,
        },
      },
      false,
      cancelToken
    );
  };

  public fetchGlobalDashboardData = ():
    | Promise<IGlobalDashboardSchool[]>
    | IGlobalDashboardSchool[] => {
    const service: ApiService = new ApiService(ServiceType.DASHBOARD);
    return service.get<IGlobalDashboardSchool[]>(
      {
        route: ['global'],
        query: {},
      },
      false
    );
  };

  public fetchGlobalDashboardHeaderData = ():
    | Promise<IGlobalDashboardHeaderResponse>
    | IGlobalDashboardHeaderResponse => {
    const service: ApiService = new ApiService(ServiceType.DASHBOARD);
    return service.get<IGlobalDashboardHeaderResponse>(
      {
        route: ['global-header'],
        query: {},
      },
      false
    );
  };

  public exportDashboardGraphData = ({
    school,
    grade = '',
    SEND = '',
    gender = '',
    ethnicity = '',
    className = '',
    start_date,
    end_date,
    year,
    otherGroups = '',
    eal = '',
    pupil_premium = '',
  }: IDashboardGraphDataRequest): Promise<IBlobExport> | IBlobExport => {
    const service: ApiService = new ApiService(ServiceType.DASHBOARD);
    return service.get<IBlobExport>(
      {
        route: ['export'],
        query: {
          school,
          is_send_registered: SEND,
          is_eal: eal,
          is_pupil_premium: pupil_premium,
          gender,
          ethnicity,
          start_date,
          end_date,
          grade,
          class: className,
          year,
          other_group: otherGroups,
        },
      },
      true
    );
  };

  public fetchDashboardHeaderData = (
    school: string
  ): Promise<IDashboardHeaderResponse> | IDashboardHeaderResponse => {
    const service: ApiService = new ApiService(ServiceType.DASHBOARD);
    return service.get<IDashboardHeaderResponse>({
      route: ['header'],
      query: {
        school,
      },
    });
  };

  public fetchGraphDetails = ({
    cancelToken,
    school,
    className = '',
    grade = '',
    SEND = '',
    gender = '',
    ethnicity = '',
    start_date,
    end_date,
    year,
    otherGroups = '',
    eal = '',
    pupil_premium = '',
    chartType = '',
    chartValue = '',
    pageNumber,
    pageSize,
    search,
  }: IGraphDetailsRequest):
    | Promise<IGraphDetailsResponse>
    | IGraphDetailsResponse => {
    const service: ApiService = new ApiService(ServiceType.DASHBOARD);
    return service.get<IGraphDetailsResponse>(
      {
        route: ['graph-users'],
        query: {
          school,
          class: className,
          is_send_registered: SEND,
          is_eal: eal,
          is_pupil_premium: pupil_premium,
          gender,
          ethnicity,
          start_date,
          end_date,
          grade,
          year,
          other_group: otherGroups,
          chart_type: chartType,
          chart_value: chartValue,
          page: pageNumber,
          page_size: pageSize ? pageSize : PAGE_SIZE,
          search: search,
        },
      },
      false,
      cancelToken
    );
  };
}

const DashboardApi = new DashboardApiClass();

export default DashboardApi;

import { Container } from '@dabapps/roe';
import { StoreState } from '^/store/types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoggedInUser } from './authentication/types';

interface DispatchProps {
  loggedInUser: LoggedInUser | null;
}

type Props = DispatchProps;

function TermsAndConditions(props: Props) {
  const { loggedInUser } = props;

  const content = (
    <>
      <h1 className="text-align-center">Terms and Conditions</h1>
      <p>
        We are Mi Move PA Limited trading as Mimove, a company registered with
        Companies House in England and Wales under number 11733518, whose
        registered office is at 8 Blackstock Mews, Islington, London, N4 2BT,
        United Kingdom (<span className="bold">“Mimove”/“we”/“us”/”our”</span>
        ). We provide online content accessible from websites such as
        Mimoveapp.com (
        <span className="bold">the “Websites”</span>) and mobile applications,
        including Mimove (<span className="bold">“Apps”</span>
        ). For the purpose of these terms and conditions (
        <span className="bold">“terms”</span>
        ), we refer to the online content we provide, including the Websites and
        the Apps as the “services”. These terms apply to us and all users of our
        services (<span className="bold">“you”/”your”</span>
        ). They govern your access to, downloading and use of all the services.
      </p>
      <p>
        Please read these terms carefully before using any of the services. They
        are a legal agreement between you and Mimove and to be read along with
        other policies made available by us on the Website and Apps, including
        but not limited to the <Link to="/privacy-policy">Privacy Policy</Link>.
        Using any of the services indicates that you accept these terms. If you
        do not accept these terms, please do not use the services, leave the
        Website, cease downloading the App, or leave the App.
      </p>
      <h2>Acceptance and updating to these terms</h2>
      <p>
        By using our services, you confirm that you are an educational, health
        or sports Organisation (“Organisation”) or a member having access
        through an Organisation which has purchased the services and have read
        and understood these terms and agree to abide by them. You confirm that
        you are old enough to enter into a binding agreement with us, and if
        not, that you have the permission of your parent or legal guardian, who
        will take responsibility for your compliance with these terms. You
        warrant that you are the owner of the device to which you have
        downloaded any App, or that you have obtained permission from the owner
        of that device to download the App. You accept responsibility in
        accordance with these terms for the use of Apps on any device, whether
        or not that device is owned by you.
      </p>

      <p>
        Mimove may update these terms from time to time. If any changes to the
        terms are made, we will notify you. From time to time, updates to Apps
        may be issued through your app store provider. Depending on the update,
        you may not be able to use an App, Website, services until you have
        installed the updates or downloaded the latest version of the App and
        accepted any new terms.
      </p>
      <h2>Fees and payment</h2>
      <p>
        Some services may be free for you to use. Some services require a
        payment from you. Some services may be free for a period, but then
        require a payment which may be taken automatically. Any particular
        payment terms of any paid for services will be made clear to you before
        you agree to pay for them and the relevant payment terms shall be
        incorporated into these terms by reference. When purchasing services
        such as a premium service you may make your purchase through one of our
        Websites or from an app store provider. If from an app store provider,
        as well as these terms, you will be subject to the terms of that app
        store provider.
      </p>

      <p>
        We may change the price and nature of services at our discretion, but if
        you are paying for services, we will always give you notice should the
        price change, or there is a significant change in the nature of the
        services that you are using. We accept no responsibility for any losses
        you may incur by failing to change or cancel a subscription after we
        have informed you of any changes to pricing or nature of services. If
        you cancel a subscription you cannot claim a refund for the period
        remaining until the end of the subscription period in which you
        cancelled.
      </p>
      <p>
        If you think that you may be entitled to a refund for services and you
        purchased those services from our Websites or from within one of our
        Apps you can contact us 8 Blackstock Mews, Islington, London, N4 2BT or{' '}
        <a href="mailto:info@mimoveapp.com">info@mimoveapp.com</a>.
      </p>
      <h2>Your account</h2>
      <p>
        If you are an Organisation, your purchase of the services would entitle
        you to a limited number of subscriber access (“user ID”) that you can
        allocate strictly only to your members including, their parents where so
        specifically approved or members of staff that need access for backend
        operations.
      </p>

      <p>
        Each user will need an account and password that is unique to the
        userID. You are responsible for maintaining the confidentiality of your
        password and account, and if you are an Organisation for the userIDs
        allocated by you, are fully responsible for all activities that occur
        under your password or account. Each account must be used by you and
        nobody else and is not transferable. You shall immediately notify Mimove
        should you suspect any unauthorised use of your account or password, you
        shall provide true, up-to-date and complete information about yourself,
        if you are an Organisation about all your userIds during registration
        and ensure this information remains up-to-date.
      </p>
      <h2>Promotion and marketing offers</h2>
      <p>
        We may offer promotional offers from time to time which may include
        discount codes or account credits, may be subject to expiration dates
        and may only be applicable to selected users of a service. We reserve
        the right to withhold or remove credit from a user account or end a
        promotion without notice if at our sole discretion we believe a user is
        not acting in good faith in relation to the terms of the promotion.
      </p>
      <h2>Confidentiality</h2>
      <p>
        Each party will keep confidential using the same standard of care
        necessary to maintain the confidentiality of its own proprietary
        information, and will not disclose or use any proprietary information of
        or disclosed by the other party, without the prior express written
        consent of the party to whom the proprietary information belongs or
        which has provided it (the “Disclosures”), except as permitted by this
        Agreement to each party agrees :
      </p>
      <ul>
        <li>
          To limit access to the Proprietary Information of the Disclosures only
          to those of its employees, consultants or agents who require access to
          the Proprietary Information;
        </li>
        <li>
          To ensure that those persons are bound by confidentiality obligations
          to the Disclosures no less strict than those set out in this
          Agreement; and
        </li>
      </ul>
      <p>
        Both parties agree that a breach of confidentiality obligations by the
        other party may cause immediate and irreparable monetary damage to the
        Disclosures and the Disclosures will be entitled to seek injunctive
        relief in addition to all other remedies.
      </p>
      <p>Intellectual property and licence grant</p>
      <p>
        Mimove acknowledges that the title to, and all Intellectual Property
        Rights in, the user data (including but not exclusively users’ profile
        information, and their activity data including the type of activity
        participated in, the duration of the activity and their feedback for the
        activity session) the property of the Organisation or the user as the
        case maybe. However, Mimove may be required to use, process, modify,
        store, make available and communicate the data for any purpose in
        connection with the exercise of its rights and performance of its
        obligations in accordance with the Agreement, Mimove is granted limited
        rights in the data to perform such actions.
      </p>
      <p>
        Mimove owns all worldwide intellectual property rights in its services
        including without limitation its Apps and Websites, including all
        software and all images, analytics, sounds, music, text, icons reports,
        templates generated and other content, including that generated upon
        modification, processing and derived out of the data (“Content”) within
        those services (other than personal information identifying a user) as
        well as the arrangement of that Content.
      </p>
      <p>
        Notwithstanding the above, Mimove owns exclusively any and all tangible
        and intangible rights throughout the world, including but not limited to
        rights associated with works of authorship including but not limited to
        copyrights, neighbouring rights, moral rights, and mask works, and such
        rights in all derivative works thereof in the “Likely Assessment
        Framework” tool and other proprietary data collection and processing
        tools used in performance of the services (“Tools”).
      </p>
      <p>
        Mimove grants you a limited, personal, non-transferable,
        non-sublicensable, worldwide and non-exclusive licence to use its
        services and Tools where authorised by us, for non-commercial use only
        and solely in accordance with these terms and any rules or policies
        applied by any app store provider or operator from whose site you
        downloaded an App.
      </p>
      <p>
        The grant and continuation of the above licence is conditional upon your
        compliance with these terms, our{' '}
        <Link to="/privacy-policy">Privacy Policy</Link> and any rules or
        policies applied by us or any app store provider from which you
        downloaded an App. You shall not (and shall not allow any third party
        to), except to the extent expressly permitted under applicable law,
        copy, modify, frame, create a derivative work from, reverse engineer,
        reverse assemble, or otherwise attempt to discover any source code in
        the services.
      </p>
      <p>
        Except as provided herein, you shall not sell, rent, lease, loan,
        redistribute, assign, sublicense, republish, grant a security interest
        in or otherwise transfer any right in the services, in whole or in part.
      </p>
      <p>
        You shall not remove or modify any copyright and/or other intellectual
        property notices or watermarks from any Content. Nothing in these terms
        grants you a licence to use any Mimove trade marks or the trade marks of
        any third parties in the services.
      </p>
      <h2>User Generated Content and your use of the services</h2>
      <p>
        Other than personal data (which is covered under our{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>
         ), any material which you create and which you transmit or post using
        the services (“User Generated Content”) shall be considered
        non-confidential and non-proprietary. Mimove shall have no obligations
        with respect to such material. You are solely responsible for such
        material. Mimove shall be free to copy, disclose, distribute,
        incorporate and otherwise use such material and all data, images,
        sounds, text and other things embodied therein for any and all
        commercial or non-commercial purposes.
      </p>
      <p>
        You are prohibited from posting to or transmitting using the services
        any material:
      </p>
      <ul>
        <li>
          that is threatening, defamatory, obscene, indecent, seditious,
          offensive, pornographic, abusive, liable to incite racial hatred,
          discriminatory, menacing, scandalous, inflammatory, blasphemous, in
          breach of confidence, in breach of privacy or which may cause
          annoyance or inconvenience;
        </li>
        <li>
          for which you have not obtained all necessary licences and/or
          approvals. In case of information pertaining to minors, for which you
          have not complied with applicable laws;
        </li>
        <li>
          which constitutes or encourages conduct that would be considered a
          criminal offence, give rise to civil liability, or otherwise be
          contrary to the law of or infringe the rights of any third party, in
          any country in the world;
        </li>
        <li>
          which is technically harmful (including, without limitation, computer
          viruses, logic bombs, Trojan horses, worms, harmful components,
          corrupted data or other malicious software or harmful data);
        </li>
        <li>
          contains or discloses another person’s personal information without
          his/her/guardian’s, written consent; or
        </li>
        <li>
          collects or solicits another person’s personal information for
          commercial or unlawful purposes.
        </li>
      </ul>
      <p>
        Mimove shall be entitled to remove any material from its services which
        is posted to or transmitted using the services in contravention of these
        terms, or for any other reason.
      </p>
      <p>You shall not:</p>
      <ul>
        <li>use the services or any Content in any unlawful manner;</li>
        <li>
          misuse the services (including, without limitation, by hacking or
          inserting malicious code);
        </li>
        <li>
          infringe our or any third party’s intellectual property rights in your
          use of the services or Content;
        </li>
        <li>use the services to transmit chain letters, junk or spam;</li>
        <li>use services to harass, abuse or harm another person;</li>
        <li>
          take any action that in our sole discretion places an unreasonable or
          disproportionately large load on our servers or other infrastructure;
          or
        </li>
        <li>
          use the services in any way which may cause, or be likely to cause,
          access to or use of the services to be interrupted, damaged or
          impaired in any way.
        </li>
      </ul>
      <h2>Privacy</h2>
      <p>
        We are committed to respecting your privacy and the privacy of all
        individuals using the services. More information regarding how we may
        use your personal data can be found in our{' '}
        <Link to="/privacy-policy">Privacy Policy</Link> . By using any of the
        services, you agree to the terms laid out in our{' '}
        <Link to="/privacy-policy">Privacy Policy</Link>
         which is incorporated into these terms by reference.
      </p>
      <h2>Termination of services</h2>
      <p>
        We may terminate your access and use of the services (or any of them)
        and these terms at our discretion if we have good reason to believe you
        have breached these terms. In such circumstances you are not entitled to
        any refund. We may modify or discontinue, temporarily or permanently,
        the services (or any part of them) at our discretion. We will give you
        notice of such modification or discontinuance wherever possible. You
        agree that Mimove shall not be liable to you or to any third party for
        any modification or discontinuance of the services.
      </p>
      <p>
        You may discontinue your use of the services (or any part of them) at
        any time.
      </p>
      <p>On termination of these terms for any reason:</p>
      <ul>
        <li>all rights granted to you under these terms shall cease;</li>
        <li>you shall no longer have access to any services; </li>
        <li>
          you must immediately cease all activities authorised by these terms;
          and
        </li>
        <li> you must immediately delete or remove Apps from your devices.</li>
      </ul>
      <h2>Links to and from other apps and websites</h2>
      <p>
        Any links to third party apps and websites, including but not limited to
        payment gateways, are provided solely for your convenience. We have not
        reviewed all of these third party apps and websites and do not control
        and are not responsible for these apps/websites or their content or
        availability. We do not endorse or make any representations about them,
        or any material found there, or any results that may be obtained from
        using them. If you decide to access any of the third party apps/websites
        linked to from our services, you do so entirely at your own risk.
      </p>
      <h2>Indemnity</h2>
      <p>
        You indemnify and hold Mimove, and its affiliates, and its and their
        officers, directors, agents and employees, harmless from any losses,
        damages, costs, expenses (including reasonable legal fees) or other
        liability, arising out of any claim, demand, allegation or proceeding
        brought by any third party due to or arising out of any breach by you of
        any of these terms or applicable law; your misuse of the services;
        Personal information including medical history, User Generated Content
        you provide or post; your infringement of intellectual property rights
        or any other third party rights; and/or your failure to provide
        accurate, up to date information.
      </p>
      <h2>Disclaimers</h2>
      <p>
        The material within the services is provided "as is", without any
        conditions, warranties or other terms of any kind (other than those
        required by applicable law and which cannot be excluded by contract) and
        is used by you at your own risk. Accordingly, to the maximum extent
        permitted by law, we provide you with the services on the basis that we
        exclude all representations, warranties, conditions and other terms
        which, but for these terms, might have effect in relation to the
        services.
      </p>
      <p>
        While we endeavour to ensure that the content contained within the
        services is correct, we do not warrant the accuracy and completeness of
        that content. We may make changes to the content at any time without
        notice. The content may be out of date and Mimove makes no commitment to
        update such material.
      </p>
      <p>
        Mimove does not provide medical advice and the services are not provided
        for the purpose of treating, assisting with, or providing any advice in
        relation to, any medical conditions. Mimove makes no claims,
        representations or warranties that the services provide any medical or
        therapeutic benefit. Health information (other than Personal
        information) if any, like dietary recommendations included in the
        services or linked to from the services is for general information
        purposes only and should not be relied upon as medical advice. If you
        have an existing condition which you think may be affected by using the
        services, you should contact a doctor before using the services.
      </p>
      <h2>Limitations and exclusions of liability</h2>
      <p>
        We shall not be liable in contract, tort (including, without limitation,
        negligence), for pre-contract or other representations, or otherwise
        arising out of or in connection with these terms for:
      </p>
      <ul>
        <li>
          any economic losses (including, without limitation, loss of revenues,
          profits, contracts, data, business, anticipated savings or cost of
          substitute services);
        </li>
        <li>any loss of goodwill or reputation; or</li>
        <li>any special, indirect or consequential losses,</li>
      </ul>
      <p>
        in any case, whether or not such losses were within either of our
        contemplations at the date of acceptance of these terms.
      </p>
      <p>
        Subject to the above, our liability to you in relation to all events or
        series of connected events occurring under these terms (and whether the
        liability arises because of breach of contract, negligence or for any
        other reason) shall be limited to the total amount of the sums you have
        paid for the services in the six month period immediately preceding the
        date of the first event allegedly giving rise to the liability.
      </p>
      <p>
        Nothing in these terms excludes any statutory rights which may apply to
        your use of the services which cannot be excluded, restricted or
        modified by contract.
      </p>
      <h2>General</h2>
      <p>
        These terms and the relationship between you and Mimove shall be
        governed by the laws of England without regard to any conflict of law
        provisions of any jurisdiction. Both you and Mimove agree to submit to
        the exclusive jurisdiction of the English courts, except that Mimove may
        seek injunctive relief in any jurisdiction in order to enforce its
        rights under these terms.
      </p>
      <p>
        We will not be liable or responsible for any failure to perform, or
        delay in performance of, any of our obligations under these terms that
        is caused by any act or event beyond our reasonable control, including
        failure of public or private telecommunications networks, change in
        government regulations (“Event Outside Our Control”). If an Event
        Outside Our Control takes place that affects the performance of our
        obligations under these terms of use:
      </p>
      <ul>
        <li>
          our obligations under these terms shall be suspended for the duration
          of the Event Outside Our Control; and
        </li>
        <li>
          we will use our reasonable endeavours to find a solution by which our
          obligations under these terms may be performed despite the Event
          Outside Our Control.
        </li>
      </ul>
      <p>
        No person other than you and Mimove shall have any rights under these
        terms in relation to your use of the services. We may transfer our
        rights and obligations under these terms to another organisation, but
        this will not affect your rights or our obligations under these terms.
        You may only transfer your rights or obligations under these terms if we
        agree in writing. With express authorisation from the user, Mimove may
        transfer a UserID and related data from one Organisation to the other
        for the purpose of service continuity. Please refer to the FAQ page for
        more information on such transfers.
      </p>
      <p>
        Any failure of or delay by Mimove to exercise or enforce any right or
        provision of the terms shall not constitute a waiver of this right or
        provision. If any provision of the terms is found by a court of
        competent jurisdiction to be invalid, the parties nevertheless agree
        that the court should endeavour to give effect to the parties’
        intentions as reflected in the provision, and the other provisions of
        the terms shall remain in full force and effect.
      </p>
      <p>
        You agree that these terms represent the entire understanding between
        you and Mimove in respect of your relationship with Mimove. These terms
        supersede any previous agreements between you and Mimove including any
        previous version of these terms.
      </p>
      <h2>Contact</h2>
      <p>
        Questions, comments and requests regarding our terms and conditions are
        welcomed and should be addressed to Mimove via email at{' '}
        <a href="mailto:info@mimoveapp.com">info@mimoveapp.com</a>
      </p>
      <p>
        Grievance cell- Please reach out to Marcella Griso at{' '}
        <a href="mailto:marcella@mimoveapp.com">marcella@mimoveapp.com</a> or by
        post to 8 Blackstock Mews, Islington, London, N4 2BT, United Kingdom for
        any complaints, grievance including those related to IPR or privacy
        matters.
      </p>
    </>
  );

  return loggedInUser ? (
    <Container className="margin-top-large">{content}</Container>
  ) : (
    content
  );
}

function mapStateToProps({ loggedInUser }: StoreState) {
  return {
    loggedInUser,
  };
}

export default connect(mapStateToProps)(TermsAndConditions);

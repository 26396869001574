import { Container, ContentBox, Row, Column } from '@dabapps/roe';
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ActivityApi from '^/api-services/activities/activities.service';
import {
  IStudentSummaryRequest,
  IUserActivitySummary,
} from '^/api-services/activities/types';
import useAPI from '^/api-services/useApi';
import { IActivityPageUserDetail } from '^/api-services/users/types';
import UsersApi from '^/api-services/users/users.service';

import { ActivitiesTable } from '^/app/authenticated/activities/activity-page/activities-table';
import AssessmentsTable from '^/app/authenticated/activities/activity-page/assessments-table';
import MessagesTable from '^/app/authenticated/activities/activity-page/messages-table';
import SummaryBanner from '^/app/authenticated/activities/activity-page/summary-banner';
import StudentDetailTabMenu, {
  TABLE_TYPES,
} from '^/app/authenticated/activities/activity-page/tab-menu';
import Title from '^/app/authenticated/activities/activity-page/title';
import { collections } from '^/common/collections';
import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import { StoreState } from '^/store/types';
import {
  IActivityFilters,
  IGetUserResponse,
  IGetUsersRequest,
  IUserStudent,
} from '../../users/types';
import { SelectedDashboardFilters } from '../overview/students/filters/reducers';
import { transformFilterOptionsToCollectionFilter } from '^/app/authenticated/activities/overview/students/filters/utils.ts';
import {
  DATE_RANGE_FORMAT,
  INPUT_DEBOUNCE_TIME,
  PAGE_SIZE,
} from '^/common/constants';
import { debounce } from 'underscore';
import { PendingTable } from './pending-table';
import { Profile } from './profile';
import moment, { Moment } from 'antd/node_modules/moment';
import { DatePicker } from 'antd';

const {
  actions: { getItem },
} = collections;

interface StateProps {
  schoolID: string;
  selectedActivityFilters: SelectedDashboardFilters;
}

interface DispatchProps {
  getItem: typeof getItem;
  getActivitiesSummary(studentId: string, data: any): void;
}

export interface RouteParamProps {
  studentId: string;
}

type Props = StateProps & DispatchProps & RouteComponentProps<RouteParamProps>;

export const ActivityPage: React.FC<Props> = (props: Props) => {
  const { match, location, schoolID } = props;
  const navPropPageNumber = location.state?.pageNumber;

  const [selectedStudentID, setSelectedStudentID] = useState(
    match.params?.studentId
  );
  const [pageNumber, setPageNumber] = useState(
    navPropPageNumber ? navPropPageNumber : 1
  );
  const [arrowNavigationDirection, setArrowNavigationDirection] = useState('');
  const [selectedTableType, setSelectedTableType] = useState(
    TABLE_TYPES.ACTIVITIES
  );
  // used to control the execution of checkIfItsCorrectPage function
  const [hasPageCheckExecuted, setHasPageCheckExecuted] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  // const [rangeFilter, setRangeFilter] = React.useState<[Moment, Moment] | null>(
  //   null
  // );

  const [activityType, setActivityType] = React.useState<string>('WEEK');
  const [recordsType, setRecordType] = React.useState<string>('WEEK');

  const { callAPI: fetchUserActivityApi, ...userActivityDetails } = useAPI<
    string,
    IActivityPageUserDetail
  >(UsersApi.getActivityPageUserDetails);
  const { callAPI: fetchActivitySummaryApi, ...activitySummary } = useAPI<
    IStudentSummaryRequest,
    IUserActivitySummary
  >(ActivityApi.getStudentSummary);
  const { callAPI: fetchStudentList, ...studentListOptions } = useAPI<
    IGetUsersRequest,
    IGetUserResponse<IUserStudent>
  >(UsersApi.getStudentsFromSchool);

  const { response: userDetail } = userActivityDetails;
  const { response: activitiesSummary } = activitySummary;
  const isLoading = userActivityDetails.loading || activitySummary.loading;
  const hasFailedLoading = userActivityDetails.error || activitySummary.error;

  useEffect(() => {
    if (selectedStudentID) {
      performAPIcalls(selectedStudentID);
    }
  }, [selectedStudentID]);

  useEffect(() => {
    if (selectedStudentID) {
      fetchActivitySummaryApi({
        studentId: selectedStudentID,
        data: {
          type: activityType,
          recordType: recordsType,
        },
      });
    }
  }, [selectedStudentID, activityType, recordsType]);

  useEffect(() => {
    match.params?.studentId && updateSelectedStudentID(match.params?.studentId);
  }, [match.params?.studentId]);

  const callFetchStudentListAPI = (search = searchQuery) => {
    if (pageNumber) {
      const { selectedActivityFilters } = props;
      fetchStudentList({
        pageNumber,
        pageSize: PAGE_SIZE,
        school: schoolID,
        search,
        ...((transformFilterOptionsToCollectionFilter(
          selectedActivityFilters
        ) as unknown) as IActivityFilters),
      });
    }
  };
  useEffect(callFetchStudentListAPI, [pageNumber]);

  // used to check if given student uuid exists in the loaded student list or page or not
  // If not present goes to next page. Continues till the end
  const checkIfItsCorrectPage = () => {
    const index = studentListOptions.response?.results.findIndex(
      (ele) => ele.id === selectedStudentID
    );
    if (index === -1) setPageNumber(pageNumber + 1);
    else setHasPageCheckExecuted(true);
  };

  // this useEffect is used to navigate to new studentlist page element when clicked on navigation buttons
  useEffect(() => {
    const resp = studentListOptions.response;
    if (resp) {
      switch (arrowNavigationDirection) {
        case ACTIVITY_NAVIGATION.PREVIOUS:
          updateSelectedStudentID(resp.results[resp.results.length - 1]?.id);
          break;
        case ACTIVITY_NAVIGATION.NEXT:
          updateSelectedStudentID(resp.results[0]?.id);
          break;
      }
      arrowNavigationDirection && setArrowNavigationDirection('');
      !hasPageCheckExecuted && checkIfItsCorrectPage();
    }
  }, [studentListOptions.response]);

  // logic for navigation button. Either fetches student details or goes to new studentList page
  const navigateStudentList = (direction: ACTIVITY_NAVIGATION) => {
    const resp = studentListOptions.response;
    if (resp) {
      let index = resp.results.findIndex((ele) => ele.id === selectedStudentID);
      setArrowNavigationDirection(direction);
      switch (direction) {
        case ACTIVITY_NAVIGATION.PREVIOUS:
          index -= 1;
          if (index < 0) {
            const pgNo = pageNumber - 1;
            if (pgNo < 0) setPageNumber(1);
            else setPageNumber(pgNo);
          } else {
            updateSelectedStudentID(resp.results[index]?.id);
          }
          break;
        case ACTIVITY_NAVIGATION.NEXT:
          index += 1;
          if (index > PAGE_SIZE - 1) {
            const pgNo = pageNumber + 1;
            const maxPgNo = Math.ceil(resp.count / PAGE_SIZE);
            if (pgNo > maxPgNo) setPageNumber(maxPgNo);
            else setPageNumber(pgNo);
          } else {
            updateSelectedStudentID(resp.results[index]?.id);
          }
          break;
      }
    }
  };

  // fetches all the api calls needed to populate the page for particular selected student id
  const performAPIcalls = (studentId: string) => {
    fetchUserActivityApi(studentId);
    setArrowNavigationDirection('');
    props.history.push({
      pathname: `/activities/${studentId}`,
      state: { pageNumber: navPropPageNumber },
    });
  };

  const updateSelectedStudentID = (id: string) => {
    if (id !== selectedStudentID) {
      setSelectedStudentID(id);
    }
  };

  const throttledGetStudentListApi = useCallback(
    debounce((search: string) => {
      setSearchQuery(search);
      callFetchStudentListAPI(search);
    }, INPUT_DEBOUNCE_TIME),
    []
  );

  const resetOptions = () => {
    setSearchQuery('');
    if (pageNumber !== 1) setPageNumber(1);
    else {
      callFetchStudentListAPI('');
    }
    setArrowNavigationDirection(ACTIVITY_NAVIGATION.NEXT);
  };

  const disabledDate = (current: Moment) => {
    return (
      // (oldestActivityDate && current < moment(oldestActivityDate)) ||
      current > moment(moment().toDate(), DATE_RANGE_FORMAT)
    );
  };

  // const updateRangeFilter = (data: [Moment, Moment]) => {
  //   setRangeFilter(data);
  // };

  // const onDatePickerChange = (data: [Moment, Moment] | null) => {
  //   if (Array.isArray(data) && data[0] && data[1]) {
  //     updateRangeFilter(data);
  //   } else {
  //     initializeRangeFilter();
  //   }
  // };

  // const initializeRangeFilter = () => {
  //   const today = moment(moment().toDate(), DATE_RANGE_FORMAT);
  //   const defaultStartDate = today.clone().add(-4, 'weeks');
  //   const defaultEndDate = today;
  //   setRangeFilter([defaultStartDate, defaultEndDate]);
  // };

  // React.useEffect(initializeRangeFilter, []);

  const renderTable = () => {
    switch (selectedTableType) {
      case TABLE_TYPES.PENDING:
        return <PendingTable userId={selectedStudentID} />;
      case TABLE_TYPES.ACTIVITIES:
        return <ActivitiesTable userId={selectedStudentID} />;
      case TABLE_TYPES.MESSAGES:
        return <MessagesTable userId={selectedStudentID} />;
      case TABLE_TYPES.ASSESSMENT:
        return <AssessmentsTable userId={selectedStudentID} />;
      case TABLE_TYPES.PROFILE:
        return <Profile userId={selectedStudentID} />;
    }
  };
  return (
    <div>
      {isLoading ? (
        <div className="padding-vertical-large">
          <LoadingSpinner />
        </div>
      ) : hasFailedLoading ? (
        <Container>
          <div className="padding-vertical-large">
            <ErrorMessage />
          </div>
        </Container>
      ) : userDetail ? (
        <>
          {studentListOptions && studentListOptions.response && (
            <Title
              userDetail={userDetail}
              students={studentListOptions.response.results}
              onStudentDropdownChange={updateSelectedStudentID}
              selectedStudentID={selectedStudentID}
              navigateStudentList={navigateStudentList}
              currentPage={pageNumber}
              totalCount={studentListOptions.response.count}
              onPageChange={setPageNumber}
              onSearch={throttledGetStudentListApi}
              resetOptions={resetOptions}
              searchFieldVisibility={hasPageCheckExecuted}
            />
          )}
          {/* <Container>
            <Row>
              <Column sm={12} md={4} mdOffset={4} className="mb-3">
                {rangeFilter && (
                  <DatePicker.RangePicker
                    format={DATE_RANGE_FORMAT}
                    value={rangeFilter}
                    disabledDate={disabledDate}
                    onCalendarChange={(data) =>
                      onDatePickerChange(data as [Moment, Moment])
                    }
                  />
                )}
              </Column>
            </Row>
          </Container> */}

          {activitiesSummary && (
            <SummaryBanner
              activitiesSummary={activitiesSummary}
              lastAssessment={userDetail.latest_assessment}
              hasSucceededUserDetail={!hasFailedLoading}
              activityType={activityType}
              recordType={recordsType}
              setActivityType={setActivityType}
              setRecordType={setRecordType}
            />
          )}
          <Container>
            <ContentBox className="margin-vertical-x-large padding-vertical-small">
              <StudentDetailTabMenu
                route={selectedTableType}
                userDetail={userDetail}
                onRouteChange={setSelectedTableType}
              />
              {renderTable()}
            </ContentBox>
          </Container>
        </>
      ) : null}
    </div>
  );
};

function mapStateToProps({
  currentSchool,
  selectedDashboardFilters,
}: StoreState) {
  return {
    schoolID: currentSchool,
    selectedActivityFilters: selectedDashboardFilters,
  };
}

export default connect(mapStateToProps, null)(ActivityPage);

export enum ACTIVITY_NAVIGATION {
  PREVIOUS = 'left',
  NEXT = 'right',
}

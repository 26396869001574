import { TChoice, TChoices } from './types';

export function getChoiceLabel(choice: TChoice): string {
  return choice.display_name;
}

export function isMultipleChoiceValue(
  value: TChoice | TChoices
): value is TChoices {
  return Array.isArray(value);
}

export function isSingularChoiceValue(
  value: TChoice | TChoices
): value is TChoice {
  return !isMultipleChoiceValue(value);
}

import {
  GET_COLLECTION,
  getCollectionResultsByName,
} from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import { Column, Container, ContentBox, Row } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import {
  GET_ITEM,
  getItemByName,
} from '@dabapps/redux-api-collections/dist/items';
import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import {
  DELETE_CLASS,
  deleteClass,
  EDIT_CLASS,
  editClass,
} from '^/app/authenticated/classes/class-management/edit-class/actions';
import { EditClassFormClass } from '^/app/authenticated/classes/class-management/types';
import { UserListUser } from '^/app/authenticated/users/types';
import { collections } from '^/common/collections';
import ActionConfirmation from '^/common/forms/buttons/action-confirmation';
import LoadingSpinner from '^/common/loading-spinner';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';
import EditCreateClassForm from '../form';

const {
  actions: { getCollection, getItem },
} = collections;

interface DispatchProps {
  getCollection: typeof getCollection;
  getItem: typeof getItem;
  editClass: typeof editClass;
  deleteClass: typeof deleteClass;
}

interface StateProps {
  availableStudents: ReadonlyArray<UserListUser>;
  studentsRequestHasFailed: boolean;
  editClassIsLoading: boolean;
  deleteClassIsLoading: boolean;
  currentSchool: string | null;
  errors?: ResponseErrors;
  classDetails: EditClassFormClass | undefined;
  classDetailsIsLoading: boolean;
  classDetailsRequestHasFailed: boolean;
}

export interface RouteParamProps {
  classId: string;
}

type Props = DispatchProps & StateProps & RouteComponentProps<RouteParamProps>;

const breadcrumbs = [
  { label: 'Classes & Groups', path: '/classes' },
  { label: 'Edit Class or Group', path: '' },
];

const CLASS_GROUPS_COLLECTION = 'cohorts/class-groups';
const STUDENT_COLLECTION_NAME = 'edit-class-students';

export class EditClass extends React.PureComponent<Props> {
  public componentDidMount() {
    this.getClassDetails();
  }

  public render() {
    const {
      deleteClassIsLoading,
      editClassIsLoading,
      classDetailsIsLoading,
      classDetails,
      errors,
    } = this.props;

    return (
      <Container className="margin-top-large">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Column sm={10} md={8} lg={6} smPush={1} mdPush={2} lgPush={3}>
            <ContentBox>
              {classDetailsIsLoading ? (
                <LoadingSpinner />
              ) : (
                <EditCreateClassForm
                  onSubmit={this.editClass}
                  classDetails={classDetails}
                  initialValues={classDetails}
                  errors={errors}
                  saveText="Save"
                  isLoading={editClassIsLoading}
                />
              )}
            </ContentBox>
            {!deleteClassIsLoading && (
              <div className="text-align-center margin-bottom-large">
                <ActionConfirmation
                  label="Delete"
                  onConfirm={this.deleteClass}
                  confirmText="Are you sure you'd like to delete this class?"
                  className="error hollow pill"
                />
              </div>
            )}
          </Column>
        </Row>
      </Container>
    );
  }

  public editClass = (data: Partial<EditClassFormClass>) => {
    if (this.props.classDetails) {
      this.props.editClass(data, this.props.classDetails.id);
    }
  };

  private getClassDetails = () => {
    const currentClass = this.props.match.params.classId;
    this.props.getItem(CLASS_GROUPS_COLLECTION, currentClass);
  };

  private deleteClass = () => {
    if (this.props.classDetails) {
      this.props.deleteClass(this.props.classDetails.id);
    }
  };
}

const mergeMembers = (classDetails: EditClassFormClass | undefined) => {
  if (classDetails && classDetails.members && classDetails.members.length > 0) {
    const { members, ...rest } = classDetails;
    return { ...rest, users: members };
  }

  return classDetails;
};

function mapStateToProps(state: StoreState): StateProps {
  const { responses, currentSchool } = state;
  const classDetails = getItemByName(state.items, CLASS_GROUPS_COLLECTION);
  return {
    availableStudents: getCollectionResultsByName(
      state.collections,
      'users',
      STUDENT_COLLECTION_NAME
    ) as ReadonlyArray<UserListUser>,
    studentsRequestHasFailed: hasFailed(responses, GET_COLLECTION, 'users'),
    editClassIsLoading: isPending(
      responses,
      EDIT_CLASS,
      CLASS_GROUPS_COLLECTION
    ),
    deleteClassIsLoading: isPending(
      responses,
      DELETE_CLASS,
      CLASS_GROUPS_COLLECTION
    ),
    currentSchool,
    errors: getResponseErrors(responses, EDIT_CLASS),
    classDetails: mergeMembers(
      classDetails as EditClassFormClass | undefined
    ) as EditClassFormClass | undefined,
    classDetailsIsLoading: isPending(
      responses,
      GET_ITEM,
      CLASS_GROUPS_COLLECTION
    ),
    classDetailsRequestHasFailed: hasFailed(
      responses,
      GET_ITEM,
      CLASS_GROUPS_COLLECTION
    ),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  getItem,
  editClass,
  deleteClass,
})(EditClass);

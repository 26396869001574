import { ETHNICITY, GENDER } from './types';
import { SelectOption } from '^/app/components/filters/select-filter';

export const GENDER_DISPLAY: Readonly<{ [K in GENDER]: string }> = {
  MALE: 'Male',
  FEMALE: 'Female',
  NON_BINARY: 'Non-Binary',
};

export const GENDERS = Object.keys(GENDER_DISPLAY) as ReadonlyArray<GENDER>;

export const ETHNICITY_DISPLAY: Readonly<{ [K in ETHNICITY]: string }> = {
  AOTH: 'Any other Asian background',
  ABAN: 'Bangladeshi',
  AIND: 'Indian',
  APKN: 'Pakistani',
  BCRB: 'Black Caribbean',
  BAFR: 'Black African',
  BOTH: 'Any other Black background',
  CHNE: 'Chinese',
  MWBA: 'Mixed: White/Black African',
  MOTH: 'Mixed: Any other mixed background',
  MWAS: 'Mixed: White/Asian',
  MWBC: 'Mixed: White/Caribbean',
  NOBT: 'Info not obtained',
  REFU: 'Refused',
  OOTH: 'Any other ethnic background',
  WOTH: 'Any other White background',
  WBRI: 'White: British',
  WIRI: 'White: Irish',
  WIRT: 'Traveller: Irish Heritage',
  WROM: 'Gypsy/Roma',
};

export const ETHNICITIES = Object.keys(ETHNICITY_DISPLAY) as ReadonlyArray<
  ETHNICITY
>;

export const NEW_GENDERS = {
  ...GENDER_DISPLAY,
  // NOT_DECLARED: 'Not Obtained',
};

export const generateBooleanFilterOptionsV2 = (
  displayPrefix: string
): SelectOption[] => {
  return [
    { id: 'true', name: `${displayPrefix} Yes` },
    { id: 'false', name: `${displayPrefix} No` },
  ];
};

export const BOOLEAN_FILTERS = {
  SEND: 'SEND',
  EAL: 'E.A.L.',
  PUPIL_PREMIUM: 'Pupil Premium',
};

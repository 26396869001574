import * as _ from 'underscore';

import { EditCreateUser } from '^/app/authenticated/users/user-management/types';

export function convertBooleanChoices(initialValues: {
  [key: string]: string | boolean | undefined | null;
}) {
  const formattedValues: { [key: string]: string | undefined | null } = {};
  if (initialValues) {
    Object.keys(initialValues).forEach(key => {
      const initialValue = initialValues[key];
      formattedValues[key] = _.isBoolean(initialValue)
        ? initialValue.toString()
        : initialValue;
    });
  }
  return (formattedValues as any) as EditCreateUser;
}

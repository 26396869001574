import React, { ReactNode } from 'react';
import { Switch, Space } from 'antd';
import './graph-card-styles.less';

interface IGraphCard {
  title: string;
  title2?: string;
  width: string;
  height: string;
  cardBodyPadding?: string;
  children: ReactNode;
  onToggleChange?: (bool: boolean) => void;
}

export const GraphCard: React.FC<IGraphCard> = (props: IGraphCard) => {
  const {
    title,
    title2,
    onToggleChange,
    width,
    height,
    children,
    cardBodyPadding: padding,
  } = props;
  return (
    <div className="graph-card" style={{ width, height }}>
      <Space className="card-header">
        <span>{title}</span>
        {title2 && onToggleChange && (
          <Space>
            <Switch defaultChecked onChange={onToggleChange} />
            <span>{title2}</span>
          </Space>
        )}
      </Space>
      <div className="card-body" style={{ padding }}>
        {children}
      </div>
    </div>
  );
};

import {
  GET_COLLECTION,
  getCollectionByName,
  getItemByName,
} from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import { Column, Container, ContentBox } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import CommonApis from '^/api-services/common/common.service';

import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import {
  CREATE_USER,
  createUser,
} from '^/app/authenticated/users/user-management/add-user/actions';
import {
  createUserFormConfig,
  createUserFormFields,
} from '^/app/authenticated/users/user-management/config';
import { ClassGroupOption } from '^/app/authenticated/users/user-management/types';
import { EditCreateUser } from '^/app/authenticated/users/user-management/types';
import { SelectOption } from '^/app/components';
import { AdminEditCreate, FormErrors } from '^/chadmin';
import { LoggedInUser, ROLES } from '^/common/authentication/types';
import { collections } from '^/common/collections';
import LoadingSpinner from '^/common/loading-spinner';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';
import { BasicSchool } from '^/app/authenticated/school-switcher/types';

const {
  actions: { getCollection, getItem },
} = collections;

interface DispatchProps {
  getCollection: typeof getCollection;
  createUser: typeof createUser;
  getItem: typeof getItem;
}

interface StateProps {
  loggedInUser: LoggedInUser | null;
  classGroups: ReadonlyArray<ClassGroupOption>;
  currentSchool: string | null;
  selectedRole?: ROLES;
  collectionIsLoading: boolean;
  createUserIsLoading: boolean;
  classGroupRequestHasFailed: boolean;
  errors?: ResponseErrors;
  currentSchoolData?: BasicSchool;
}

type Props = DispatchProps & StateProps;

interface IState {
  yearGroups: SelectOption[];
  classGroups: ClassGroupOption[];
  isSSO: boolean;
}

const breadcrumbs = [
  { label: 'Users', path: '/users' },
  { label: 'Add User', path: '' },
];

const setPendingUploadInForm = () => null;
const ADD_USER_FORM = 'ADD_USER_FORM';

export class AddUser extends React.Component<Props, IState> {
  // class variable to store initial yeargroups from BE
  initialYearGroup: SelectOption[] = [];
  initialClassGroup: SelectOption[] = [];
  constructor(props: Props) {
    super(props);
    this.state = {
      yearGroups: [],
      classGroups: [],
      isSSO: false,
    };
  }

  public componentDidMount() {
    if (this.props.currentSchool) {
      this.fetchClassGroups(this.props.currentSchool);
      this.fetchYearGroups(this.props.currentSchool);
      this.fetchCurrentSchool(this.props.currentSchool);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.currentSchoolData != prevProps.currentSchoolData) {
      this.setState({ isSSO: this.props.currentSchoolData?.sso != 'NONE' });
    }
  }

  fetchCurrentSchool = async (school: string) => {
    this.props.getItem('schools/school-switcher', school);
  };

  fetchYearGroups = async (school: string) => {
    const resp = await CommonApis.getYearGroups({
      school,
    });
    this.setState({
      yearGroups: [...resp.results],
    });
    this.initialYearGroup = [...resp.results];
  };

  addYearGroup = (newYearGroup: SelectOption, cb: () => void) => {
    this.setState({
      yearGroups: [...this.initialYearGroup, newYearGroup],
    });
    return cb();
  };

  addClassGroup = (newClassGroup: SelectOption, cb: () => void) => {
    this.setState({
      classGroups: [...this.initialClassGroup, newClassGroup],
    });
    return cb();
  };

  public render() {
    const {
      loggedInUser,
      selectedRole,
      collectionIsLoading,
      createUserIsLoading,
      classGroupRequestHasFailed,
      errors,
    } = this.props;
    const { yearGroups, classGroups } = this.state;

    return (
      <Container className="margin-top-large">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Column sm={10} md={8} lg={6} smPush={1} mdPush={2} lgPush={3}>
          <ContentBox>
            {collectionIsLoading ? (
              <div className="padding-vertical-large">
                <LoadingSpinner />
              </div>
            ) : (
              <AdminEditCreate
                className={'create-edit-form'}
                createItem={this.createUser}
                fields={createUserFormFields(
                  selectedRole,
                  false,
                  this.state.isSSO
                )}
                itemOptions={createUserFormConfig(
                  classGroups,
                  yearGroups,
                  this.addYearGroup,
                  this.addClassGroup,
                  selectedRole,
                  loggedInUser
                )}
                formName={ADD_USER_FORM}
                setPendingUploadInForm={setPendingUploadInForm}
                saveButtonText={'Add User'}
                itemErrors={errors as FormErrors}
                itemIsPending={createUserIsLoading}
                itemHasFailed={classGroupRequestHasFailed}
              />
            )}
          </ContentBox>
        </Column>
      </Container>
    );
  }

  public createUser = async (data: EditCreateUser) => {
    const { currentSchool } = this.props;
    if (currentSchool) {
      const yearGroupID = data.year_group;
      const classGroupID = data.class_group;

      if (yearGroupID) {
        const yearGroupIndex = this.initialYearGroup.findIndex(
          (ele) => ele.id === yearGroupID || ele.name === yearGroupID
        );
        if (yearGroupIndex === -1) {
          // create year group object in BE
          const resp = await CommonApis.createYearGroups(
            yearGroupID,
            currentSchool
          );
          if (resp) {
            data = { ...data, year_group: resp.id };
            this.initialYearGroup.push({ id: resp.id, name: resp.name });
          }
        } else
          data = {
            ...data,
            year_group: this.initialYearGroup[yearGroupIndex].id,
          };
      }

      if (classGroupID) {
        const classGroupIndex = this.initialClassGroup.findIndex(
          (ele) => ele.id === classGroupID || ele.name === classGroupID
        );
        if (classGroupIndex === -1) {
          // create year group object in BE
          const resp = await CommonApis.createClassGroups(
            classGroupID,
            currentSchool
          );
          if (resp) {
            data = { ...data, class_group: resp.id };
            this.initialClassGroup.push({ id: resp.id, name: resp.name });
            this.fetchClassGroups(currentSchool);
          }
        } else
          data = {
            ...data,
            class_group: this.initialClassGroup[classGroupIndex].id,
          };
      }

      this.props.createUser(data, currentSchool);
    }
  };

  public shouldComponentUpdate = (nextProps: Props) => {
    if (this.props.classGroups !== nextProps.classGroups) {
      this.setState({
        classGroups: [...nextProps.classGroups],
      });
      this.initialClassGroup = [...nextProps.classGroups];
    }

    return true;
  };
  /*
  NOTE: Currently, these methods are being used to retrieve all of the objects
  from their respective endpoints. Later on, this should be moved to a search
  in order to improve performance and allow schools to have more than 100
  classes.
  */

  public fetchClassGroups = (school: string) => {
    this.props.getCollection(
      'cohorts/class-group-options',
      {
        filters: { school },
        pageSize: 100,
      },
      ADD_USER_FORM
    );
  };
}

function mapStateToProps(state: StoreState): StateProps {
  const classGroupCollection = getCollectionByName(
    state.collections,
    'cohorts/class-group-options',
    ADD_USER_FORM
  );

  return {
    loggedInUser: state.loggedInUser,
    currentSchool: state.currentSchool,
    classGroups: classGroupCollection.results as ReadonlyArray<
      ClassGroupOption
    >,
    selectedRole: formValueSelector(ADD_USER_FORM)(state, 'role'),
    collectionIsLoading: isPending(state.responses, GET_COLLECTION),
    createUserIsLoading: isPending(state.responses, CREATE_USER),
    classGroupRequestHasFailed: hasFailed(
      state.responses,
      GET_COLLECTION,
      'cohorts/class-group-options'
    ),
    errors: getResponseErrors(state.responses, CREATE_USER),
    currentSchoolData: getItemByName(
      state.items,
      'schools/school-switcher'
    ) as BasicSchool,
  };
}

export default connect(mapStateToProps, {
  getCollection,
  createUser,
  getItem,
})(AddUser);

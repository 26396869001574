export const DASHBOARD_PAGE_LABELS: { [key: string]: string } = {
  class_group: 'Class:',
  other_groups: 'Groups:',
  year_group: 'Year',
  gender: 'Gender:',
  SEND: 'SEND:',
  ethnicity: 'Ethnicity:',
  school: 'School:',
  eal: 'E.A.L:',
  pupil_premium: 'Pupil Premium:',
  failed_to_load: 'Failed to load dashboard data',
  no_of_students: 'No. of Students',
  no_of_students_full: 'Number of Students',
  trends_avg_mins: 'Avg. Minutes Per Week',
  active_students: 'Active Students',
  inactive_students: 'Inactive Students',
  no_data: 'No data to display',
  class_breakdown_graph_title: 'By Class',
  year_breakdown_graph_title: 'By Year',
  activity_breakdown_graph_title: 'By Minutes',
  sessions_breakdown_graph_title: 'By Sessions',
  summary_data_toggle_title: 'Summary Data',
  headline_data_toggle_title: 'Headline Data',
  activity_locations_graph_title: 'Activity Locations',
  trends_graph_title: 'Trends',
  emotional_response_graph_title: 'Emotional Response',
  export_data_popup_title: 'Downloaded data will be based on applied filters',
  export_data_popup_success: 'Okay',
  export_data_popup_failure: 'Cancel',
  export_data: 'Export Data',
  highlights_total_user_count: '#Students',
  highlights_total_wau: '#Active this week',
  highlights_total_mau: '#Active this month',
  highlights_total_noactivitieslogged: '#Inactive Students',
  highlights_total_neverlogged: '#Not Logged in',

  highlights_summary_total_students: '#Students',
  highlights_summary_most_active_activity: 'Activity',
  highlights_summary_most_active_time: 'Time of Day',
  highlights_summary_most_active_day: 'Day of the Week',
  highlights_summary_total_active_time: 'Active Time',
  highlights_summary_total_active_sessions: '#Active Sessions',
  highlights_summary_average_active_time: 'Avg. Active Time',
  highlights_summary_average_active_sessions: 'Avg. Active Sessions',
  highlights_summary_most_common_emotion: 'Emotional Response',
  highlights_summary_most_common_place: 'Setting',
  highlights_summary_unique_out_of_school_students: '#Active Out of School',

  highlights_summary_total_students_description: 'Number of miMove accounts',
  highlights_summary_most_active_activity_description: 'Most logged activity',
  highlights_summary_most_active_time_description:
    'Most active time of the day',
  highlights_summary_most_active_day_description: 'Most active day of the week',
  highlights_summary_total_active_time_description:
    'Total active time in minutes',
  highlights_summary_total_active_sessions_description:
    'Total number of sessions',
  highlights_summary_average_active_time_description:
    'Avg. active time in minutes per student',
  highlights_summary_average_active_sessions_description:
    'Avg. number of sessions per student',
  highlights_summary_most_common_emotion_description:
    'Most popular emotional response',
  highlights_summary_most_common_place_description: 'Most popular setting',
  highlights_summary_unique_out_of_school_students_description:
    'No. of Students active out of school',

  activity_summary_graph_title: 'Distribution by Activity',
  ethnicity_summary_graph_title: 'Distribution by Ethnicity',
  class_summary_graph_title: 'Distribution by Class',
  year_summary_graph_title: 'Distribution by Year',

  highlights_total_user_count_description: 'Total number of miMove accounts',
  highlights_total_wau_description:
    'Number of students who have posted at least one activity in the last',
  highlights_total_mau_description:
    'Number of students who have posted at least one activity in the last',
  highlights_total_noactivitieslogged_description:
    'Number of students who have posted no activities so far',
  highlights_total_neverlogged_description:
    'Number of students who have not used miMove even once',

  highlights_total_students: '#Students',
  highlights_weekly_active_students: '#Active this week',
  highlights_monthly_active_students: '#Active this month',
  highlights_inactive_students: '#Inactive Students',
  highlights_total_students_description:
    'Total number of miMove accounts in your school',
  highlights_weekly_active_students_description:
    'Number of students who have posted at least one activity in the last',
  highlights_monthly_active_students_description:
    'Number of students who have posted at least one activity in the last',
  highlights_inactive_students_description:
    'Number of students who have not posted any activity in the last',
  no_school_assigned_description:
    'No school is assigned to you, please contact Admin',
  total: 'Total',
  is: 'is',
  days: 'days',
  highlights_weekly_active_students_tooltip:
    'Percentage change from previous week',
  highlights_monthly_active_students_tooltip:
    'Percentage change from previous month',
  highlights_inactive_students_tooltip:
    'Percentage change from previous two weeks',
  quarantineDescription: 'This data will be available after',
  quarantineDescriptionEnd: ' from the date you started using miMove',
};

export const ACTIVITIES_LABELS = {
  SEARCH_STUDENTS: 'Search student by name',
  addActivityBtn: 'Add Activity',
  resetSelectionBtn: 'Reset Selection',
};

export const ACTIVITY_PAGE_NAVIGATION = {
  previousStudent: 'Previous Student',
  nextStudent: 'Next Student',
  filterApplied: '* Results based on Activities filter',
};

export const ADD_USER_YEAR_GROUP = (val: string): string =>
  `Press ENTER to choose "${val}" as Year group`;

export const ADD_CLASS = (val: string): string =>
  `Press ENTER to choose "${val}" as a class`;

export const MESSAGE_PAGE_LABELS = {
  buttonTitle: 'Message Students',
  messageAllPopupTitle: (val: number): string =>
    `Message will be sent to ${val} ` + (val > 1 ? 'students' : 'student'),
  messageAllPopupSuccess: 'Okay',
  messageAllPopupFailure: 'Cancel',
};

export const TABLE_EMPTY_CELL = 'None';

export const ACTIVITIES_TABLE_COLUMNS = {
  studentName: 'Student',
  lastAssessmentStatus: 'Last Assessment',
  lastActivity: 'Last Activity',
  activitiesCount: 'Activities',
  pendingActivitiesCount: 'Pending Activities',
  totalActivityMins: 'Total mins',
  actions: ' ',
  yearGroup: 'Year Group',
};

export const ADD_ACTIVITY_LABELS = {
  title: (val: number): string =>
    `Add activity for ${val} ` + (val > 1 ? 'students' : 'student'),
  formLabels: {
    activity: 'Activity',
    otherActivity: 'Specify the activity name',
    duration: 'Duration',
    location: 'Location',
    date: 'Date',
    isRecurring: 'Is the activity recurring?',
    recurringDays: 'Recurring days',
    recurringEndDate: 'Recurring end date',
  },
  required: 'This field is required!',
  submitBtn: 'Submit',
  successMsg: 'Activity logged successfully',
};

export const USER_LABELS = {
  searchUsers: 'Search user by name',
  user: 'Users',
  importStudents: 'Import Students',
  exportStudents: 'Export Students',
  addUser: 'Add User',
  edit: 'Edit',
  columns: {
    name: 'NAME',
    role: 'ROLE',
    dateCreated: 'DATE CREATED',
    action: 'ACTION',
  },
};

export const RECURRING_ACTIVITIES_LABELS = {
  columns: {
    activity: 'Activity',
    duration: 'Duration',
    location: 'Location',
    studentsCount: 'No. of Students',
    recurringDates: 'Recurring Dates',
    createdBy: 'Created By',
    actions: 'Actions',
  },
  deleteBtn: 'Delete',
  deleteSuccess: 'Activity deleted successfully',
};

export const SCHOOLS_LABELS = {
  searchSchools: 'Search school by name',
  rootTableTitle: 'School List',
  button: {
    addSchool: 'Add School',
    export: 'Export',
    viewOrEdit: 'View/Edit',
  },
  column: {
    schoolName: 'SCHOOL NAME',
    location: 'LOCATION',
    created: 'CREATED',
    action: '	ACTION',
  },
  modal: {
    title: (id: string): string => (id ? 'Edit School' : 'Create School'),
    schoolName: 'School name',
    location: 'Location',
    country: 'Country',
    photoUpload: 'Allow photo upload',
    sso: 'Single SignOn',
    wonde_id: 'Wonde Id',
    ruleSet: 'Select the automated messaging rules:',
    submitBtn: 'Submit',
    required: 'This field is required!',
    onCreateSuccessMsg: 'School created successfully',
    onUpdateSuccessMsg: 'School updated successfully',
  },
};

export const GLOBAL_DASHBOARD_LABELS = {
  column: {
    schoolName: 'SCHOOL NAME',
    wau: 'WAU',
    mau: 'MAU',
    noactivitieslogged: 'NO ACTIVITIES LOGGED',
    neverloggedin: 'NEVER LOGGED IN',
    userCount: 'STUDENTS',
  },
};

export const CLUSTER_ADMIN_LABELS = {
  navTitle: 'Cluster Users',
  submitBtn: 'Submit',
  fName: 'First Name',
  lName: 'Last Name',
  email: 'Email',
  tempPassword: 'Temporary Password',
  isClusterManager: 'Cluster Manager?',
  isRequired: 'is required',
  isInvalid: 'is invalid',
  clusterName: 'Cluster Name',
  addAdmin: 'Add Cluster User',
  clusterAdmins: 'Cluster Users',
  areYouSure: 'Are you sure?',
  yes: 'Yes',
  no: 'No',
  addCluster: 'Add Cluster',
  addSubCluster: 'Add Sub Cluster',
  editCluster: 'Edit Cluster',
  editClusterUser: 'Edit Cluster Users',
  error: 'Sorry, Something went wrong',
  newClusterBtn: 'New Cluster',
  rootTableTitle: 'Cluster List',
  name: 'Name',
  schoolCount: 'No. of schools',
  modifiedBy: 'Last Modified by',
  modifiedDate: 'Last Modified Date',
  clusterCreationSuccess: 'Cluster created successfully',
  clusterEditSuccess: 'Cluster details updated successfully',
  deleteSuccess: 'Cluster deleted successfully',
  reload: 'Reload',
  schoolModal: {
    Title: 'Manage Schools',
    addBtn: 'Add',
    nameCol: 'Name',
    locationCol: 'Location',
  },
  columns: {
    view: 'View',
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
  },
  menuOptions: {
    add: 'Add Sub Cluster',
    edit: 'Edit Cluster',
    school: 'Manage School',
    delete: 'Delete Cluster',
  },
  deleteConfirm: {
    title: 'Are you sure?',
    content: "This will delete this cluster and all it's Sub Clusters.",
  },
};

export const SCHOOL_SETTINGS_LABELS = {
  columns: {
    year: 'Year',
    ownClass: 'Their own class',
    ownYear: 'Their own year',
    groups: 'Their groups',
    anyStudent: 'Any student in the school',
  },
};

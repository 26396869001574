import React, { useEffect, useState, useCallback } from 'react';
import { DatePicker, Space } from 'antd';
import moment, { Moment } from 'antd/node_modules/moment';

import { DATE_RANGE_FORMAT } from '^/common/constants';
import {
  NEW_GENDERS,
  ETHNICITY_DISPLAY,
  ETHNICITIES,
  generateBooleanFilterOptionsV2,
  BOOLEAN_FILTERS,
} from '^/common/users/constants';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';

import useAPI from '^/api-services/useApi';
import SchoolsApi from '^/api-services/schools/schools.service';
import CommonApis from '^/api-services/common/common.service';
import {
  IYearGroupsRequest,
  IYearGroupsResponse,
} from '^/api-services/common/types';
import {
  ISchoolsRequest,
  ISchoolsResponse,
} from '^/api-services/schools/types';
import { debounce } from 'underscore';

import { SelectFilter, SelectOption } from '^/app/components';

import './graph-filters-styles.less';
import { GraphFilterProps } from '../types';
import { PAGE_SIZE, INPUT_DEBOUNCE_TIME } from '^/common/constants';

export const GraphFilters: React.FC<GraphFilterProps> = (
  props: GraphFilterProps
) => {
  const {
    currentSchool,
    selectedSchool,
    defaultRange,
    onRangeChange,
    onClassChange,
    onGroupChange,
    onYearChange,
    onSENDChange,
    selectedClasses,
    selectedYear,
    selectedGroups,
    onGenderChange,
    onEthnicityChange,
    onSchoolChange,
    resetRangeFilter,
    onPupilPremiumChange,
    onEALChange,
    oldestActivityDate,
    showSchool,
    onSchoolReset,
  } = props;
  const { callAPI: getYearGroups, ...getYearGroupsResponse } = useAPI<
    IYearGroupsRequest,
    IYearGroupsResponse
  >(CommonApis.getYearGroups);
  const { callAPI: getClassGroups, ...getClassGroupsResponse } = useAPI<
    IYearGroupsRequest,
    IYearGroupsResponse
  >(CommonApis.getClassGroups);

  const { callAPI: getOtherGroups, ...getOtherGroupResponse } = useAPI<
    IYearGroupsRequest,
    IYearGroupsResponse
  >(CommonApis.getOtherGroups);

  const [page, setPage] = useState(1);
  const [searchQuery, setSearch] = useState('');
  const [schoolMatch, setSchoolMatch] = useState(false);

  useEffect(() => {
    if (selectedSchool) {
      getYearGroups({ school: selectedSchool });
      getClassGroups({ school: selectedSchool });
      getOtherGroups({ school: selectedSchool });
    }
  }, [selectedSchool]);

  const { callAPI: getAllSchools, ...allSchoolsResponse } = useAPI<
    ISchoolsRequest,
    ISchoolsResponse
  >(SchoolsApi.fetchSchoolsData);

  const fireGetAllSchools = (search = searchQuery) => {
    if (showSchool) {
      getAllSchools({
        page,
        page_size: PAGE_SIZE,
        search,
      });
    }
  };

  useEffect(() => {
    fireGetAllSchools();
  }, [page]);

  const recursiveSchoolQuery = () => {
    if (allSchoolsResponse.response) {
      const index = (allSchoolsResponse.response.results || []).findIndex(
        (ele) => ele.id === selectedSchool
      );
      if (index === -1) setPage((page) => ++page);
      else setSchoolMatch(true);
    }
  };

  useEffect(() => {
    schoolMatch === false && recursiveSchoolQuery();
  }, [allSchoolsResponse?.response]);

  const genderOptions = (): SelectOption[] => {
    const options: SelectOption[] = [];
    Object.keys(NEW_GENDERS).map((gender) =>
      options.push({
        id: gender as string,
        name: (NEW_GENDERS as any)[gender],
      })
    );
    return options;
  };

  const SENDOptions = (): SelectOption[] => {
    return generateBooleanFilterOptionsV2(BOOLEAN_FILTERS.SEND);
  };

  const EALOptions = (): SelectOption[] => {
    return generateBooleanFilterOptionsV2(BOOLEAN_FILTERS.EAL);
  };

  const pupilPremiumOptions = (): SelectOption[] => {
    return generateBooleanFilterOptionsV2(BOOLEAN_FILTERS.PUPIL_PREMIUM);
  };

  const ethnicityOptions = (): SelectOption[] => {
    const options: SelectOption[] = [];
    ETHNICITIES.forEach((ethnicity) => {
      options.push({
        id: ethnicity,
        name: ETHNICITY_DISPLAY[ethnicity],
      });
    });
    return options;
  };

  // disable future dates and prior to oldest activity of the school
  const disabledDate = (current: Moment) => {
    return (
      (oldestActivityDate && current < moment(oldestActivityDate)) ||
      current > moment(moment().toDate(), DATE_RANGE_FORMAT)
    );
  };

  const onDatePickerChange = (data: [Moment, Moment] | null) => {
    if (Array.isArray(data) && data[0] && data[1]) {
      onRangeChange(data);
    } else {
      resetRangeFilter();
    }
  };

  const resetSchool = () => {
    onSchoolReset();
    setSearch('');
    if (currentSchool !== selectedSchool) setSchoolMatch(false);
    if (page !== 1) setPage(1);
    else fireGetAllSchools();
  };

  const throttledGetSchoolsApi = useCallback(
    debounce((search: string) => {
      setSearch(search);
      fireGetAllSchools(search);
    }, INPUT_DEBOUNCE_TIME),
    []
  );

  const onPageChange = (newPage: number) => {
    if (page !== newPage) {
      setPage(newPage);
    }
  };

  const updateSchool = (data: string) => {
    if (data) {
      setSearch('');
      onSchoolChange(data);
    }
  };

  const showSchoolFilter = () => {
    return (
      <SelectFilter
        style={{ minWidth: '7em' }}
        dropdownMatchSelectWidth={250}
        loading={schoolMatch ? allSchoolsResponse.loading : true}
        optionList={allSchoolsResponse.response?.results || []}
        currentPageNumber={page}
        totalCount={allSchoolsResponse.response?.count}
        onPageChange={onPageChange}
        showSearch
        onSearch={throttledGetSchoolsApi}
        label={DASHBOARD_PAGE_LABELS.school}
        onChange={(data) => updateSchool(data)}
        // onClear={resetSchool}
        value={schoolMatch ? (selectedSchool as string) : ''}
      />
    );
  };

  return (
    <div className="filters-container mb-3 flex-row">
      <Space size={'middle'}>
        {/* defaultPickerValue={[defaultRange[0],defaultRange[1].clone().add(-1,'month')]}
          this prop can be used to decide what months to be displayed in the rangePicker
        */}
        <DatePicker.RangePicker
          format={DATE_RANGE_FORMAT}
          value={defaultRange}
          disabledDate={disabledDate}
          onCalendarChange={(data) =>
            onDatePickerChange(data as [Moment, Moment])
          }
        />
        {showSchool && showSchoolFilter()}
        <SelectFilter
          value={selectedYear}
          mode="multiple"
          style={{ minWidth: '7em' }}
          dropdownMatchSelectWidth={120}
          loading={getYearGroupsResponse.loading}
          optionList={getYearGroupsResponse.response?.results || []}
          label={DASHBOARD_PAGE_LABELS.year_group}
          onChange={(data) => onYearChange(data)}
        />
        <SelectFilter
          value={selectedClasses}
          mode="multiple"
          style={{ minWidth: '7em' }}
          dropdownMatchSelectWidth={120}
          loading={getClassGroupsResponse.loading}
          optionList={getClassGroupsResponse.response?.results || []}
          label={DASHBOARD_PAGE_LABELS.class_group}
          onChange={(data) => onClassChange(data)}
        />
        <SelectFilter
          value={selectedGroups}
          mode="multiple"
          style={{ minWidth: '7em' }}
          dropdownMatchSelectWidth={120}
          loading={getOtherGroupResponse.loading}
          optionList={getOtherGroupResponse.response?.results || []}
          label={DASHBOARD_PAGE_LABELS.other_groups}
          onChange={(data) => onGroupChange(data)}
        />
        <SelectFilter
          mode="multiple"
          style={{ minWidth: '7em' }}
          dropdownMatchSelectWidth={120}
          optionList={genderOptions()}
          label={DASHBOARD_PAGE_LABELS.gender}
          onChange={(data) => onGenderChange(data)}
        />
        <SelectFilter
          mode="multiple"
          style={{ minWidth: '7em' }}
          dropdownMatchSelectWidth={120}
          optionList={SENDOptions()}
          label={DASHBOARD_PAGE_LABELS.SEND}
          onChange={(data) => onSENDChange(data)}
        />
        <SelectFilter
          mode="multiple"
          style={{ minWidth: '7em' }}
          dropdownMatchSelectWidth={150}
          optionList={ethnicityOptions()}
          label={DASHBOARD_PAGE_LABELS.ethnicity}
          onChange={(data) => onEthnicityChange(data)}
        />
        <SelectFilter
          style={{ minWidth: '7em' }}
          mode="multiple"
          dropdownMatchSelectWidth={150}
          optionList={EALOptions()}
          label={DASHBOARD_PAGE_LABELS.eal}
          onChange={(data) => onEALChange(data)}
        />
        <SelectFilter
          style={{ minWidth: '7em' }}
          mode="multiple"
          dropdownMatchSelectWidth={150}
          optionList={pupilPremiumOptions()}
          label={DASHBOARD_PAGE_LABELS.pupil_premium}
          onChange={(data) => onPupilPremiumChange(data)}
        />
      </Space>
    </div>
  );
};

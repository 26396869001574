import classNames from 'classnames';
import * as React from 'react';

import { ASSESSMENT_STATUS_DETAILS } from '^/app/authenticated/assessments/constants';
import { Status } from '^/app/authenticated/assessments/types';

interface Props {
  status: Status;
  customClassNames?: string;
}

export default function StatusPill({ status, customClassNames }: Props) {
  const colour = ASSESSMENT_STATUS_DETAILS[status].colour;
  let pillClassName = `display-inline-block status-pill status-pill-${colour}`;
  if (customClassNames) {
    pillClassName = classNames(pillClassName, customClassNames);
  }
  return (
    <span className={pillClassName}>
      {ASSESSMENT_STATUS_DETAILS[status].displayName}
    </span>
  );
}

import * as React from 'react';
import { FieldType } from '../../../types';

import FieldGroup from '../FieldGroup';
import { TWrappedFieldProps } from './types';

export const PASSWORD_FIELDS = ['password', 'password_1', 'password_2'];

function shouldForcePasswordType(name: string) {
  return PASSWORD_FIELDS.indexOf(name) !== -1;
}

function getFieldType(name: string, type: FieldType): FieldType {
  if (shouldForcePasswordType(name)) {
    return 'password';
  }

  return type || 'text';
}

export const FieldChar = ({
  readOnly,
  config,
  name,
  errors,
  value,
  onChange,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) => {
  return readOnly ? (
    <FieldGroup config={config} errors={errors}>
      <span
        className="input"
        dangerouslySetInnerHTML={{
          __html: config.format ? config.format(value) : value,
        }}
      >
        {/* {config.format ? config.format(value) : value} */}
      </span>
    </FieldGroup>
  ) : (
    <FieldGroup config={config} errors={errors}>
      <input
        type={getFieldType(name, config.type)}
        name={name}
        placeholder={config.placeholder}
        onChange={onChange}
        value={value}
      />
    </FieldGroup>
  );
};

export default FieldChar;

import { SimpleRecord } from '@dabapps/simple-records';

export interface ClassListClass {
  readonly id: string;
  readonly name: string;
  readonly student_count: number;
  readonly created: string;
  readonly is_extracurricular: boolean;
}
export const ClassListClass = SimpleRecord<ClassListClass>({
  id: '',
  name: '',
  student_count: 0,
  created: '',
  is_extracurricular: false,
});

export interface ClassListUser {
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly class_group: string;
}
export const ClassListUser = SimpleRecord<ClassListUser>({
  id: '',
  first_name: '',
  last_name: '',
  class_group: '',
});

import { LoggedInUser } from '^/common/authentication/types';

export function isLoggedInUser(
  loggedInUser: LoggedInUser | null,
  editingUserId?: string
): boolean {
  return Boolean(
    loggedInUser && editingUserId && loggedInUser.id === editingUserId
  );
}

export function hasTemporaryPassword(loggedInUser: LoggedInUser | null) {
  return Boolean(loggedInUser && loggedInUser.has_temporary_password);
}

export function getChangePasswordPath(userId: string) {
  return `/users/edit-user/${userId}/change-password`;
}

import * as React from 'react';

import FieldGroup from '../FieldGroup';
import { TWrappedFieldProps } from './types';

export const FieldNumber = ({
  readOnly,
  config,
  name,
  errors,
  value,
  onChange,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) => {
  return readOnly ? (
    <FieldGroup config={config} errors={errors}>
      <span className="input">{value}</span>
    </FieldGroup>
  ) : (
    <FieldGroup config={config} errors={errors}>
      <input
        type="number"
        name={name}
        step={config.step}
        placeholder={config.placeholder}
        /* tslint:disable-next-line:jsx-no-lambda */
        onChange={event => onChange(event)}
        value={value}
      />
    </FieldGroup>
  );
};

export default FieldNumber;

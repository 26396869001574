import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Table,
  Divider,
  Typography,
  Spin,
  Checkbox,
} from 'antd';
import {
  EditableCellProps,
  IAddClusterModal,
  IClusterAdminRecord,
  ClusterAdminColumnsIndex,
  ClusterAdminTableTypes,
} from './types';
import { uniqueId } from 'underscore';
import { toast } from 'react-toastify';
import { ClusterAdminColumns } from './addClusterTable.helper';
import { CLUSTER_ADMIN_LABELS } from '^/common/labels-english';
import ClusterApis from '^/api-services/cluster/cluster.service';
import { ICreateCluster, IEditCluster } from '^/api-services/cluster/types';
import useAPI from '^/api-services/useApi';
import axios from '^/api-services/axiosInstance';
import { toSentenceCase } from '^/common/utils/text-utils';
import { UserRoles } from '^/common/authentication/types';

const { Title } = Typography;

const EMPTY_ROW = 'EMPTY_ROW';
const NEW_ADDED_ROW = 'NEW_CLUSTER_ADMIN';

export default function AddClusterModal({
  closeModal,
  parentTreeID,
  clusterID,
  clusterManager,
}: IAddClusterModal): JSX.Element {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<IClusterAdminRecord[]>([]);
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: IClusterAdminRecord) => record.key === editingKey;
  const { callAPI: addCluster, loading: addClusterLoading } = useAPI(
    ClusterApis.createCluster
  );
  const { callAPI: editCluster, loading: editClusterLoading } = useAPI(
    ClusterApis.editClusterDetails
  );
  const loading = addClusterLoading || editClusterLoading;

  const {
    callAPI: getClusterDetails,
    response: clusterDetails,
    loading: loadingClusterDetails,
  } = useAPI(ClusterApis.getClusterDetails);

  useEffect(() => {
    if (clusterID) {
      getClusterDetails(clusterID);
    }
  }, [clusterID]);

  useEffect(() => {
    if (clusterDetails?.name) {
      form.setFieldsValue({
        clusterName: clusterDetails.name,
      });
      const admins: IClusterAdminRecord[] = (clusterDetails.admins || [])
        .filter(
          (admin) => clusterManager === null || clusterManager.id !== admin.id
        )
        .map((admin) => {
          return {
            Email: admin.email ? admin.email : '',
            FName: admin.first_name,
            LName: admin.last_name,
            TempPassword: admin.temporary_password
              ? admin.temporary_password
              : '',
            IsManager: admin.role === UserRoles.CLUSTER_MANAGER ? true : false,
            key: admin.id ? admin.id : uniqueId('userID'), // uniquId would not be assigned if BE sends user ID
            type: ClusterAdminTableTypes.EXISTING,
          };
        });
      setTableData(admins);
    }
  }, [clusterDetails]);

  const onSuccess = (msg: string) => {
    closeModal();
    toast.success(msg);
  };
  const onError = ({ error }: { error: axios.AxiosError; message: string }) => {
    const data = error?.response?.data;
    const errorKey = data ? Object.keys(data)[0] : '';
    if (errorKey && Array.isArray(data[errorKey]) && data[errorKey].length) {
      data[errorKey][0] && toast.error(toSentenceCase(data[errorKey][0]));
    }
  };

  const onSubmit = ({ clusterName }: { clusterName: string }) => {
    // if cluster ID is not present then add
    if (!clusterID) {
      // add mode
      const createClusterRequest: ICreateCluster = {
        name: clusterName,
        parent: parentTreeID,
        admins: tableData.length
          ? tableData.map((ele) => {
              return {
                first_name: ele.FName,
                last_name: ele.LName,
                email: ele.Email,
                temporary_password: ele.TempPassword,
                role: ele.IsManager
                  ? UserRoles.CLUSTER_MANAGER
                  : UserRoles.CLUSTER_ADMIN,
              };
            })
          : [],
      };
      addCluster(
        createClusterRequest,
        () => onSuccess(CLUSTER_ADMIN_LABELS.clusterCreationSuccess),
        onError
      );
    } else {
      // edit mode
      const deletedUsers = [];
      const editedUsers = [];
      const addedUsers = [];
      for (const user of tableData) {
        switch (user.type) {
          case ClusterAdminTableTypes.ADD:
            addedUsers.push(user);
            break;
          case ClusterAdminTableTypes.EDIT:
            editedUsers.push(user);
            break;
          case ClusterAdminTableTypes.DELETE:
            deletedUsers.push(user);
            break;
        }
      }
      const editRequest: IEditCluster = {
        id: clusterID,
        name: clusterName,
        admins_to_add: addedUsers.map((ele) => {
          return {
            first_name: ele.FName,
            last_name: ele.LName,
            email: ele.Email,
            temporary_password: ele.TempPassword,
            role: ele.IsManager
              ? UserRoles.CLUSTER_MANAGER
              : UserRoles.CLUSTER_ADMIN,
          };
        }),
        admins_to_update: editedUsers.map((ele) => {
          return {
            id: ele.key,
            first_name: ele.FName,
            last_name: ele.LName,
            temporary_password: ele.TempPassword,
            role: ele.IsManager
              ? UserRoles.CLUSTER_MANAGER
              : UserRoles.CLUSTER_ADMIN,
          };
        }),
        admins_to_remove: deletedUsers.map((ele) => ele.key),
        schools_to_add: [],
        schools_to_remove: [],
      };
      editCluster(
        editRequest,
        () => onSuccess(CLUSTER_ADMIN_LABELS.clusterEditSuccess),
        onError
      );
    }
  };

  const cancel = (record: IClusterAdminRecord) => {
    if (record[ClusterAdminColumnsIndex.KEY] === EMPTY_ROW) {
      const newData = [...tableData];
      const index = newData.findIndex((ele) => ele.key === EMPTY_ROW);
      newData.splice(index, 1);
      setTableData(newData);
    }
    setEditingKey('');
  };

  const edit = (record: IClusterAdminRecord) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const onDelete = (record: IClusterAdminRecord) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => item.key === record.key);
    if (record.type === ClusterAdminTableTypes.ADD) {
      newData.splice(index, 1);
    } else if (
      record.type === ClusterAdminTableTypes.EDIT ||
      record.type === ClusterAdminTableTypes.EXISTING
    ) {
      newData.splice(index, 1, {
        ...record,
        type: ClusterAdminTableTypes.DELETE,
      });
    }
    setTableData(newData);
  };

  const createEmptyRow = () => {
    const index = [...tableData].findIndex((ele) => ele.key === EMPTY_ROW);
    if (index === -1) {
      const newItem: IClusterAdminRecord = {
        [ClusterAdminColumnsIndex.KEY]: EMPTY_ROW,
        [ClusterAdminColumnsIndex.FNAME]: '',
        [ClusterAdminColumnsIndex.LNAME]: '',
        [ClusterAdminColumnsIndex.EMAIL]: '',
        [ClusterAdminColumnsIndex.TEMP]: '',
        [ClusterAdminColumnsIndex.ISMANAGER]: false,
        [ClusterAdminColumnsIndex.TYPE]: ClusterAdminTableTypes.ADD,
      };
      const newData = [newItem, ...tableData];
      setTableData(newData);
      edit(newItem);
    }
  };

  // saves to memory not BE also updates the state
  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as IClusterAdminRecord;
      const newData = [...tableData];
      const index = newData.findIndex((item) => key === item.key);
      const tableRow = newData[index];
      // bcoz edit not allowed on user email id once saved
      const emailValue =
        tableRow.type === ClusterAdminTableTypes.ADD
          ? row.Email
          : tableRow.Email;
      if (
        tableRow.type === ClusterAdminTableTypes.EXISTING ||
        tableRow.type === ClusterAdminTableTypes.DELETE
      )
        tableRow.type = ClusterAdminTableTypes.EDIT;
      if (tableRow.key === EMPTY_ROW) tableRow.key = uniqueId(NEW_ADDED_ROW);
      newData.splice(index, 1, {
        [ClusterAdminColumnsIndex.KEY]: tableRow.key,
        [ClusterAdminColumnsIndex.TYPE]: tableRow.type,
        [ClusterAdminColumnsIndex.EMAIL]: emailValue,
        [ClusterAdminColumnsIndex.FNAME]: row.FName,
        [ClusterAdminColumnsIndex.LNAME]: row.LName,
        [ClusterAdminColumnsIndex.TEMP]: row.TempPassword,
        [ClusterAdminColumnsIndex.ISMANAGER]: row.IsManager,
      });
      setTableData(newData);
      setEditingKey('');
    } catch (errInfo) {}
  };

  const columns = ClusterAdminColumns({
    edit,
    editingKey,
    isEditing,
    save,
    cancel,
    onDelete,
  });
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IClusterAdminRecord) => ({
        record,
        inputType: col.inputType ? col.inputType : 'string',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const renderInputItem = ({
    dataIndex,
    title,
    inputType,
    record,
  }: EditableCellProps) => {
    const requiredRule = {
      transform: (val: string) => val?.toString().trim(),
      required: true,
      whitespace: false,
      message: `${title} ${CLUSTER_ADMIN_LABELS.isRequired}`,
    };
    return (
      <Form.Item
        className="m-0"
        name={dataIndex}
        rules={[
          dataIndex === ClusterAdminColumnsIndex.TEMP
            ? record.type === ClusterAdminTableTypes.ADD
              ? requiredRule
              : {}
            : requiredRule,
          {
            transform: (val) => val?.toString().trim(),
            type: inputType,
            message: `${title} ${CLUSTER_ADMIN_LABELS.isInvalid}`,
          },
        ]}
        key={title}
      >
        <Input
          disabled={
            dataIndex === ClusterAdminColumnsIndex.EMAIL &&
            record.type !== ClusterAdminTableTypes.ADD
              ? true
              : false
          }
        />
      </Form.Item>
    );
  };

  const renderCheckboxItem = ({
    dataIndex,
    title,
    record,
    editing,
  }: EditableCellProps) => {
    return editing ? (
      <Form.Item
        className="m-0"
        name={dataIndex}
        rules={[]}
        key={title}
        valuePropName="checked"
      >
        <Checkbox></Checkbox>
      </Form.Item>
    ) : Boolean(record.IsManager) ? (
      'Yes'
    ) : (
      '-'
    );
  };

  const EditableCell = (editableCellProps: EditableCellProps) => {
    const { editing, inputType, children, ...restProps } = editableCellProps;

    return (
      <td {...restProps}>
        {inputType === 'boolean'
          ? renderCheckboxItem(editableCellProps)
          : editing
          ? renderInputItem(editableCellProps)
          : children}
      </td>
    );
  };

  const rowBgColor = (type: ClusterAdminTableTypes) => {
    switch (type) {
      case ClusterAdminTableTypes.ADD:
        return 'add-row';
      case ClusterAdminTableTypes.EDIT:
        return 'edit-row';
      case ClusterAdminTableTypes.DELETE:
        return 'delete-row';
      case ClusterAdminTableTypes.EXISTING:
        return 'existing-row';
      default:
        return '';
    }
  };

  const _renderForm = () => (
    <Form
      validateTrigger={'onBlur'}
      onFinish={onSubmit}
      form={form}
      className="cluster-managent-form"
    >
      <Form.Item
        name="clusterName"
        label={CLUSTER_ADMIN_LABELS.clusterName}
        rules={[
          {
            required: true,
            message: `${CLUSTER_ADMIN_LABELS.clusterName} ${CLUSTER_ADMIN_LABELS.isRequired}`,
          },
        ]}
      >
        <Input disabled={Boolean(clusterManager)} />
      </Form.Item>
      <div className="cluster-admin-table-header">
        <Title level={5}>{CLUSTER_ADMIN_LABELS.clusterAdmins}</Title>
        <Button type="primary" shape="round" onClick={createEmptyRow}>
          {CLUSTER_ADMIN_LABELS.addAdmin}
        </Button>
      </div>
      <Table
        columns={mergedColumns}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={tableData}
        pagination={{
          hideOnSinglePage: true,
          position: ['bottomCenter'],
          pageSize: 10,
          showSizeChanger: false,
        }}
        rowClassName={(record: IClusterAdminRecord) => rowBgColor(record.type)}
      />
      <Divider />
      <Form.Item className="submit-btn-container">
        <Button
          shape="round"
          type="primary"
          htmlType="submit"
          block
          className="submit-btn"
          loading={loading}
          disabled={editingKey !== ''}
        >
          {CLUSTER_ADMIN_LABELS.submitBtn}
        </Button>
      </Form.Item>
    </Form>
  );

  return (
    <Modal
      width={document.documentElement.clientWidth * 0.8}
      title={
        clusterID
          ? Boolean(clusterManager)
            ? CLUSTER_ADMIN_LABELS.editClusterUser
            : CLUSTER_ADMIN_LABELS.editCluster
          : parentTreeID
          ? CLUSTER_ADMIN_LABELS.addSubCluster
          : CLUSTER_ADMIN_LABELS.addCluster
      }
      visible={true}
      onCancel={closeModal}
      footer={null}
      destroyOnClose
      maskClosable={false}
      className="cluster-modal-wrapper"
      closable={!loading}
    >
      {clusterID ? (
        // edit
        loadingClusterDetails ? (
          <div className="spin-container">
            <Spin />
          </div>
        ) : clusterDetails ? (
          _renderForm()
        ) : (
          <p>{CLUSTER_ADMIN_LABELS.error}</p>
        )
      ) : (
        // add
        _renderForm()
      )}
    </Modal>
  );
}

import React from 'react';

export default function LogoutIcon() {
  return (
    <svg width="12" height="9" viewBox="0 0 12 9">
      <path
        fillRule="nonzero"
        d="M4.219 0c.078 0 .144.027.199.082a.271.271 0 0 1 .082.2v.562a.271.271 0 0 1-.082.199.271.271 0 0 1-.2.082H2.25c-.313 0-.578.11-.797.328a1.085 1.085 0 0 0-.328.797v4.5c0 .313.11.578.328.797.219.219.484.328.797.328h1.969c.078 0 .144.027.199.082a.271.271 0 0 1 .082.2v.562a.271.271 0 0 1-.082.199.271.271 0 0 1-.2.082H2.25a2.17 2.17 0 0 1-1.594-.656A2.17 2.17 0 0 1 0 6.75v-4.5C0 1.625.219 1.094.656.656A2.17 2.17 0 0 1 2.25 0h1.969zm3.445.445V.47a.291.291 0 0 1 .211-.094c.078 0 .14.023.187.07L11.93 4.29c.047.063.07.133.07.211 0 .078-.023.14-.07.188L8.062 8.53a.223.223 0 0 1-.187.094.291.291 0 0 1-.211-.094l-.445-.445a.291.291 0 0 1-.094-.211c0-.078.031-.14.094-.187l2.648-2.579H4.031a.271.271 0 0 1-.199-.082.271.271 0 0 1-.082-.199v-.656c0-.078.027-.145.082-.2a.271.271 0 0 1 .2-.081h5.835L7.22 1.313a.223.223 0 0 1-.094-.188c0-.078.031-.148.094-.21l.445-.47z"
      />
    </svg>
  );
}

import { SimpleRecord } from '@dabapps/simple-records';

export type Status = 'VERY_LIKELY' | 'LIKELY' | 'UNLIKELY' | 'VERY_UNLIKELY';
export type AssessmentType = 'FULL' | 'LIGHT';

export interface AssessmentListItem {
  readonly id: string;
  readonly status: Status;
  readonly created: string;
  readonly assessment_type: AssessmentType;
}
export const AssessmentListItem = SimpleRecord<AssessmentListItem>({
  id: '',
  status: 'LIKELY',
  created: '',
  assessment_type: 'LIGHT',
});

import React from 'react';
import { ArrowUpOutlined,ArrowDownOutlined } from '@ant-design/icons';

import { formatDuration } from '^/common/utils/durations';

export interface Props {
  minutesChange?: number;
  increasePrefix?: string;
  increaseSuffix?: string;
  decreasePrefix?: string;
  decreaseSuffix?: string;
  showDuration?: boolean;
}

export default function TimeChange({
  minutesChange,
  increasePrefix,
  increaseSuffix,
  decreasePrefix,
  decreaseSuffix,
  showDuration,
}: Props) {
  if (!minutesChange) {
    return null;
  }
  const className = 'stats-change';
  if (minutesChange > 0) {
    return (
      <div className={className}>
        <span className="stats-change-duration">
          {increasePrefix && `${increasePrefix} `}
          {showDuration && formatDuration(minutesChange)}
          {increaseSuffix && ` ${increaseSuffix}`}{' '}
        </span>
        <ArrowUpOutlined className="icon" />
      </div>
    );
  }
  return (
    <div className={className}>
      <span className="stats-change-duration">
        {decreasePrefix && `${decreasePrefix} `}
        {showDuration && formatDuration(-minutesChange)}
        {decreaseSuffix && ` ${decreaseSuffix}`}{' '}
      </span>
      <ArrowDownOutlined className="icon" />
    </div>
  );
}

import React from 'react';
import { isNumber, isUndefined } from 'underscore';
import { Tooltip } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

import {
  ActivityHighlightsProps,
  ActivitySummaryHighlightProps,
} from '../types';
import {
  DashBoardHighlightKeys,
  IActivityHighlightDetails,
} from '^/api-services/dashboard/types.ts';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';
import {
  ACTIVITY_HIGHLIGHT_GREEN,
  ACTIVITY_HIGHLIGHT_RED,
} from '^/common/colors';
import { toReadableNumber } from '^/common/utils/text-utils';

export const ActivityHighlights: React.FC<
  ActivityHighlightsProps | ActivitySummaryHighlightProps
> = ({
  data,
  small,
}: ActivityHighlightsProps | ActivitySummaryHighlightProps) => {
  return (
    <div className="activity-highlights">
      {Object.keys(data).map((ele, index) => (
        <ActivityHeaderCard
          small={small}
          {...(data as never)[ele]}
          name={ele}
          key={`dashboard-highlight-card-${index}`}
        />
      ))}
    </div>
  );
};

const ActivityHeaderCard = ({
  name,
  count,
  percentage_change,
  small,
}: IActivityHeaderCardProps) => {
  const total_students = 'total_students';

  const cardBorderStyle = () => {
    if (name === total_students || !isNumber(percentage_change)) {
      return {};
    }
    return percentage_change > 0
      ? { borderColor: ACTIVITY_HIGHLIGHT_GREEN }
      : { borderColor: ACTIVITY_HIGHLIGHT_RED };
  };

  const percentageColorStyle = () => {
    return isNumber(percentage_change) && percentage_change > 0
      ? { color: ACTIVITY_HIGHLIGHT_GREEN }
      : { color: ACTIVITY_HIGHLIGHT_RED };
  };

  const isQuarantined = isUndefined(count);

  const generateDescription = () => {
    const val: string = isQuarantined
      ? DASHBOARD_PAGE_LABELS.quarantineDescription
      : DASHBOARD_PAGE_LABELS[`highlights_${name}_description`];
    let days = 0;
    switch (name) {
      case DashBoardHighlightKeys.IU:
        days = 14;
        break;
      case DashBoardHighlightKeys.MAU:
        days = 30;
        break;
      case DashBoardHighlightKeys.WAU:
        days = 7;
        break;
    }
    if (days)
      return (
        <span>
          {val + ' '}
          <strong className="highlight">
            {isQuarantined ? days * 2 : days} {DASHBOARD_PAGE_LABELS.days}
          </strong>
          {isQuarantined && DASHBOARD_PAGE_LABELS.quarantineDescriptionEnd}
        </span>
      );
    return <>{val}</>;
  };

  const generatePercentTooltipTitle = () => {
    if (isNumber(percentage_change)) {
      return `${
        (DASHBOARD_PAGE_LABELS as never)[`highlights_${name}_tooltip`]
      }`;
    }
  };

  return (
    <div className="activity-header-card" style={cardBorderStyle()}>
      <div
        className={`highlight-column ${
          isQuarantined ? ' quarantined-col-1' : 'col-1'
        }`}
      >
        {isQuarantined ? (
          <InfoCircleFilled />
        ) : (
          <>
            <span className="title">
              {DASHBOARD_PAGE_LABELS[`highlights_${name}`]}
            </span>
            <span className="description">{generateDescription()}</span>
          </>
        )}
      </div>
      {isQuarantined ? (
        <div className="highlight-column quarantined-col-2">
          <span className="title">
            {DASHBOARD_PAGE_LABELS[`highlights_${name}`]}
          </span>
          <span className="description">{generateDescription()}</span>
        </div>
      ) : (
        <div className="highlight-column align-items-end col-2">
          <span className={small ? 'count-small' : 'count'}>
            {/* {isNumber(count) ? toReadableNumber(count) : 'Quarantined'} */}
            {isNumber(count) ? toReadableNumber(count) : count}
          </span>
          {isNumber(percentage_change) && (
            <Tooltip
              placement="bottomRight"
              title={generatePercentTooltipTitle()}
            >
              <span
                className="percent"
                style={percentageColorStyle()}
              >{`${+percentage_change.toFixed(2)}%`}</span>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

interface IActivityHeaderCardProps extends IActivityHighlightDetails {
  name: string;
  small?: boolean;
}

import { SimpleRecord } from '@dabapps/simple-records';
import { Status } from '^/app/authenticated/assessments/types';
import { Activity } from '^/common/constants';
import { Feeling } from '../constants';

export interface ActivityPageLastAssessment {
  readonly id: string;
  readonly status: Status;
  readonly created: string;
}
export const ActivityPageLastAssessment = SimpleRecord<
  ActivityPageLastAssessment
>({
  id: '',
  status: 'LIKELY',
  created: '',
});

export interface ActivityPageUserDetail {
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly number_of_activities: number;
  readonly number_of_assessments: number;
  readonly number_of_messages: number;
  readonly latest_assessment: ActivityPageLastAssessment;
}
export const ActivityPageUserDetail = SimpleRecord<ActivityPageUserDetail>({
  id: '',
  first_name: '',
  last_name: '',
  number_of_activities: 0,
  number_of_assessments: 0,
  number_of_messages: 0,
  latest_assessment: ActivityPageLastAssessment({}),
});

export interface ActivityData {
  readonly no_of_activity_sessions: number;
  readonly activities_by_date_time: number;
  readonly most_frequent_activity_by_date: Activity | null;
  readonly most_frequent_category_by_date: string | null;
  readonly most_active_day: string;
  readonly most_frequent_emotion: Feeling | null;
  readonly most_frequent_setting: Location | null;
  readonly all_activites_count: number;
  readonly all_activities_time: number;
}

export interface RecordsData {
  readonly best_activities: any;
  readonly best_minutes: any;
  readonly social: any;
  readonly streaks: any;
}

export interface ActivityPageActivitiesSummary {
  readonly current_week: ActivityData | null;
  readonly last_week: ActivityData | null;
  readonly wallet: any;
  readonly records: RecordsData | null;
}
export const ActivityPageActivitiesSummary = SimpleRecord<
  ActivityPageActivitiesSummary
>({
  current_week: null,
  last_week: null,
  wallet: null,
  records: null,
});

import { AssessmentType, Status } from '^/app/authenticated/assessments/types';

interface AssessmentStatusDetails {
  displayName: string;
  notes: string;
  colour: string;
}

export const ASSESSMENT_STATUS_DETAILS: {
  [K in Status]: AssessmentStatusDetails
} = {
  VERY_LIKELY: {
    displayName: 'Very Likely',
    notes:
      'Very regular participation on an ongoing and sustained basis both in and beyond lesson time and both in and out of school. Stays activeduring times of high pressure. Physical activity plays a central role in their lifestyle. Personal organisation supports this regular participation and is autonomous relative to age and stage.',
    colour: 'green',
  },
  LIKELY: {
    displayName: 'Likely',
    notes:
      'Regular participation on an ongoing and sustained basis both in and beyond lesson time but mainly in school. Personal organisation supports this regular participation. Adult support is required to be fully organised.',
    colour: 'yellow',
  },
  UNLIKELY: {
    displayName: 'Unlikely',
    notes:
      'Regular participation both in and beyond lesson time in school, if and when convenient. Much more likely to take part if friends attend but less likely if friends do not attend. May not always be sufficiently organised. Unlikely to participate in physical activity when busy or under increased pressure.',
    colour: 'orange',
  },
  VERY_UNLIKELY: {
    displayName: 'Very Unlikely',
    notes:
      'Takes part in physical activity when s/he perceives that there is no option. Would look to avoid participating if safe and convenient to do so. May well be exhibiting high risk health behaviours.',
    colour: 'red',
  },
};

export const ASSESSMENT_STATUSES = Object.keys(
  ASSESSMENT_STATUS_DETAILS
) as ReadonlyArray<Status>;

export const ASSESSMENT_TYPES: { [K in AssessmentType]: AssessmentType } = {
  FULL: 'FULL',
  LIGHT: 'LIGHT',
};

export const ASSESSMENT_TYPE_DISPLAY: { [K in AssessmentType]: string } = {
  FULL: 'Full',
  LIGHT: 'Light',
};

export const NUMBER_OF_FULL_ASSESSMENT_PAGES = 3;

export type FullAssessmentMovementNotes = ReadonlyArray<string>;

export interface FullAssessmentThinkingNotes {
  heading: string;
  notes: string;
}

export interface FullAssessmentAttitudeNotes {
  TO_SELF: ReadonlyArray<string>;
  TO_OTHERS: ReadonlyArray<string>;
  TO_ACTIVITY: ReadonlyArray<string>;
}

export type FullAssessmentNotesType =
  | FullAssessmentMovementNotes
  | FullAssessmentThinkingNotes
  | FullAssessmentAttitudeNotes;

export interface FullAssessmentNotes<T> {
  VERY_LIKELY: T;
  LIKELY: T;
  UNLIKELY: T;
  VERY_UNLIKELY: T;
}

export const FULL_ASSESSMENT_MOVEMENT_INTRO =
  "In this domain you are asked to think about the pupil's movement. The statements below relate to fundamental movement skills (FMS), activity specific movement, and the way the pupil is able to meet the fitness and technical demands of the activity environment.";

export const FULL_ASSESSMENT_MOVEMENT_NOTES: FullAssessmentNotes<
  FullAssessmentMovementNotes
> = {
  VERY_LIKELY: [
    'High level FMS relative to age and stage.',
    'Is able to move with fluency and control in a wide range of movement environments.',
    'Is able to meet the fitness demands of a range of activities so these do not present any barrier to participation at the desired level.',
    'Is able to meet the technical demands of a range of activities so these do not present any barrier to participation at the desired level.',
  ],
  LIKELY: [
    'Good level FMS relative to age and stage.',
    'Is able to move with reasonable fluency and control in some movement environments.',
    'Is able to meet the fitness demands of some activities so these present only a slight barrier to participation at the desired level.',
    'Is able to meet the technical demands of some activities so these present only a minimal barrier to participation at the desired level.',
  ],
  UNLIKELY: [
    'Emerging FMS relative to age and stage.',
    'Is able to move with emerging fluency and control in a limited number of movement environments.',
    'Is able to meet the fitness demands of low intensity activities.',
    'Is able to meet the technical demands of less technically demanding activities.',
  ],
  VERY_UNLIKELY: [
    'Weak FMS relative to age and stage.',
    'Many movements lack fluency and control.',
    'Fitness demands of physical activity present a barrier to participation.',
    'Technical demands of physical activity present a barrier to participation.',
  ],
};

export const FULL_ASSESSMENT_THINKING_INTRO =
  "In this domain you should consider the pupil's thinking ability in relation to physical activity. This should be evidenced in and through the activity not away from it.";

export const FULL_ASSESSMENT_THINKING_NOTES: FullAssessmentNotes<
  FullAssessmentThinkingNotes
> = {
  VERY_LIKELY: {
    heading: 'Create, Evaluate, Synthesise, Analyse + Likely attributes',
    notes:
      'Is able to apply a full range of higher order thinking strategies (Analyse, Evaluate, Create) to a wide range of activity areas. This is evident before, during and after activity –‘choose-do-review’. Exceptionally high level of ‘situation literacy’ allowsfor deep understanding of the essence of the activity relative to age and stage. Thinking ability enables fully autonomous and informed participation.',
  },
  LIKELY: {
    heading: 'Application + Unlikely attributes',
    notes:
      'Is able to apply mid-level and some higher order thinking strategies to some activity areas with analysis stronger than evaluation and creativity. Thinking tends to be more evident before and/or after activity rather than ‘in situ’. Good understanding of key concepts of activity relative to age and stage.Thinking ability enables partially autonomous participation.',
  },
  UNLIKELY: {
    heading: 'Understand, Knowledge, Remember',
    notes:
      'Thinking is predominantly shaped by recall. Thinking tends to be more evident after activity with guided support rather than at the planning stage or ‘in situ’. Some understanding of key concepts of activity relative to age and stage.Thinking ability enables supported participation.',
  },
  VERY_UNLIKELY: {
    heading: 'Understand, Knowledge, Remember',
    notes:
      'Limited evidence of thinking in a movement context.  As a result, skill acquisition is severely hindered and key concepts are not sufficiently understood to make sense of activity.  High level of support needed to ensure any participation.',
  },
};

export const FULL_ASSESSMENT_ATTITUDE_INTRO =
  "The statements below relate to the pupil's attitude to themselves in an active environment, to others and their attitude to activity. As always a best fit approach.";

export const FULL_ASSESSMENT_ATTITUDE_NOTES: FullAssessmentNotes<
  FullAssessmentAttitudeNotes
> = {
  VERY_LIKELY: {
    TO_SELF: [
      'Strives to excel at all times',
      'Maintains a sense of humility at all times',
      'Never gives up when faced with difficulty/challenges',
      'Feels most satisfied when trying their hardest',
      'Feels very comfortable in their physical self in any movement environment',
    ],
    TO_OTHERS: [
      'Makes people around him/her feel great about themselves',
      'Able to motivate peers to exceed their expectations',
      'Thoroughly enjoys taking part in activity with and against others',
      'Feels a deep sense of joy when others succeed',
    ],
    TO_ACTIVITY: [
      'Enthuses about all activities',
      'Intrinsically motivated to do well in all activities',
      'Always wants to exceed own expectations and targets',
      'Feels completely comfortable in a movement environment.',
    ],
  },
  LIKELY: {
    TO_SELF: [
      'Wants to do well in most contexts',
      'Sense of success may well come from performing ‘better’ than others and therefore gaining status',
      'Will generally try hard in most contexts',
      'Cares about their progress',
      'Feels very comfortable in their physical self in some movement environments',
    ],
    TO_OTHERS: [
      'Is able to function effectively in a team but will generally not impact on the emotional state of the people around him/her ',
      'Able to support friends in making progress',
      'Enjoys taking part in most activities with and against others ',
      'Compliments others when they succeed',
    ],
    TO_ACTIVITY: [
      'Enthuses about some activities (e.g. Invasion gems)',
      'Often motivated to do well in preferred activities',
      'Wants to achieve success as measured by outcome ',
      'Feels comfortable in most movement environments.',
    ],
  },
  UNLIKELY: {
    TO_SELF: [
      'Wants to do well in some contexts',
      'Sense of success and worth generally comes from performing ‘better’ than others and may well use excuses to explain why a successful outcome was not achieved',
      'Will try hard sometimes',
      'Care about their progress at times',
      'Feels comfortable in their physical self in a limited number movement environments ',
    ],
    TO_OTHERS: [
      'Has a clear preference for working either competitively or co-operatively with others',
      'Is able to function effectively in a team if their wishes and preferences are accommodated but will generally not impact on the emotional state of the people around him/her ',
      'Focus is on own performance',
      'May feel threatened by the success of others ',
      'OR not bothered by the success of others',
    ],
    TO_ACTIVITY: [
      'Engages best when grouped with friends',
      'Enthuses about a limited number of activities (1 or 2)',
      'Much prefers familiar learning activities',
      'Often motivated by being seen to do well compared to others',
      'Feels comfortable in specific environments, usually preferring indoor activity',
    ],
  },
  VERY_UNLIKELY: {
    TO_SELF: [
      'Has a sense of only being able to do well in very specific contexts or none at all',
      'Rarely experiences a sense of success or satisfaction',
      'Gives the impression of seeing no point in trying hard',
      'Rarely appears to care about their progress',
      'Feels uncomfortable in their physical self in most movement environments',
    ],
    TO_OTHERS: [
      'Feels insecure in a group and finds it difficult to engage in a group activity',
      'Success of others may add to insecurity and a sense of ‘I can’t do’',
      'Success of others is seen as irrelevant',
    ],
    TO_ACTIVITY: [
      "Has a sense of dis-belonging -'it's not for me'",
      'More secure when grouped with a small number of people',
      "Sometimes reluctant to engage and may look for ways to disengage ('sit out')",
      'Considers the subject futile',
      'OR Associates the movement environment with unpleasantness',
    ],
  },
};

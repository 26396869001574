import { GraphUserListUser } from '^/app/pages/activity-dashboard/graphs/types';
import * as axios from 'axios';
export interface IDashboardGraphDataRequest {
  school: string;
  className: string;
  otherGroups: string;
  year: string;
  grade?: string;
  SEND: string;
  gender: string;
  ethnicity: string;
  start_date: string;
  end_date: string;
  pupil_premium: string;
  eal: string;
  // to cancel api
  cancelToken?: axios.CancelTokenSource;
}

export interface IDashboardGraphDataResponse {
  class_wise_breakdown: IGradeWiseBreakdown[];
  year_wise_breakdown: IGradeWiseBreakdown[];
  activity_locations: IActivityLocations;
  emotional_response: IEmotionData[];
  trends: ITrendsData[];
}

export interface IGraphDetailsRequest {
  chartType: string;
  chartValue: string;
  school: string;
  className: string;
  otherGroups: string;
  year: string;
  grade?: string;
  SEND: string;
  gender: string;
  ethnicity: string;
  start_date: string;
  end_date: string;
  pupil_premium: string;
  eal: string;
  // to cancel api
  cancelToken?: axios.CancelTokenSource;
  pageNumber: number;
  pageSize?: number;
  search?: string;
}

export interface IGraphDetailsResponse<T = GraphUserListUser> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export interface IDashboardSummaryDataResponse {
  summary_metrics: ISummaryHistograms;
}

export interface IDashboardUIHistogram {
  sessions: number;
  duration: number;
  name?: string;
}
export interface IDashboardHistogramMap {
  [key: string]: IDashboardUIHistogram;
}

export interface ISummaryHistograms {
  activity?: IDashboardHistogramMap;
  class_group?: IDashboardHistogramMap;
  ethnicity?: IDashboardHistogramMap;
  feeling?: IDashboardHistogramMap;
  gender?: IDashboardHistogramMap;
  is_pupil_premium?: IDashboardHistogramMap;
  is_send_registered?: IDashboardHistogramMap;
  location?: IDashboardHistogramMap;
  week_day?: IDashboardHistogramMap;
  year_group?: IDashboardHistogramMap;
  summary_average_active_sessions: number;
  summary_average_active_time: number;
  summary_total_active_sessions: number;
  summary_total_students: number;
  summary_total_active_time: number;
  summary_unique_out_of_school_students: number;
}

export interface IGradeWiseBreakdown {
  grade: string;
  active_students_count: number;
  inactive_students_count: number;
}

export interface IActivityLocations {
  INSIDE_SCHOOL_PE: number;
  INSIDE_SCHOOL_CLUB: number;
  INSIDE_SCHOOL_BREAK: number;
  OUTSIDE_SCHOOL_CLUB: number;
  OUTSIDE_SCHOOL_FREE_PLAY: number;
  OUTSIDE_SCHOOL_HOLIDAY_CAMP: number;
}

export interface IEmotionData {
  GOOD: number;
  GREAT: number;
  OK: number;
  NOT_GOOD: number;
  HORRIBLE: number;
  name: string;
}

export interface IActivityFrequency {
  sessions: number;
  mins: number;
  name: string;
}

export interface ITrendsData {
  start_date: string;
  end_date: string;
  average_minutes_spent: number;
  active_students_count: number;
  inactive_students_count: number;
  name: string;
}

export interface IBlobExport {
  headers: unknown;
  data: any;
}
export interface IDashboardHeaderResponse {
  [DashBoardHighlightKeys.TOTAL_STUDENTS]: IActivityHighlightDetails;
  [DashBoardHighlightKeys.WAU]: IActivityHighlightDetails;
  [DashBoardHighlightKeys.MAU]: IActivityHighlightDetails;
  [DashBoardHighlightKeys.IU]: IActivityHighlightDetails;
}
export interface IActivityHighlightDetails {
  count: number;
  percentage_change?: number;
  start_date?: string;
  end_date?: string;
}

export interface IGlobalDashboardSchool {
  id: string;
  wau: number;
  mau: number;
  noactivitieslogged: number;
  neverloggedin: number;
  user_count: number;
  name: string;
}

export interface IGlobalDashboardHeaderResponse {
  [DashBoardHighlightKeys.GLOBAL_TOTAL_STUDENTS]: number;
  [DashBoardHighlightKeys.TOTAL_WAU]: number;
  [DashBoardHighlightKeys.TOTAL_MAU]: number;
  [DashBoardHighlightKeys.TOTAL_NOACTIVITES]: number;
  [DashBoardHighlightKeys.TOTAL_NOLOGIN]: number;
}

export enum DashBoardHighlightKeys {
  TOTAL_STUDENTS = 'total_students',
  WAU = 'weekly_active_students',
  MAU = 'monthly_active_students',
  IU = 'inactive_students',
  GLOBAL_TOTAL_STUDENTS = 'total_user_count',
  TOTAL_WAU = 'total_wau',
  TOTAL_MAU = 'total_mau',
  TOTAL_NOACTIVITES = 'total_noactivitieslogged',
  TOTAL_NOLOGIN = 'total_neverlogged',
  SUMMARY_TOTAL_STUDENTS = 'summary_total_students',
  SUMMARY_MOST_ACTIVE_ACTIVITY = 'summary_most_active_activity',
  SUMMARY_MOST_ACTIVE_DAY = 'summary_most_active_day',
  SUMMARY_MOST_ACTIVE_TIME = 'summary_most_active_time',
  SUMMARY_TOTAL_ACTIVE_TIME = 'summary_total_active_time',
  SUMMARY_TOTAL_ACTIVE_SESSIONS = 'summary_total_active_sessions',
  SUMMARY_AVERAGE_ACTIVE_TIME = 'summary_average_active_time',
  SUMMARY_AVERAGE_ACTIVE_SESSIONS = 'summary_average_active_sessions',
  SUMMARY_MOST_COMMON_EMOTION = 'summary_most_common_emotion',
  SUMMARY_MOST_COMMON_PLACE = 'summary_most_common_place',
  SUMMARY_OUT_OF_SCHOOL_STUDENTS = 'summary_unique_out_of_school_students',
}

export interface ISummaryDashboardHeaderResponse {
  [DashBoardHighlightKeys.SUMMARY_TOTAL_STUDENTS]: number;
  [DashBoardHighlightKeys.SUMMARY_TOTAL_ACTIVE_TIME]: number;
  [DashBoardHighlightKeys.SUMMARY_TOTAL_ACTIVE_SESSIONS]: number;
  [DashBoardHighlightKeys.SUMMARY_AVERAGE_ACTIVE_TIME]: number;
  [DashBoardHighlightKeys.SUMMARY_AVERAGE_ACTIVE_SESSIONS]: number;
  [DashBoardHighlightKeys.SUMMARY_MOST_ACTIVE_ACTIVITY]: string;
  [DashBoardHighlightKeys.SUMMARY_MOST_ACTIVE_DAY]: string;
  [DashBoardHighlightKeys.SUMMARY_MOST_ACTIVE_TIME]: string;
  [DashBoardHighlightKeys.SUMMARY_MOST_COMMON_EMOTION]: string;
  [DashBoardHighlightKeys.SUMMARY_MOST_COMMON_PLACE]: string;
  [DashBoardHighlightKeys.SUMMARY_OUT_OF_SCHOOL_STUDENTS]: number;
}

import axios from '^/api-services/axiosInstance';

import { ServiceType } from './serviceTypes';
import { QueryPath } from './queryPath';
import { HttpContentType } from './httpContentType';
import AxiosInstance from './axiosInstance';
import { AppSettings } from './settings';
import { apiErrorMessages } from './apiErrorMessages';
import Cookies from 'js-cookie';

export interface ParsedError {
  message: string;
  result?: any;
  error?: any;
}

/**
 * We need this Base class just to separate all http methods from core helper functions.
 * If we use mock API in future all this logic can be reused.
 */
export default abstract class ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly hideFeedback: boolean;

  constructor(serviceType: ServiceType, hideFeedback?: boolean) {
    this.serviceType = serviceType;
    this.hideFeedback = !!hideFeedback;
  }

  public abstract get<T = void>(
    path: QueryPath,
    isBlob?: boolean,
    cancelToken?: axios.CancelTokenSource
  ): Promise<T> | T;
  public abstract post<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract patch<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract put<T = void>(path: QueryPath, body: any): Promise<T> | T;
  public abstract delete<T = void>(path: QueryPath): Promise<T> | T;
  public abstract postMultipart<T = void>(
    path: QueryPath,
    data: any
  ): Promise<T> | T;

  public provideErrorFeedback(content: string) {
    // TODO: Add an alert or notification and if possible show the component name
    console.log('error feedback', content);
  }

  // tslint:disable-next-line: cyclomatic-complexity
  public processError(error: any): ParsedError {
    // used when we cancel api call using axios inbuilt feature
    if (axios.default.isCancel(error)) {
      return error;
    }
    const errorCode = error.response ? error.response.status || 500 : 500;
    switch (errorCode) {
      case 401:
        // TODO: Clear the user details. call logout
        this.provideErrorFeedback(apiErrorMessages.sessionExpired);
        return { message: 'Unauthorized', error };
      case 404:
        this.provideErrorFeedback(apiErrorMessages.error);
        return { message: 'The request is not found', error };
      case 500:
        this.provideErrorFeedback(apiErrorMessages.error);
        return { message: 'Internal server error', error };
      case 400:
      case 403:
      case 412:
      case 422:
      case 423:
      case 424: {
        const err = error.response.data;
        // TODO verify the error message format sent from BE
        if (err) {
          if (err.message && err.message.isJson) {
            //if "isJson" flag is set, returning the error object, stringified
            delete err.message.isJson;
            return new Error(JSON.stringify(err.message, null, 4));
          } else if (err.message) {
            //showing feedback to user about the error if message is string
            this.provideErrorFeedback(
              typeof err.message === 'string'
                ? err.message
                : apiErrorMessages.error
            );
            //Just to be safe, prevent runtime error if it is not string.
            return {
              message:
                typeof err.message === 'string'
                  ? err.message
                  : JSON.stringify(err.message),
              result: err.result || '',
              error,
            };
          }
        }
        return { message: 'Error', error };
      }
    }
    return error;
  }

  //It returns Headers to make API call
  protected getConfig(
    contentType: HttpContentType,
    cancelToken?: axios.CancelToken
  ): axios.AxiosRequestConfig {
    // TODO: populate authtoken; Currently not used in webapp.
    // const token = '';
    const headers = {
      'Content-Type': contentType.toString(),
      // Authorization: token && `Token ${token}`,
      'X-CSRFToken': Cookies.get('csrftoken'),
    };
    if (cancelToken) {
      return { headers, cancelToken };
    }
    return { headers };
  }

  //Returns an axios instance
  protected getAxiosInstance(): axios.AxiosInstance {
    const instance = AxiosInstance.default.create();
    return instance;
  }

  // Generates url: {AppSettings.service.baseUrl}/{this.serviceType}/{routeParam1}/{routeParam2}/.../{routeParamN}?{queryParam1key}={queryParam1val}&{queryParam2key}={queryParam2val}...
  // Need this to be able to write the mocks properly. Don't want to parse urls.
  // Query params with null, undefined or empty string won't be appended to the url.

  protected getUrl(path: QueryPath): string {
    const baseUrl = AppSettings.server.baseUrl;
    let url = `${baseUrl}/api/${this.serviceType}`;

    if (path) {
      if (path.route && path.route.length > 0) {
        for (const route of path.route) {
          if (route && route !== 'undefined') {
            url += `/${route}`;
          }
        }
      }
      url += '/'; // adding slash before query param begins
      if (path.query) {
        let separator = '?';
        for (const name in path.query) {
          if (path.query[name]) {
            url += `${separator}${encodeURI(name)}=${encodeURIComponent(
              path.query[name]!.toString()
            )}`;
            separator = '&';
          }
        }
      }
    }
    return url;
  }
}

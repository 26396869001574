import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { StoreState } from '^/store/types';
import { LoggedInUser } from './authentication/types';

interface DispatchProps {
  loggedInUser: LoggedInUser | null;
}

type Props = DispatchProps;

function NotFoundPage(props: Props) {
  const { loggedInUser } = props;

  return (
    <div className="text-align-center">
      <h1 className="primary font-weight-bold">404</h1>
      <p>Looks like this page doesn't exist</p>
      {loggedInUser ? (
        <Link to="/">Return to home page</Link>
      ) : (
        <Link to="/">Return to login page</Link>
      )}
    </div>
  );
}

function mapStateToProps({ loggedInUser }: StoreState) {
  return {
    loggedInUser,
  };
}

export default connect(mapStateToProps)(NotFoundPage);

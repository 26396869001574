import { Column, Row } from '@dabapps/roe';
import * as React from 'react';
import { SearchSelectOption } from './types';

interface SelectedOptionProps {
  option: SearchSelectOption;
  removeOption: (optionIdToRemove: string) => void;
}

export default class SelectedOption extends React.PureComponent<
  SelectedOptionProps
> {
  public render() {
    const { option } = this.props;

    return (
      <li className="selected-item">
        <Row>
          <Column md={9} sm={9}>
            {option.label}
          </Column>
          <Column md={3} sm={3} className="text-align-right">
            <a onClick={this.removeOption}>Remove</a>
          </Column>
        </Row>
      </li>
    );
  }

  private removeOption = () => {
    this.props.removeOption(this.props.option.value);
  };
}

export const MOMENT_DATE_FORMAT = 'Do MMM YYYY'; // 4th Feb 2019
export const MONTH_FORMAT = 'MMM'; // FEB
export const MONTH_YEAR_FORMAT = 'MMMM YYYY'; // January 2019
export const CURRENT_SCHOOL_COOKIE_KEY = 'school_switcher__current_school';
export const INPUT_DEBOUNCE_TIME = 500; // in milliseconds
export const CLICK_DEBOUNCE_TIME = 750; // in milliseconds
export const SEARCH_FIELD_MAXLENGTH = 50;
export const GRAPH_AXIS_LABEL_MAXLENGTH = 12;
export const DATE_RANGE_FORMAT = 'DD/MM/YYYY';
export const ISO_FORMAT = 'YYYY-MM-DD'; // 2020-09-13
export const DATE_FORMAT_FOR_FILENAME = 'YYYY-MM-DD_HH-mm-ss';
export const TRENDS_TOOLTIP_LABEL_FORMAT = 'MMM Do, YYYY';
export const GRAPH_TOOLTIP_COLOR = 'rgba(206, 206, 206, 0.2)';
export const CANCEL_API_MSG = 'Operation canceled due to new request.';
export const PAGE_SIZE = 10;
export const GOOGLE_CLIENT_ID =
  '1030928921043-hfik73sohinq8pv3b4ia2jrfiekn2964.apps.googleusercontent.com';
export const MICROSOFT_CLIENT_ID = '7a01d24b-ed67-484b-8578-6e3405189989';

export type Activity =
  | 'ACTIVE_PLAY'
  | 'ACTIVE_TRAVEL'
  | 'AMERICAN_FOOTBALL'
  | 'BADMINTON'
  | 'BALLET'
  | 'BASEBALL'
  | 'BASKETBALL'
  | 'BMX'
  | 'BOXING'
  | 'CHEERLEADING'
  | 'CLIMBING'
  | 'CRICKET'
  | 'CYCLING'
  | 'DIVING'
  | 'EQUESTRIAN'
  | 'EXERCISE_CLASS'
  | 'FENCING'
  | 'FITNESS_TRAINING'
  | 'FOOTBALL'
  | 'GO_KARTING'
  | 'GOLF'
  | 'GYMNASTICS'
  | 'HANDBALL'
  | 'HIKING'
  | 'HOCKEY'
  | 'HURLING'
  | 'ICE_SKATING'
  | 'JUDO'
  | 'KARATE'
  | 'KAYAKING'
  | 'KINBALL'
  | 'LACROSSE'
  | 'LAWN_BOWLS'
  | 'NETBALL'
  | 'NITRO_BALL'
  | 'OTHER_DANCE'
  | 'OTHER_MARTIAL_ART'
  | 'PADDLE_BOARDING'
  | 'PARKOUR'
  | 'PHYSICALLY_ACTIVE_LEARNING'
  | 'PICKLE_BALL'
  | 'ROLLER_OR_INLINE_SKATING'
  | 'ROUNDERS'
  | 'ROWING'
  | 'ROWING_INDOORS'
  | 'RUGBY'
  | 'RUNNING'
  | 'RUNNING_TREADMILL'
  | 'SAILING'
  | 'SKATEBOARDING'
  | 'SKIING'
  | 'SNOOKER'
  | 'SNOWBOARDING'
  | 'SOFTBALL'
  | 'SPEED_SKATING'
  | 'SPIKE_BALL'
  | 'SQUASH'
  | 'STREET_DANCE'
  | 'SURFING'
  | 'SWIMMING'
  | 'TABLE_TENNIS'
  | 'TEN_PIN_BOWLING'
  | 'TENNIS'
  | 'TRACK_AND_FIELD_ATHLETICS'
  | 'TRAMPOLINING'
  | 'VOLLEYBALL'
  | 'WALKING_HIKING'
  | 'WALKING'
  | 'WATER_POLO'
  | 'WIND_SURFING'
  | 'WRESTLING'
  | 'YOGA'
  | 'OTHER';

export const ACTIVITY_NAMES: Readonly<
  { [ActivityType in Activity]: string }
> = {
  ACTIVE_PLAY:
    'Active play (skipping, tag games, climbing frame and slide etc)',
  ACTIVE_TRAVEL: 'Active travel',
  AMERICAN_FOOTBALL: 'American Football',
  BADMINTON: 'Badminton',
  BALLET: 'Ballet',
  BASEBALL: 'Baseball',
  BASKETBALL: 'Basketball',
  BMX: 'BMX',
  BOXING: 'Boxing',
  CHEERLEADING: 'Cheerleading',
  CLIMBING: 'Climbing',
  CRICKET: 'Cricket',
  CYCLING: 'Cycling',
  DIVING: 'Diving',
  EQUESTRIAN: 'Equestrian',
  EXERCISE_CLASS: 'Exercise class',
  FENCING: 'Fencing',
  FITNESS_TRAINING: 'Fitness training',
  FOOTBALL: 'Football',
  GO_KARTING: 'Go Karting',
  GOLF: 'Golf',
  GYMNASTICS: 'Gymnastics',
  HANDBALL: 'Handball',
  HIKING: 'Hiking',
  HOCKEY: 'Hockey',
  HURLING: 'Hurling',
  ICE_SKATING: 'Ice Skating',
  JUDO: 'Judo',
  KARATE: 'Karate',
  KAYAKING: 'Kayaking',
  KINBALL: 'Kinball',
  LACROSSE: 'Lacrosse',
  LAWN_BOWLS: 'Lawn Bowls',
  NETBALL: 'Netball',
  NITRO_BALL: 'Nitro Ball',
  OTHER_DANCE: 'Other Dance',
  OTHER_MARTIAL_ART: 'Other martial art',
  PADDLE_BOARDING: 'Paddle Boarding',
  PARKOUR: 'Parkour',
  PHYSICALLY_ACTIVE_LEARNING: 'Physically Active Learning',
  PICKLE_BALL: 'Pickleball',
  ROLLER_OR_INLINE_SKATING: 'Roller or inline skating',
  ROUNDERS: 'Rounders',
  ROWING: 'Rowing Outdoors',
  ROWING_INDOORS: 'Rowing Indoors',
  RUGBY: 'Rugby',
  RUNNING: 'Running',
  RUNNING_TREADMILL: 'Running Treadmill',
  SAILING: 'Sailing',
  SKATEBOARDING: 'Skateboarding',
  SKIING: 'Skiing',
  SNOOKER: 'Snooker',
  SNOWBOARDING: 'Snowboarding',
  SOFTBALL: 'Softball',
  SPEED_SKATING: 'Speed Skating',
  SPIKE_BALL: 'Spike Ball',
  SQUASH: 'Squash',
  STREET_DANCE: 'Street Dance',
  SURFING: 'Surfing',
  SWIMMING: 'Swimming',
  TABLE_TENNIS: 'Table Tennis',
  TEN_PIN_BOWLING: 'Ten Pin Bowling',
  TENNIS: 'Tennis',
  TRACK_AND_FIELD_ATHLETICS: 'Track and Field Athletics',
  TRAMPOLINING: 'Trampolining',
  VOLLEYBALL: 'Volleyball',
  WALKING_HIKING: 'Walking/Hiking',
  WALKING: 'Walking',
  WATER_POLO: 'Water polo',
  WIND_SURFING: 'Wind Surfing',
  WRESTLING: 'Wrestling',
  YOGA: 'Yoga',
  OTHER: 'Other',
};

export const msalConfig = {
  auth: {
    clientId: MICROSOFT_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/common', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
  prompt: 'select_account',
};

import { Button, Column, FormGroup, ModalFooter, Row } from '@dabapps/roe';
import React from 'react';
import { Field } from 'redux-form';

import { FullAssessmentPageConfig } from '^/app/authenticated/assessments/config';
import {
  ASSESSMENT_STATUSES,
  FullAssessmentNotesType,
  NUMBER_OF_FULL_ASSESSMENT_PAGES,
} from '^/app/authenticated/assessments/constants';
import { Status } from '^/app/authenticated/assessments/types';

interface Props {
  config: FullAssessmentPageConfig<FullAssessmentNotesType>;
  currentPageNumber: number;
  value?: Status;
}

export default function FullAssessmentPage({
  config,
  currentPageNumber,
  value,
}: Props) {
  const {
    pageNumber,
    fieldName,
    title,
    intro,
    notes,
    renderNotesForStatus,
  } = config;
  return currentPageNumber === pageNumber ? (
    <>
      <div className="padding-vertical-base">
        <h3>
          {title}{' '}
          <span className="font-color-grey-light font-weight-base">{`${pageNumber}/${NUMBER_OF_FULL_ASSESSMENT_PAGES}`}</span>
        </h3>
        <p>{intro}</p>
      </div>
      {ASSESSMENT_STATUSES.map(status => (
        <FormGroup>
          <Row>
            <Column sm={1} md={2}>
              <Field
                name={fieldName}
                component="input"
                type="radio"
                value={status}
              />
            </Column>
            <Column sm={11} md={10}>
              {renderNotesForStatus(status, notes[status])}
            </Column>
          </Row>
        </FormGroup>
      ))}
      <ModalFooter>
        <Button type="submit" className="small pill" disabled={!value}>
          {pageNumber === NUMBER_OF_FULL_ASSESSMENT_PAGES ? 'Submit' : 'Next'}
        </Button>
      </ModalFooter>
    </>
  ) : null;
}

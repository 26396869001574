import React from 'react';

import {
  FULL_ASSESSMENT_ATTITUDE_INTRO,
  FULL_ASSESSMENT_ATTITUDE_NOTES,
  FULL_ASSESSMENT_MOVEMENT_INTRO,
  FULL_ASSESSMENT_MOVEMENT_NOTES,
  FULL_ASSESSMENT_THINKING_INTRO,
  FULL_ASSESSMENT_THINKING_NOTES,
  FullAssessmentAttitudeNotes,
  FullAssessmentMovementNotes,
  FullAssessmentNotes,
  FullAssessmentThinkingNotes,
} from '^/app/authenticated/assessments/constants';
import { Status } from '^/app/authenticated/assessments/types';
import StatusPill from '^/common/status-pill';

export interface FullAssessmentPageConfig<T> {
  fieldName: string;
  title: string;
  pageNumber: number;
  intro: string;
  notes: FullAssessmentNotes<T>;
  renderNotesForStatus(status: Status, notes: T): React.ReactNode;
}

export const FULL_ASSESSMENT_MOVEMENT_CONFIG: FullAssessmentPageConfig<
  FullAssessmentMovementNotes
> = {
  fieldName: 'movement_status',
  title: 'Movement',
  pageNumber: 1,
  intro: FULL_ASSESSMENT_MOVEMENT_INTRO,
  notes: FULL_ASSESSMENT_MOVEMENT_NOTES,
  renderNotesForStatus: (
    status: Status,
    notes: FullAssessmentMovementNotes
  ) => (
    <div className="full-assessment-movement-section">
      <StatusPill status={status} />
      <ul className="full-bulleted-list">
        {notes.map((note: string) => (
          <li>{note}</li>
        ))}
      </ul>
    </div>
  ),
};

export const FULL_ASSESSMENT_THINKING_CONFIG: FullAssessmentPageConfig<
  FullAssessmentThinkingNotes
> = {
  fieldName: 'thinking_status',
  title: 'Thinking',
  pageNumber: 2,
  intro: FULL_ASSESSMENT_THINKING_INTRO,
  notes: FULL_ASSESSMENT_THINKING_NOTES,
  renderNotesForStatus: (
    status: Status,
    notes: FullAssessmentThinkingNotes
  ) => (
    <div className="full-assessment-thinking-section">
      <StatusPill status={status} />
      <span className="notes-heading">{notes.heading}</span>
      <p>{notes.notes}</p>
    </div>
  ),
};

export const FULL_ASSESSMENT_ATTITUDE_CONFIG: FullAssessmentPageConfig<
  FullAssessmentAttitudeNotes
> = {
  fieldName: 'attitude_status',
  title: 'Attitude',
  pageNumber: 3,
  intro: FULL_ASSESSMENT_ATTITUDE_INTRO,
  notes: FULL_ASSESSMENT_ATTITUDE_NOTES,
  renderNotesForStatus: (
    status: Status,
    notes: FullAssessmentAttitudeNotes
  ) => (
    <>
      <StatusPill status={status} />
      <div className="full-assessment-attitude-section">
        <div>
          <span className="notes-heading">To Self:</span>
        </div>
        <div>
          <ul>
            {notes.TO_SELF.map((note: string) => (
              <li>{note}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="full-assessment-attitude-section">
        <div>
          <span className="notes-heading">To Others:</span>
        </div>
        <div>
          <ul>
            {notes.TO_OTHERS.map((note: string) => (
              <li>{note}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="full-assessment-attitude-section">
        <div>
          <span className="notes-heading">To Activity:</span>
        </div>
        <div>
          <ul>
            {notes.TO_ACTIVITY.map((note: string) => (
              <li>{note}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  ),
};

import { Container } from '@dabapps/roe';
import { StoreState } from '^/store/types';
import React from 'react';
import { connect } from 'react-redux';
import { LoggedInUser } from './authentication/types';

interface DispatchProps {
  loggedInUser: LoggedInUser | null;
}

type Props = DispatchProps;

function PrivacyPolicyPage(props: Props) {
  const { loggedInUser } = props;

  const content = (
    <>
      <h1 className="text-align-center">PRIVACY POLICY</h1>
      <p>
        MI MOVE PA LIMITED TRADING AS MIMOVE (“
        <span className="bold">COMPANY</span>” or “
        <span className="bold">WE</span>
        ”) WEBSITE [ ] OR MOBILE AND DESKTOP APPLICATIONS AND EXTENSIONS THEREOF
        (HEREINAFTER COLLECTIVELY REFERRED TO AS "
        <span className="bold">APP</span>
        "), SERVICES, FEATURES, CONTENT OR APPLICATIONS OFFERED THROUGH ITS APP
        (HEREINAFTER COLLECTIVELY REFERRED TO AS “
        <span className="bold">SERVICES</span>
        ”) ARE GOVERNED BY THIS PRIVACY POLICY ("
        <span className="bold">PRIVACY POLICY</span>
        ") READ WITH THE TERMS AND CONDITIONS. ACCESSING, BROWSING OR OTHERWISE
        USING THE APP INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS,
        SO PLEASE READ THEM CAREFULLY BEFORE PROCEEDING OR IF YOU ARE NOT
        AGREEABLE TO TERMS HEREIN PLEASE REFRAIN FROM USING THE APP.{' '}
      </p>
      <p>
        Terms used but not defined in this policy will carry the meaning
        attributed to them in the Terms and Conditions.
      </p>
      <p>
        By using the Websites and/or using any of our Apps, you consent to us
        processing your personal information in accordance with this Privacy
        Policy (the “Policy”). If you are an Organisation, you represent and
        warrant that all information including personal information provided by
        you for your pupils, parents where applicable, staff, members, userIDs
        allocated under your purchase comply with applicable laws and that you
        have necessary consent from the owners.
      </p>
      <p>
        This is the legal basis upon which we process your personal information
        as well. It is necessary for the performance of our obligations under
        the terms of use/terms and conditions you accept to use our Apps or the
        Websites.
      </p>
      <p>
        You may withdraw such consent at any time by letting us know at{' '}
        <a href="mailto:info@mimoveapp.com">info@mimoveapp.com</a>. At that
        time, you may also want to remove any cookies which have been placed on
        any device used to access the Websites. Your withdrawal of consent will
        not affect the lawfulness of any processing carried out by us prior to
        such withdrawal.
      </p>
      <p>
        Mi Move, is designed to support children and young people in developing
        a physical activity habit. It is only available to individuals if their
        accounts are opened by a school or other bona fide organisation to the
        user belongs, like a sports club for example. As such, parental consent
        is obtained via the organisation at the point that the school signs up
        the user and opens their accounts. There is a link to the privacy policy
        within the app. Furthermore, parents and users will receive a thorough
        explanation of the purpose of the app, the data that the app will
        capture, how this data will be used and a summary and link to this
        privacy policy before they download the app. This information will be
        prepared by Mi Move PA Limited and passed on to parents via the school
        or organisation.
      </p>
      <p>
        This Privacy Policy highlights inter alia the type of data
        shared/collected from you in the course of your usage of the App and/or
        the Services, the purposes and duration for which your data is collected
        and the App’s policy with regard to sharing such personal information
        with third party entities. This Privacy Policy is subject to change at
        any time without notice and Company encourages you to review this
        Privacy Policy periodically.
      </p>
      <p>
        We do not approve of unauthorized uses, post and strictly prohibit
        offensive or obscene materials on the App, but by using the App, you
        acknowledge and agree that we are not responsible for the use of any
        personal information that you publicly disclose or share with others on
        the App. Please carefully select the type of information that you
        publicly disclose or share with others on the Platform.
      </p>
      <p>
        Privacy Policy applies to all users of the Services, including users who
        are also contributors of content. “Content” includes the text, software,
        scripts, graphics, graphics interchange formats, photos, sounds, music,
        videos, audio visual combinations, interactive features and other
        materials you may view on, access through, or contribute to the
        Services.
      </p>
      <p>
        We take your privacy seriously. It is fundamental to the way we work and
        we are committed to providing a secure environment for you to store your
        personal information and share it with others when you want to.
      </p>
      <ol className="padding-none">
        <li>COLLECTION AND USE OF INFORMATION</li>
        <p>
          To avail certain services on the App, users may be required to provide
          personal and non-personal information ("Information"), like - a) your
          name, b) email address, c) sex, d) age, e) credit card or debit card
          details f) password g) physical attributes and the likes.
        </p>
        <p>
          In connection with certain aspects of the Services, we may request,
          collect and/or display some of your personally identifiable
          information ("Personal Information"). All required information is
          service dependent and we may use the above said user information to,
          maintain, protect, and improve its services (including advertising
          services) and for developing new services. We recommend you use your
          device’s standard keyboard whenever entering credit card numbers,
          passwords or other sensitive information. If you enter any of this
          information into the content library search input area, we will
          receive it. We use reasonable efforts to remove this type of
          information from our records, but cannot guarantee that it will be
          able to do so.
        </p>
        <p>The Information as supplied by you is used to:</p>
        <ul>
          <li>
            to fulfil other such our legal, regulatory and other good governance
            or contractual obligations between us like authenticate access, keep
            a record of your marketing, invoicing preferences, contact you by
            electronic mail or phone in connection with the Services, deal with
            any questions, comments or complaints you may have, retain a record
            of the communications for administrative purposes, including to
            assist you with any ongoing or future concerns, keep a record of
            your contact or emergency/alternative contacts so that they can be
            contacted
          </li>
          <li>
            improve our sites and provide you the most user-friendly experience
            and value added or newer products and services.
          </li>
        </ul>
        <p>
          We use some of the information that you may choose to provide (such as
          your school) to enable you to compare your performance in a sport with
          other people like you. We also use the information we collect to
          analyse performance within an activity by a particular demographic
          such as age or geography by noting when you do well and allowing you
          to share your performances with friends.
        </p>
        <p>
          We also use it for telling you about things we think you will find
          useful and interesting such as how you are performing, or telling you
          about products or services, promotions, offers, features and
          competitions we believe you may be interested in. These communications
          will only ever be sent with your consent, which we obtain at the point
          you open your account. You can choose not to receive direct marketing
          communications or opt-out/unsubscribe at any time by following the
          instructions contained in any marketing emails received. Factual
          communications with respect to the Services will not be affected by
          such opt-out.
        </p>
        <p>
          We also sometimes send you information within an App using what are
          called push notifications. You may at any time opt-out from receiving
          such notifications by turning them off in your settings on the device
          you use to access the App.
        </p>
        <p>
          The data that we collect from you may be processed outside the
          European Economic Area (EEA) (for example by third party processors of
          data), but we have ensured that there is an adequate level of
          protection for such data.
        </p>
        <p>
          The App uses third party services that may collect information used to
          identify you. Link to privacy policy of third party service providers
          used by the App:
        </p>
        <ul>
          <li>
            <a href="https://sentry.io/privacy/">Sentry (Error Tracking)</a>
          </li>
          <li>
            <a href="https://aws.amazon.com/privacy/">Amazon Web Services</a>
          </li>
          <li>
            <a href="https://www.heroku.com/policy/security">Heroku</a>
          </li>
        </ul>
        <li>DURATION:</li>
        <p>
          Your personal data will not be kept for longer than is necessary to
          fulfil the specific purposes outlined in this Privacy Policy, and to
          allow us to comply with our contractual and legal requirements.
        </p>
        <p>
          Any data we do hold onto will be anonymous, personally identifiable
          information such as your name, address, date of birth and emailID will
          be deleted after a defined period. The rest of the data is therefore
          anonymous and will be used to analyse general market and website
          trends but without an identifiable personal information.
        </p>
        <li>AGGREGATED AND ANONYMISED INFORMATION</li>
        <p>
          We may share with third parties or/and use aggregate user statistics
          and other data which does not identify you specifically. We may
          combine this information with that of other users of our Apps and
          share or provide this information in aggregated and anonymised form
          with third parties such as universities, service providers, subject
          matter experts or use the same for product enhancements and
          developments. We may at any point anonymise personal information to
          make sure that such information is can no longer be related back to
          you. We do all this to help us improve the design and delivery of our
          Apps and to assist in research and development.
        </p>
        <li>COOKIES AND OTHER IDENTIFIERS</li>
        <p>
          To improve the responsiveness of the sites for our users, the App and
          third parties with whom we partner, may use cookies, pixel tags, web
          beacons, mobile device IDs, “flash cookies” and similar files or
          technologies to collect and store information with respect to your use
          of the Services and third-party websites.
        </p>
        <p>
          Cookies are small files that are stored on your browser or device by
          websites, Apps, online media and advertisements. We use cookies and
          similar technologies for purposes such as:
        </p>
        <ul>
          <li>Authenticating users;</li>
          <li>Remembering user preferences and settings;</li>
          <li>Determining the popularity of content;</li>
          <li>
            Delivering and measuring the effectiveness of advertising campaigns;
          </li>
          <li>
            Analysing site traffic and trends, and generally understanding the
            online behaviours and interests of people who interact with our
            services.
          </li>
        </ul>
        <p>
          A pixel tag (also called a web beacon or clear GIF) is a tiny graphic
          with a unique identifier, embedded invisibly on a webpage (or an
          online ad or email), and is used to count or track things like
          activity on a webpage or ad impressions or clicks, as well as to
          access cookies stored on users’ computers. We use pixel tags to
          measure the popularity of our various pages, features and services. We
          also may include web beacons in e-mail messages or newsletters to
          determine whether the message has been opened and for other analytics.
        </p>
        <p>
          When you use our Service, in a case of an error in the App we collect
          data and information (through third party products) on your phone
          called Log Data. This Log Data may include information such as your
          device Internet Protocol (“IP”) address, device name, operating system
          version, the configuration of the App when utilizing our Service, the
          time and date of your use of the Service, and other statistics
        </p>
        <p>
          To modify your cookie settings, please visit your browser’s settings.
          By using our Services with your browser settings to accept cookies,
          you are consenting to our use of cookies in the manner described in
          this section.
        </p>
        <p>
          We may also allow third parties to provide audience measurement and
          analytics services for us, to serve advertisements on our behalf
          across the Internet, and to track and report on the performance of
          those advertisements. These entities may use cookies, web beacons,
          SDKs and other technologies to identify your device when you visit the
          App and use our Services, as well as when you visit other online sites
          and services. We do not control their process of collection use and
          storing of information.
        </p>
        <li>INFORMATION SHARING</li>
        <p>
          We will only share information with a third party if we are satisfied
          that such third party will keep that information secure. We do not
          share Personal Information with any third party without obtaining the
          prior consent of the user except in the following limited
          circumstances:
        </p>
        <ol className="list-style-lower-alpha">
          <li>
            If you shared the content with another user of our Services or if
            you are creating published collections of the Content, your account
            name and profile will be associated with the Content you upload,
            will be visible to anyone who views the Content you have uploaded,
            and may be visible to the public. In addition to any information you
            may elect to place in your account profile, your account profile
            will also include certain information regarding your use of the
            Services, including but not limited to the Content collections you
            have published, number of other users you follow, and the number of
            users who follow you.
          </li>
          <li>
            When it is requested or required by law or by any court or
            governmental agency or authority to disclose, for the purpose of
            verification of identity, or for the prevention, detection,
            investigation including cyber incidents, or for prosecution and
            punishment of offences. These disclosures are made in good faith and
            belief that such disclosure is reasonably necessary for enforcing
            these Terms; for complying with the applicable laws and regulations
          </li>
          <li>
            With our service providers or our affiliated companies in order to
            provide and distribute the Services and their functions, the App,
            its other services, and related services and support; to complete
            billing and payment for them; or to collect information and
            communicate with you as described above.
          </li>
          <li>
            In connection with the transfer, sale, merger or reorganization of
            all or any relevant portion of its business or assets to or with any
            third party.
          </li>
        </ol>
        <p>
          We do not process any personal data in jurisdictions other than the
          UK. However, some of our service providers may (from time to time)
          hold personal data on data centres located in other jurisdictions or
          otherwise rely on sub-contractors located in other jurisdictions.
        </p>
        <p>
          If your personal data is to be transferred from within to outside the
          European Economic Area, we will only make that transfer if:
        </p>
        <ul>
          <li>
            the country to which the personal data is to be transferred ensures
            an adequate level of protection for personal data;
          </li>
          <li>
            we have put in place appropriate safeguards to protect your personal
            data, such as an appropriate contract with the recipient;
          </li>
          <li>
            the transfer is necessary for one of the reasons specified in data
            protection legislation, such as the performance of a contract
            between us and you; or
          </li>
          <li>you explicitly consent to the transfer.</li>
        </ul>
        <p>INFORMATION SECURITY</p>
        <p>
          We understand the importance of security and protection of the
          Information provided by and/or collected from you. Pursuant to the
          same, we shall make the best efforts to ensure protection of
          Information by use of such security measures and programs that it may
          deem fit for the purpose to protect the Information against any
          unauthorized, illegal and fraudulent use of such Information by third
          parties. We shall adopt appropriate encryption and security measures
          to prevent any hacking of your Information and third parties. The App
          shall use the Information collected from you in accordance with
          applicable laws and the rules made thereunder and use the data only
          for the purpose for which it was furnished.
        </p>
        <p>
          Company shall endeavour to safeguard the confidentiality of your
          Personal Information, however, the transmissions made by means of the
          Internet cannot be made absolutely secure by the App. You agree and
          acknowledge that the App shall not be liable for disclosure of any
          Information due to errors in transmission or any unauthorized acts of
          third parties. We take appropriate security measures to protect
          against unauthorized access to or unauthorized alteration, disclosure
          or destruction of data. These include internal reviews of our data
          collection, storage and processing practices and security measures,
          including appropriate encryption and physical security measures to
          guard against unauthorized access to systems where we store personal
          data. We cannot guarantee the security of our database.
        </p>
        <p>
          Notwithstanding anything to the contrary, please note that we are not
          be held responsible for any loss, damage or misuse of the information
          caused to you, if such loss, damage or misuse is attributable to an
          event beyond the control of or is not attributable to Company like
          errors in transmission or any unauthorized acts of third parties or a
          force majeure event.
        </p>
        <li>LINKS TO THE OTHER SITES</li>
        <p>
          Our policy discloses the privacy practices for our own web site and
          App. Our Website or App provides links to other websites, including
          but not limited to payment gateways, also that are beyond our control.
          We shall in no way be responsible for your use of such links and
          sites.
        </p>
        <li>YOUR RIGHTS</li>
        <p>
          We think it is important that you are able to control your personal
          data. You have the right to ask us not to process your personal data
          for marketing purposes. You can exercise your right to prevent such
          processing at any time by contacting us at{' '}
          <a href="mailto:marcella@mimoveapp.com">marcella@mimoveapp.com.</a>
        </p>
        <p>
          The law gives you the right to request a copy of the personal
          information we hold about you. We first require you to prove your
          identity with 2 pieces of approved identification. We will supply,
          correct or delete personal information about you on our files. In
          addition, you may request rectification or erasure of personal
          information as well as the restriction of processing of your personal
          information.
        </p>
        <p>
          If you wish to complain about the processing of your personal
          information then please contact us first, but if we do not
          satisfactorily deal with your complaint, then you may contact Marcella
          Griso at{' '}
          <a href="mailto:marcella@mimoveapp.com">marcella@mimoveapp.com</a>.
        </p>
        <li>CONTACT US</li>
        <p>
          We welcome any questions, comments and requests you may have regarding
          this Policy. You can contact us by emailing us at{' '}
          <a href="mailto:info@mimoveapp.com">info@mimoveapp.com</a>
        </p>
      </ol>
    </>
  );

  return loggedInUser ? (
    <Container className="margin-top-large">{content}</Container>
  ) : (
    content
  );
}

function mapStateToProps({ loggedInUser }: StoreState) {
  return {
    loggedInUser,
  };
}

export default connect(mapStateToProps)(PrivacyPolicyPage);

import { SimpleRecord } from '@dabapps/simple-records';

import { ETHNICITY, GENDER } from '^/common/users/types';

export interface ClassGroupOption {
  readonly id: string;
  readonly name: string;
}
export const ClassGroupOption = SimpleRecord<ClassGroupOption>({
  id: '',
  name: '',
});

export interface EditCreateUser {
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly username: string;
  readonly class_group?: string;
  readonly year_group?: string;
  readonly gender?: GENDER;
  readonly is_pupil_premium: boolean;
  readonly ethnicity?: ETHNICITY;
  readonly is_send_registered: boolean;
  readonly is_eal: boolean;
  readonly temporary_password?: string;
  readonly school?: string;
  readonly role?: string;
  readonly password?: string;
}

export const EditCreateUser = SimpleRecord<EditCreateUser>({
  id: '',
  first_name: '',
  last_name: '',
  username: '',
  is_pupil_premium: false,
  is_send_registered: false,
  is_eal: false,
});

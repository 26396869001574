import React, { useState } from 'react';
import './graph-container-styles.less';

import { GraphCard } from '^/app/components';
import { ActivityLocationsPieGraph } from './activity-locations-pie-graph';
import GradeBreakdownBarGraph from './grade-breakdown-bar-graph';
import { GraphContainerProps } from '../types';
import { EmotionalResponseBarGraph } from './emotional-response-bar-graph';
import TrendsTimeSeriesGraph from './trends-time-series-graph';
import { IActivityLocations } from '^/api-services/dashboard/types';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';

// colors
const ActivityLocationColors = [
  '#80D4F8',
  '#53C5F7',
  '#29B8F6',
  '#045273',
  '#066A95',
  '#1A83B1',
];
const EmotionalResponseColors = [
  '#66BB6A',
  '#64B5F6',
  '#FFEB3B',
  '#FF9800',
  '#F44336',
];
const TrendsGraphColors = ['#66BB6A', '#F44336', '#000'];

const GradeBreakdownColors = ['#66BB6A', '#F44336'];

export const GraphContainer: React.FC<GraphContainerProps> = ({
  data,
  handleGraphClick,
}: GraphContainerProps) => {
  const [isYearBreakdownShown, setGraphToggle] = useState(true);

  const mapActivityLocationData = (data: IActivityLocations) => {
    if (data) {
      const finalData: any[] = [];
      Object.keys(data).forEach((key, index) => {
        if ((data as any)[key]) {
          finalData.push({
            name: key,
            value: (data as any)[key],
            color: ActivityLocationColors[index],
          });
        }
      });
      return finalData;
    }
    return [];
  };

  return (
    <div className="graph-wrapper">
      <div className="mb-3">
        <GraphCard
          title={DASHBOARD_PAGE_LABELS.class_breakdown_graph_title}
          title2={DASHBOARD_PAGE_LABELS.year_breakdown_graph_title}
          height="50vh"
          width="100%"
          onToggleChange={setGraphToggle}
        >
          {isYearBreakdownShown ? (
            <GradeBreakdownBarGraph
              data={data.year_wise_breakdown}
              colors={GradeBreakdownColors}
              handleClick={handleGraphClick}
              is_year_wise={isYearBreakdownShown}
            />
          ) : (
            <GradeBreakdownBarGraph
              data={data.class_wise_breakdown}
              colors={GradeBreakdownColors}
              handleClick={handleGraphClick}
              is_year_wise={isYearBreakdownShown}
            />
          )}
        </GraphCard>
      </div>
      <div className="graph-container">
        <div className="left-graph-col">
          <div className="mb-3 flex-1">
            <GraphCard
              title={DASHBOARD_PAGE_LABELS.activity_locations_graph_title}
              height="100%"
              width="100%"
            >
              <ActivityLocationsPieGraph
                data={mapActivityLocationData(data.activity_locations)}
                handleClick={handleGraphClick}
              />
            </GraphCard>
          </div>
          <div className="flex-1">
            <GraphCard
              title={DASHBOARD_PAGE_LABELS.trends_graph_title}
              height="100%"
              width="100%"
              cardBodyPadding="1em .5em"
            >
              <TrendsTimeSeriesGraph
                data={data.trends}
                colors={TrendsGraphColors}
                handleClick={handleGraphClick}
              />
            </GraphCard>
          </div>
        </div>
        <div className="right-graph-col">
          <GraphCard
            title={DASHBOARD_PAGE_LABELS.emotional_response_graph_title}
            height="100%"
            width="100%"
          >
            <EmotionalResponseBarGraph
              data={data.emotional_response}
              colors={EmotionalResponseColors}
              handleClick={handleGraphClick}
            />
          </GraphCard>
        </div>
      </div>
    </div>
  );
};

import { LoggedInUser, ROLES } from '^/common/authentication/types';
import { StoreState } from '^/store/types';
import * as React from 'react';
import { connect } from 'react-redux';

interface StateProps {
  loggedInUser?: LoggedInUser | null;
}

interface ExternalProps {
  requiredRoles: ROLES[] ;
  hideIfNoPermission: boolean;
}

type Props = StateProps & ExternalProps;

export class PermissionRequired extends React.PureComponent<Props> {
  public render() {
    const { loggedInUser, hideIfNoPermission } = this.props;

    if (loggedInUser && !(this.props.requiredRoles.includes(loggedInUser.role))) {
      return hideIfNoPermission ? null : (
        <p>Sorry, you don't have permission to view this.</p>
      );
    }

    return this.props.children;
  }
}

function mapStateToProps({ loggedInUser }: StoreState) {
  return {
    loggedInUser,
  };
}

export default connect(mapStateToProps)(PermissionRequired);

import { Pagination } from '../activities/types';
import ApiService from '../apiService';
import { ServiceType } from '../serviceTypes';
import {
  IRootClusterDetails,
  ICreateCluster,
  IClusterDetails,
  IEditCluster,
} from './types';

const service: ApiService = new ApiService(ServiceType.CLUSTER);

const getRootClusters = (pageInfo: {
  page: number;
  pageSize: number;
}): Promise<Pagination<IRootClusterDetails>> => {
  return service.get<Pagination<IRootClusterDetails>>({
    route: [],
    query: {
      page: pageInfo.page,
      page_size: pageInfo.pageSize,
    },
  });
};

const createCluster = (clusterDetails: ICreateCluster): Promise<void> => {
  return service.post(
    {
      route: [],
    },
    clusterDetails
  );
};

const editClusterDetails = (data: IEditCluster): Promise<IEditCluster> => {
  return service.patch<IEditCluster>(
    {
      route: [data.id],
      query: {},
    },
    data
  );
};

const getClusterDetails = (
  clusterID: string = ''
): Promise<IClusterDetails> => {
  return service.get<IClusterDetails>({
    route: [clusterID],
  });
};

const deleteCluster = (clusterID: string = '') => {
  return service.delete<IClusterDetails>({
    route: [clusterID],
  });
};

export const ClusterApis = {
  createCluster,
  getRootClusters,
  getClusterDetails,
  editClusterDetails,
  deleteCluster,
};

export default ClusterApis;

import {
  hasFailed,
  isPending,
  resetRequestState,
} from '@dabapps/redux-requests';
import { Column, Container, ContentBox } from '@dabapps/roe';
import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  GET_IMPORT_STATUS,
  importUsersAndBeginPolling,
  QUEUE_IMPORT_USER,
} from './actions';
import ImportUsersForm from './import-users-form';
import { ImportUsersCsvFile } from './types';

const breadcrumbs = [
  { label: 'Users', path: '/users' },
  { label: 'Import Users', path: '' },
];

interface StateProps {
  queueImportIsLoading: boolean;
  isImportPendingOrInProgress: boolean;
  importStatusHasFailed: boolean;
  currentSchool: string | null;
  queueImportErrors: ResponseErrors | undefined;
  importErrors: ResponseErrors | null;
}

interface DispatchProps {
  importUsersAndBeginPolling: typeof importUsersAndBeginPolling;
  resetRequestState: typeof resetRequestState;
}

type Props = StateProps & DispatchProps;

export class ImportUsers extends React.PureComponent<Props> {
  public componentWillUnmount() {
    this.props.resetRequestState(GET_IMPORT_STATUS);
    this.props.resetRequestState(QUEUE_IMPORT_USER);
  }

  public render() {
    const {
      queueImportIsLoading,
      queueImportErrors,
      isImportPendingOrInProgress,
      importStatusHasFailed,
      importErrors,
    } = this.props;

    return (
      <Container className="margin-top-large">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Column sm={10} md={8} lg={5} smPush={1} mdPush={2} lgPush={3}>
          <ContentBox>
            <h3 className="font-size-medium">
              Upload a CSV file to import users into the system.
            </h3>
            <p>
              CSV files should contain the following fields in the following
              order: Student Full Name, Username, Year, Class, Gender, Pupil
              Premium, Ethnicity, SEND, EAL.
            </p>

            {importStatusHasFailed ? (
              <ErrorMessage />
            ) : isImportPendingOrInProgress ? (
              <div className="padding-vertical-large">
                <LoadingSpinner />
              </div>
            ) : (
              <ImportUsersForm
                onSubmit={this.queueImport}
                isLoading={queueImportIsLoading}
                queueImportErrors={queueImportErrors}
                importErrors={importErrors}
              />
            )}
          </ContentBox>
        </Column>
      </Container>
    );
  }

  private queueImport = (data: Partial<ImportUsersCsvFile>) => {
    if (this.props.currentSchool) {
      const dataToSubmit = {
        school: this.props.currentSchool,
        ...data,
      };

      this.props.importUsersAndBeginPolling(dataToSubmit);
    }
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    currentSchool: state.currentSchool,
    queueImportIsLoading: isPending(state.responses, QUEUE_IMPORT_USER),
    isImportPendingOrInProgress: state.isImportPendingOrInProgress,
    importStatusHasFailed: hasFailed(state.responses, GET_IMPORT_STATUS),
    queueImportErrors: getResponseErrors(state.responses, QUEUE_IMPORT_USER),
    importErrors: state.importErrors,
  };
}

export default connect(mapStateToProps, {
  importUsersAndBeginPolling,
  resetRequestState,
})(ImportUsers);

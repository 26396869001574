import ApiService from '../apiService';
import { ServiceType } from '../serviceTypes';
import {
  IYearGroupCreatedResponse,
  IYearGroupsRequest,
  IYearGroupsResponse,
} from './types';

class CommonApiClass {
  public getYearGroups = (
    request: IYearGroupsRequest
  ): Promise<IYearGroupsResponse> | IYearGroupsResponse => {
    const service: ApiService = new ApiService(ServiceType.COHORTS);
    return service.get<IYearGroupsResponse>({
      route: ['year-group'],
      query: {
        school: request.school,
        page: request.page || 1,
        page_size: request.page_size || 100,
      },
    });
  };

  public createYearGroups = (yearGroupName: string, schoolUUID: string) => {
    const service: ApiService = new ApiService(ServiceType.COHORTS);
    return service.post<IYearGroupCreatedResponse>(
      {
        route: ['year-group'],
        query: {},
      },
      {
        school: schoolUUID,
        name: yearGroupName,
      }
    );
  };

  public getClassGroups = (
    request: IYearGroupsRequest
  ): Promise<IYearGroupsResponse> | IYearGroupsResponse => {
    const service: ApiService = new ApiService(ServiceType.COHORTS);
    return service.get<IYearGroupsResponse>({
      route: ['class-group-options'],
      query: {
        school: request.school,
        page: request.page || 1,
        page_size: request.page_size || 100,
      },
    });
  };

  public getOtherGroups = (
    request: IYearGroupsRequest
  ): Promise<IYearGroupsResponse> | IYearGroupsResponse => {
    const service: ApiService = new ApiService(ServiceType.COHORTS);
    return service.get<IYearGroupsResponse>({
      route: ['class-group-options'],
      query: {
        school: request.school,
        page: request.page || 1,
        page_size: request.page_size || 100,
        include_only_extracurricular: true,
      },
    });
  };

  public createClassGroups = (classGroupName: string, schoolUUID: string) => {
    const service: ApiService = new ApiService(ServiceType.COHORTS);
    return service.post<IYearGroupCreatedResponse>(
      {
        route: ['class-groups'],
        query: {},
      },
      {
        school: schoolUUID,
        name: classGroupName,
      }
    );
  };
}

const CommonApis = new CommonApiClass();

export default CommonApis;

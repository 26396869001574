import { Pagination } from '../activities/types';
import ApiService from '../apiService';
import { ServiceType } from '../serviceTypes';
import {
  ICreateSchoolRequest,
  IMessageRules,
  ISchoolsRequest,
  ISchoolsResponse,
  IUpdateSchoolRequest,
} from './types';

class SchoolsApiClass {
  public fetchSchoolsData = ({
    page = 1,
    page_size = 100,
    search = '',
  }: ISchoolsRequest) => {
    const service: ApiService = new ApiService(ServiceType.SCHOOLS);
    return service.get<ISchoolsResponse>({
      route: ['school-switcher'],
      query: {
        page,
        page_size,
        search,
      },
    });
  };

  public fetchSchoolsPageData = (data: ISchoolsRequest) => {
    const service: ApiService = new ApiService(ServiceType.SCHOOLS);
    return service.get<ISchoolsResponse>({
      route: [],
      query: {
        ...data,
      },
    });
  };

  public getSchoolDetails = (id: string) => {
    const service: ApiService = new ApiService(ServiceType.SCHOOLS);
    return service.get<ICreateSchoolRequest>({ route: [id] });
  };

  public createSchool = (data: ICreateSchoolRequest) => {
    const service: ApiService = new ApiService(ServiceType.SCHOOLS);
    return service.post(
      {
        route: [],
      },
      data
    );
  };

  public updateSchool = (data: IUpdateSchoolRequest) => {
    const service: ApiService = new ApiService(ServiceType.SCHOOLS);
    const { id, ...updateValues } = data;
    return service.patch(
      {
        route: [id],
      },
      updateValues
    );
  };

  public getMessageRules = () => {
    const service: ApiService = new ApiService(ServiceType.MESSAGES);
    return service.get<Pagination<IMessageRules>>({
      route: ['automated-message-rules'],
      query: {
        pageSize: 100,
      },
    });
  };
}

const SchoolsApi = new SchoolsApiClass();

export default SchoolsApi;

import { SimpleRecord } from '@dabapps/simple-records';

export type Emoji =
  | 'THUMBS_UP'
  | 'SMILE'
  | 'LAUGHING'
  | 'HEART_EYES'
  | 'TONGUE_OUT';

export interface MessageListItem {
  readonly id: string;
  readonly created: string;
  readonly text: string;
  readonly emoji?: Emoji;
}
export const MessageListItem = SimpleRecord<MessageListItem>({
  id: '',
  created: '',
  text: '',
  emoji: undefined,
});

export interface NewMessage {
  readonly text: string;
  readonly emoji: Emoji;
}

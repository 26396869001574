import * as React from 'react';

// import ActivityOverviewHeader from '^/app/authenticated/activities/overview/header';
// import ActivityOverviewStats from '^/app/authenticated/activities/overview/stats';
import StudentList from '^/app/authenticated/activities/overview/students/list';

export default function ActivityOverview() {
  return (
    <>
      {/* TODO: Remove it once the business confirms it's no longer required permanently */}
      {/* <ActivityOverviewHeader />
      <ActivityOverviewStats /> */}
      <StudentList />
    </>
  );
}

// TODO: enable eslint once chadmin is removed
/* eslint-disable */
import { TColumns } from '^/chadmin';
import classNames from 'classnames';
import * as React from 'react';

import {
  FEELING_NAMES,
  LOCATION_NAMES,
} from '^/app/authenticated/activities/constants';
import {
  ASSESSMENT_STATUS_DETAILS,
  ASSESSMENT_TYPE_DISPLAY,
} from '^/app/authenticated/assessments/constants';
import { AssessmentListItem } from '^/app/authenticated/assessments/types';
import { EMOJI_TO_CLASSNAME } from '^/app/authenticated/messaging/constants';
import { MessageListItem } from '^/app/authenticated/messaging/types';
import { ACTIVITY_NAMES } from '^/common/constants';
import StatusPill from '^/common/status-pill';
import { formatDate } from '^/common/utils/format-date';
import { IActivityListItem } from '^/api-services/activities/types';

export const ACTIVITY_COLUMNS = (
  openPreview: (url: string, type?: string) => void
): TColumns<IActivityListItem> => {
  return [
    {
      type: 'custom',
      name: 'activity',
      display_name: 'TITLE',
      customItemHandler: (data) => (
        <strong>{ACTIVITY_NAMES[data.activity]}</strong>
      ),
    },
    {
      type: 'custom',
      name: 'location',
      display_name: 'LOCATION',
      customItemHandler: (data) => (
        <span className="font-weight-light">
          {LOCATION_NAMES[data.location]}
        </span>
      ),
    },
    {
      type: 'custom',
      name: 'date',
      display_name: 'DATE',
      customItemHandler: (data) => (
        <span className="font-weight-light">{formatDate(data.date)}</span>
      ),
    },
    {
      type: 'custom',
      name: 'duration_minutes',
      display_name: 'TIME',
      customItemHandler: (data) => (
        <span className="font-weight-light">{`${data.duration_minutes}m`}</span>
      ),
    },
    {
      type: 'custom',
      name: 'feeling',
      display_name: 'FEELING',
      customItemHandler: (data) => (
        <span className="font-weight-light">{FEELING_NAMES[data.feeling]}</span>
      ),
    },
    {
      type: 'custom',
      name: 'notes',
      display_name: 'REFLECTIONS',
      customItemHandler: (data) =>
        data.best_thing_about_activity || data.other_activity_type ? (
          <div className="table-notes">
            {data.other_activity_type && (
              <strong>{data.other_activity_type}. </strong>
            )}
            {data.best_thing_about_activity}
          </div>
        ) : null,
    },
    {
      type: 'custom',
      name: 'photo',
      display_name: 'PHOTO',
      customItemHandler: (data) => {
        return (
          data.photo && (
            <img
              src={data.photo}
              className="activity-image-cell"
              alt="activity image"
              onClick={() => openPreview(data.photo || '')}
            />
          )
        );
      },
    },
    {
      type: 'custom',
      name: 'video',
      display_name: 'VIDEO',
      customItemHandler: (data) => {
        return (
          data.video && (
            <img
              src={data.video}
              className="activity-image-cell"
              onClick={() => openPreview(data.video || '', 'VIDEO')}
            />
          )
        );
      },
    },
  ];
};

export const PENDING_ACTIVITY_COLUMNS: TColumns<IActivityListItem> = [
  {
    type: 'custom',
    name: 'activity',
    display_name: 'TITLE',
    customItemHandler: (data) => (
      <strong>{ACTIVITY_NAMES[data.activity]}</strong>
    ),
  },
  {
    type: 'custom',
    name: 'location',
    display_name: 'LOCATION',
    customItemHandler: (data) => (
      <span className="font-weight-light">{LOCATION_NAMES[data.location]}</span>
    ),
  },
  {
    type: 'custom',
    name: 'date',
    display_name: 'DATE',
    customItemHandler: (data) => (
      <span className="font-weight-light">{formatDate(data.date)}</span>
    ),
  },
  {
    type: 'custom',
    name: 'duration_minutes',
    display_name: 'TIME',
    customItemHandler: (data) => (
      <span className="font-weight-light">{`${data.duration_minutes}m`}</span>
    ),
  },
];

export const ASSESSMENT_COLUMNS: TColumns<AssessmentListItem> = [
  {
    type: 'custom',
    name: 'assessment_type',
    display_name: 'TYPE',
    customItemHandler: (data) => (
      <strong>{ASSESSMENT_TYPE_DISPLAY[data.assessment_type]}</strong>
    ),
  },
  {
    type: 'custom',
    name: 'created',
    display_name: 'DATE',
    customItemHandler: (data) => (
      <span className="font-weight-light">{formatDate(data.created)}</span>
    ),
  },
  {
    type: 'custom',
    name: 'status',
    display_name: 'NOTES',
    customItemHandler: (data) => (
      <div className="table-notes">
        {ASSESSMENT_STATUS_DETAILS[data.status].notes}
      </div>
    ),
  },
  {
    type: 'custom',
    name: 'status',
    display_name: 'ASSESSMENT STATUS',
    customItemHandler: (data) => <StatusPill status={data.status} />,
  },
];

export const MESSAGES_COLUMNS: TColumns<MessageListItem> = [
  {
    type: 'custom',
    name: 'created',
    display_name: 'DATE',
    customItemHandler: (data) => (
      <span className="font-weight-light">{formatDate(data.created)}</span>
    ),
  },
  {
    type: 'custom',
    name: 'text',
    display_name: 'MESSAGE',
    customItemHandler: (data) => <div className="table-notes">{data.text}</div>,
  },
  {
    type: 'custom',
    name: 'emoji',
    display_name: 'EMOJI',
    customItemHandler: (data) =>
      data.emoji ? (
        <span className={classNames('emoji', EMOJI_TO_CLASSNAME[data.emoji])} />
      ) : null,
  },
];

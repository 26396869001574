import React from 'react';
import { Popconfirm, Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface IConfirmButton extends ButtonProps {
  onConfirm: () => void;
  placement: TooltipPlacement;
  popConfirmMessages: {
    success: string;
    title: string;
    failure: string;
  };
  buttonTitle: string;
  popupClassName?: string;
  buttonClassName?: string;
}

export function ConfirmButton({
  placement,
  popConfirmMessages,
  onConfirm,
  buttonTitle,
  popupClassName,
  buttonClassName,
  ...buttonProps
}: IConfirmButton) {
  return (
    <Popconfirm
      placement={placement}
      title={popConfirmMessages.title}
      onConfirm={onConfirm}
      okText={popConfirmMessages.success}
      cancelText={popConfirmMessages.failure}
      className={popupClassName}
    >
      <Button
        className={buttonClassName + (buttonProps.className || '')}
        {...buttonProps}
      >
        {buttonTitle}
      </Button>
    </Popconfirm>
  );
}

import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { USER_DETAIL_ROUTE } from '^/app/authenticated/activities/activity-page/constants';
import { ASSESSMENT_TYPE_DISPLAY } from '^/app/authenticated/assessments/constants';
import { AssessmentType, Status } from '^/app/authenticated/assessments/types';
import { collections } from '^/common/collections';
import { closeModal } from '^/common/modals/actions';
import { StoreState } from '^/store/types';

const {
  actions: { getItem },
} = collections;

export const TOGGLE_ASSESSMENT_TYPE = 'TOGGLE_ASSESSMENT_TYPE';
export function toggleAssessmentType() {
  return {
    type: TOGGLE_ASSESSMENT_TYPE,
  };
}

export const CHANGE_FULL_ASSESSMENT_PAGE = 'CHANGE_FULL_ASSESSMENT_PAGE';
export function changeFullAssessmentPage(pageNumber?: number) {
  return {
    type: CHANGE_FULL_ASSESSMENT_PAGE,
    pageNumber,
  };
}

export interface AddAssessmentData {
  movement_status?: Status;
  thinking_status?: Status;
  attitude_status?: Status;
  is_full_assessment?: boolean;
  status?: Status;
  student_id: string;
}
export const ADD_ASSESSMENT = makeAsyncActionSet('ADD_ASSESSMENT');
export function addAssessment(
  data: AddAssessmentData,
  type: AssessmentType,
  studentName: string
) {
  return (dispatch: Dispatch, getState: () => StoreState) => {
    return request(ADD_ASSESSMENT, '/api/assessments/', 'POST', data)(
      dispatch
    ).then(() => {
      getItem(USER_DETAIL_ROUTE, data.student_id, data.student_id)(
        dispatch,
        getState,
        null
      );
      dispatch(closeModal());
      toast.success(
        `${ASSESSMENT_TYPE_DISPLAY[type]} assessment for ${studentName} added`
      );
    });
  };
}

import { makeAsyncActionSet } from '@dabapps/redux-requests';
import { request } from '@dabapps/redux-requests';
import { push, replace } from 'connected-react-router';
import * as Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import {
  getChangePasswordPath,
  hasTemporaryPassword,
} from '^/app/authenticated/users/user-management/utils';
import { collections } from '^/common/collections';
import { CURRENT_SCHOOL_COOKIE_KEY } from '^/common/constants';
import { setCurrentSchool } from '^/common/schools/actions';
import { isSuccessResponse } from '^/common/utils/responses';
import {
  loggedInUserIsAdmin,
  loggedInUserIsClusterAdmin,
  loggedInUserIsClusterManager,
} from '^/common/utils/roles';
import { StoreState } from '^/store/types';

const {
  actions: { getItem },
} = collections;

export const LOGOUT = makeAsyncActionSet('LOGOUT');
export function logout() {
  return (dispatch: Dispatch) => {
    return request(
      LOGOUT,
      '/api/auth/logout/',
      'POST'
    )(dispatch).then(() => {
      dispatch(push('/'));
      Cookies.remove(CURRENT_SCHOOL_COOKIE_KEY);
    });
  };
}

export const GET_FIRST_AVAILABLE_SCHOOL = makeAsyncActionSet(
  'GET_FIRST_AVAILABLE_SCHOOL'
);
export function getFirstAvailableSchool() {
  return request(
    GET_FIRST_AVAILABLE_SCHOOL,
    '/api/schools/school-switcher/?page=1&page_size=1',
    'GET'
  );
}

export function getSchoolsListThenAssignFirstOrRedirect(
  isClusterAdminTemp = true
) {
  return (dispatch: Dispatch, getState: () => StoreState) => {
    return getFirstAvailableSchool()(dispatch).then((response) => {
      if (response) {
        const { loggedInUser } = getState();
        if (
          loggedInUserIsClusterAdmin(loggedInUser) ||
          loggedInUserIsClusterManager(loggedInUser)
        ) {
          if (response.data.count !== 0) {
            const currentSchool: string = response.data.results[0].id;
            Cookies.set(CURRENT_SCHOOL_COOKIE_KEY, currentSchool);
            dispatch(setCurrentSchool(currentSchool));
          }
          isClusterAdminTemp && dispatch(push('/'));
        } else if (response.data.count === 0) {
          dispatch(push('/schools/'));
        } else {
          const currentSchool: string = response.data.results[0].id;
          Cookies.set(CURRENT_SCHOOL_COOKIE_KEY, currentSchool);
          dispatch(setCurrentSchool(currentSchool));
          getItem('schools/school-switcher', currentSchool)(
            dispatch,
            getState,
            null
          );
        }
      }
    });
  };
}

export interface LoginData {
  username?: string;
  password?: string;
  school?: string;
}

export const GET_LOGGED_IN_USER = makeAsyncActionSet('GET_LOGGED_IN_USER');
export function getLoggedInUser() {
  return request(GET_LOGGED_IN_USER, '/api/users/logged-in-user/', 'GET');
}

export const LOGIN = makeAsyncActionSet('LOGIN');
export function login(data: LoginData) {
  return (dispatch: Dispatch, getState: () => StoreState) => {
    return request(
      LOGIN,
      '/api/auth/login/',
      'POST',
      data
    )(dispatch).then((response) => {
      if (response && response.status === 200) {
        getLoggedInUser()(dispatch).then(() => {
          const { currentSchool, loggedInUser } = getState();
          if (loggedInUser && hasTemporaryPassword(loggedInUser)) {
            // change this
            // if cluster admin then assign school first then reset password coz school will be empty
            loggedInUserIsClusterAdmin(loggedInUser) ||
            loggedInUserIsClusterManager(loggedInUser)
              ? getSchoolsListThenAssignFirstOrRedirect(false)(
                  dispatch,
                  getState
                )
              : null;
            // this will take to reset password
            dispatch(replace(getChangePasswordPath(loggedInUser.id)));
            return;
          }
          if (!currentSchool && loggedInUser) {
            //admin
            getSchoolsListThenAssignFirstOrRedirect()(dispatch, getState);
          } else {
            //teacher
            if (loggedInUserIsAdmin(loggedInUser) && currentSchool) {
              getItem('schools/school-switcher', currentSchool)(
                dispatch,
                getState,
                null
              );
            }
            push('/');
          }
        });
      }
    });
  };
}

export interface ResetPasswordData {
  email: string;
}

export const RESET_PASSWORD = makeAsyncActionSet('RESET_PASSWORD');
export function resetPassword(data: ResetPasswordData) {
  return request(RESET_PASSWORD, '/api/auth/password/reset/', 'POST', data);
}

export interface ResetPasswordConfirmationData {
  new_password1: string;
  new_password2: string;
  uid: string;
  token: string;
}

export const RESET_PASSWORD_CONFIRMATION = makeAsyncActionSet(
  'RESET_PASSWORD_CONFIRMATION'
);
export function resetPasswordConfirmation(data: ResetPasswordConfirmationData) {
  return request(
    RESET_PASSWORD_CONFIRMATION,
    '/api/auth/password/reset/confirm/',
    'POST',
    data
  );
}

export interface ChangePasswordData {
  old_password: string;
  new_password1: string;
  new_password2: string;
}

export const CHANGE_PASSWORD = makeAsyncActionSet('CHANGE_PASSWORD');
export function changePassword(data: ChangePasswordData) {
  return (dispatch: Dispatch) => {
    return request(
      CHANGE_PASSWORD,
      '/api/auth/password/change/',
      'POST',
      data
    )(dispatch).then((response) => {
      const successfulRequest = response ? isSuccessResponse(response) : false;
      if (successfulRequest) {
        toast.success('Password updated');
      }
      return response;
    });
  };
}

export function changePasswordAndRedirectToProfile(
  data: ChangePasswordData,
  userId: string
) {
  return (dispatch: Dispatch) => {
    return changePassword(data)(dispatch).then((response) => {
      if (response && isSuccessResponse(response)) {
        dispatch(push(`/users/edit-user/${userId}`));
      }
    });
  };
}

export function changePasswordFromTemporaryPassword(
  data: ChangePasswordData,
  redirectPath: string
) {
  return (dispatch: Dispatch) => {
    return changePassword(data)(dispatch).then((response) => {
      if (response && isSuccessResponse(response)) {
        getLoggedInUser()(dispatch);
        dispatch(push(redirectPath));
      }
    });
  };
}

export interface LoginSocial {
  school?: string;
  access_token?: string;
  code?: string;
}

export const LOGIN_SOCIAL = makeAsyncActionSet('LOGIN_SOCIAL');
export function loginSocial(data: LoginSocial) {
  return (dispatch: Dispatch, getState: () => StoreState) => {
    return request(
      LOGIN_SOCIAL,
      '/api/users/social-auth/',
      'POST',
      data
    )(dispatch).then((response) => {
      if (response && response.status === 200) {
        getLoggedInUser()(dispatch).then(() => {
          const { currentSchool, loggedInUser } = getState();
          // if (loggedInUser && hasTemporaryPassword(loggedInUser)) {
          //   // change this
          //   // if cluster admin then assign school first then reset password coz school will be empty
          //   loggedInUserIsClusterAdmin(loggedInUser) ||
          //   loggedInUserIsClusterManager(loggedInUser)
          //     ? getSchoolsListThenAssignFirstOrRedirect(false)(
          //         dispatch,
          //         getState
          //       )
          //     : null;
          //   // this will take to reset password
          //   dispatch(replace(getChangePasswordPath(loggedInUser.id)));
          //   return;
          // }
          if (!currentSchool && loggedInUser) {
            //admin
            getSchoolsListThenAssignFirstOrRedirect()(dispatch, getState);
          } else {
            //teacher
            if (loggedInUserIsAdmin(loggedInUser) && currentSchool) {
              getItem('schools/school-switcher', currentSchool)(
                dispatch,
                getState,
                null
              );
            }
            push('/');
          }
        });
      }
    });
  };
}

export const GET_SCHOOL_LIST = makeAsyncActionSet('GET_SCHOOL_LIST');
export function getSchoolList() {
  return request(
    GET_SCHOOL_LIST,
    '/api/schools/schools-list/?page=1&page_size=1',
    'GET'
  );
}
export function getSchoolsList() {
  return (dispatch: Dispatch, getState: () => StoreState) => {
    return getSchoolList()(dispatch).then((response) => {
      console.log('we reached', response);
      if (response) {
        const currentSchool: string = response.data.results[0].id;
        Cookies.set(CURRENT_SCHOOL_COOKIE_KEY, currentSchool);
        dispatch(setCurrentSchool(currentSchool));
        getItem('schools/school-switcher', currentSchool)(
          dispatch,
          getState,
          null
        );
      }
    });
  };
}

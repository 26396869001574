import {
  GET_COLLECTION,
  getCollectionByName,
} from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import { Column, Container, ContentBox, Row } from '@dabapps/roe';
import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import { classListConfig } from '^/app/authenticated/classes/list/config';
import { ClassListClass } from '^/app/authenticated/classes/types';
import { AdminList } from '^/chadmin';
import { collections } from '^/common/collections';
import LoadingSpinner from '^/common/loading-spinner';
import { getPageFromQueryString } from '^/common/utils/get-page-from-query-string';
import { StoreState } from '^/store/types';

const {
  actions: { getCollection },
} = collections;

interface StateProps {
  currentSchool: string | null;
  count: number;
  page: number;
  isLoading: boolean;
  requestHasFailed: boolean;
  classes: ReadonlyArray<ClassListClass>;
}

interface DispatchProps {
  getCollection: typeof getCollection;
  push: typeof push;
}

type Props = StateProps & DispatchProps;

const breadcrumbs = [{ label: 'Classes & Groups', path: '' }];

const CLASSES_LIST = 'CLASSES_LIST';

export class Classes extends React.PureComponent<Props> {
  public componentDidMount() {
    this.fetchClasses(this.props.page);
  }

  public componentDidUpdate(previousProps: Props) {
    /*
    NOTE: this is annoying to have to use the lifecycle for this but we
    are keeping the page state in the url so there is not another safe way
    as we need to handle the back and forward events in the browser too
    rather than just our pagination clicks.
    */
    if (this.props.page !== previousProps.page) {
      this.fetchClasses(this.props.page);
    }
  }

  public render() {
    const { isLoading, requestHasFailed, page, classes, count } = this.props;

    return (
      <Container className="margin-top-large">
        <Row>
          <Column md={6}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Column>
          <Column md={6} className="text-align-right">
            <Link to="/classes/add-class" className="button pill">
              Manage Classes or Groups
            </Link>
          </Column>
        </Row>
        <ContentBox>
          {isLoading ? (
            <LoadingSpinner />
          ) : requestHasFailed ? (
            'Failed to load classes list'
          ) : (
            <AdminList
              items={classes}
              columns={classListConfig}
              changePage={this.changePage}
              listName="CLASSES"
              itemCount={count}
              page={page}
            />
          )}
        </ContentBox>
      </Container>
    );
  }

  public changePage = (page: number) => {
    this.props.push({ search: `?page=${page}` });
  };

  public fetchClasses = (page: number) => {
    const { currentSchool } = this.props;
    if (currentSchool) {
      this.props.getCollection(
        'cohorts/class-groups',
        {
          page,
          filters: { school: currentSchool },
        },
        CLASSES_LIST
      );
    }
  };
}

export function mapStateToProps(
  state: StoreState,
  props: RouteComponentProps<any>
): StateProps {
  const classesCollection = getCollectionByName(
    state.collections,
    'cohorts/class-groups',
    CLASSES_LIST
  );
  return {
    currentSchool: state.currentSchool,
    count: classesCollection.count,
    isLoading: isPending(
      state.responses,
      GET_COLLECTION,
      'cohorts/class-groups'
    ),
    requestHasFailed: hasFailed(
      state.responses,
      GET_COLLECTION,
      'cohorts/class-groups'
    ),
    page: getPageFromQueryString(props),
    classes: classesCollection.results as ReadonlyArray<ClassListClass>,
  };
}

export default connect(mapStateToProps, { getCollection, push })(Classes);

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

type Breadcrumb = Readonly<{ label: string; path: string }>;

interface ExternalProps {
  breadcrumbs: ReadonlyArray<Breadcrumb>;
}

export default class Breadcrumbs extends React.PureComponent<ExternalProps> {
  public render() {
    const { breadcrumbs } = this.props;

    return (
      <div className="breadcrumbs">
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={index}>
            {breadcrumbs[index + 1] ? (
              <>
                <Link to={breadcrumb.path}>{breadcrumb.label}</Link>
                <span className="breadcrumbs-arrow"> &gt; </span>
              </>
            ) : (
              <span>{breadcrumb.label}</span>
            )}
          </Fragment>
        ))}
      </div>
    );
  }
}

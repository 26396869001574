import React from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  TooltipProps,
  Legend,
  LegendProps,
} from 'recharts';

import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';
import './graph-container-styles.less';
import { IGenericPieChartGraphProps } from './types';
import { toReverseTitleCase } from '^/common/utils/text-utils';

export const GenericPieGraph: React.FC<IGenericPieChartGraphProps> = ({
  data,
  title,
  valueKey,
  width,
  height,
  handleClick,
}: IGenericPieChartGraphProps) => {
  const renderClick = (param: string, label: string) => {
    switch (param) {
      case 'Gender':
        return {
          type: 'SUMMARY_GENDER',
          selectedType: {
            data: label !== '' ? toReverseTitleCase(label, '_') : 'NON_BINARY',
          },
        };
      case 'SEND':
        return {
          type: 'SUMMARY_SEND',
          selectedType: {
            data: toReverseTitleCase(label, '_'),
          },
        };
      case 'Year Group':
        return {
          type: 'SUMMARY_YEAR_GROUP',
          selectedType: {
            data: label,
          },
        };
      default:
        return {
          type: 'SUMMARY_GENDER',
          selectedType: {
            data: toReverseTitleCase(label, '_'),
          },
        };
    }
  };

  return (
    <div className="generic-pie-chart-container">
      <p className="generic-pie-chart-label" style={{ width: width || '100%' }}>
        {title}
      </p>

      {Array.isArray(data) && data.length ? (
        <ResponsiveContainer height={height || '100%'} width={width || '100%'}>
          <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
            <Pie
              data={data}
              dataKey={valueKey || 'value'}
              stroke=""
              onClick={(e) => {
                // console.log(e);
                const label = e.name || '';
                handleClick ? handleClick(renderClick(title, label)) : null;
              }}
              style={{ cursor: 'pointer' }}
            >
              {data.map((entry, index) => (
                <Cell key={`pie-chart-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip content={<PieChartToolTip />} />
            <Legend
              content={<PieChartLegend />}
              layout="vertical"
              verticalAlign="middle"
              align="right"
            />
          </PieChart>
        </ResponsiveContainer>
      ) : (
        <p className="text-center">{DASHBOARD_PAGE_LABELS.no_data}</p>
      )}
    </div>
  );
};

const PieChartToolTip: React.FC<TooltipProps> = ({
  active,
  payload,
}: TooltipProps) => {
  if (active && Array.isArray(payload)) {
    const { value, name } = payload[0];
    return (
      <div className="tooltip-container">
        <span>
          {name}: {value}
        </span>
      </div>
    );
  }
  return <> </>;
};

const PieChartLegend: React.FC<LegendProps> = ({ payload }: LegendProps) => {
  if (Array.isArray(payload)) {
    return (
      <div className="legend-container">
        {payload.map((data, index) => {
          const { color, value } = data;
          return (
            <div className="legend-item" key={`pie-chart-lengend-${index}`}>
              <div className="circle" style={{ backgroundColor: color }}></div>
              <span className="legend-text">{value}</span>
            </div>
          );
        })}
      </div>
    );
  }
  return <> </>;
};

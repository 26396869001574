import { isPending } from '@dabapps/redux-requests';
import { Container, NavBar, NavItem } from '@dabapps/roe';
import PermissionRequired from '^/common/permissions';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { LOGOUT } from '^/common/authentication/actions';
import LogoutButton from '^/common/authentication/logout-button';
import { LoggedInUser, UserRoles } from '^/common/authentication/types';
import ProfileIcon from '^/common/icons/profile-icon';
import { CurrentSchool } from '^/common/schools/reducers';
import { StoreState } from '^/store/types';
import { RouteConstants } from './route-constants';
import {
  loggedInUserIsClusterAdmin,
  loggedInUserIsTeacher,
  loggedInUserIsClusterManager,
} from './utils/roles';
import { Button } from 'antd';
import { SchoolModal } from '^/app/pages/schools/school-modal';
import { useState } from 'react';
import AddClusterModal from '^/app/pages/clusterManagement/addClusterModal';
import { CLUSTER_ADMIN_LABELS } from '^/common/labels-english';

const MATCHES_ROOT = /^\/$/;
const MATCHES_ACTIVITIES = new RegExp('^' + RouteConstants.overview.route);
const MATCHES_CLASSES = /^\/classes/;
const MATCHES_USERS = /^\/users/;
const MATCHES_SCHOOLS = new RegExp('^' + RouteConstants.schools.route);
const MATCHES_DASHBOARD = new RegExp('^' + RouteConstants.dashboard.route);
const MATCHES_GLOBAL_DASHBOARD = new RegExp('^' + RouteConstants.global.route);
const MATCHES_RECURRING_ACTIVITIES = new RegExp(
  '^' + RouteConstants.recurringActivities.route
);
const MATCHES_CLUSTER = new RegExp('^' + RouteConstants.cluster.route);
const MATCHES_SCHOOL_SETTINGS = new RegExp(
  '^' + RouteConstants.schoolSettings.route
);

interface ExternalProps {
  pathname: string;
}

interface StateProps {
  loggedInUser: LoggedInUser | null;
  currentSchool: CurrentSchool;
  isLoggingOut: boolean;
}

type Props = ExternalProps & StateProps;

function Nav(props: Props) {
  const { loggedInUser, currentSchool, pathname, isLoggingOut } = props;
  const { MIMOVE_ADMIN, TEACHER, CLUSTER_MANAGER, LEAD_TEACHER } = UserRoles;
  const [isSchoolModalOpen, setSchoolModalOpen] = useState(false);
  const [isClusterModalOpen, setClusterModalOpen] = useState(false);

  return (
    <NavBar className="padding-vertical-medium">
      <Container className="d-flex align-items-center">
        <ul className="nav main-nav flex-1">
          {(currentSchool ||
            loggedInUserIsClusterAdmin(loggedInUser) ||
            loggedInUserIsClusterManager(loggedInUser)) && (
            <>
              <NavItem className="nav-logo-link">
                <Link to="/">
                  <img className="nav-logo" src="/static/logo.png" />
                </Link>
              </NavItem>
              <NavItem
                active={
                  MATCHES_ROOT.test(pathname) ||
                  MATCHES_DASHBOARD.test(pathname)
                }
              >
                <Link to={RouteConstants.dashboard.route}>
                  {RouteConstants.dashboard.label}
                </Link>
              </NavItem>
              <PermissionRequired
                hideIfNoPermission
                requiredRoles={[MIMOVE_ADMIN]}
              >
                <NavItem active={MATCHES_GLOBAL_DASHBOARD.test(pathname)}>
                  <Link to={RouteConstants.global.route}>
                    {RouteConstants.global.label}
                  </Link>
                </NavItem>
              </PermissionRequired>
              <PermissionRequired
                hideIfNoPermission
                requiredRoles={[
                  MIMOVE_ADMIN,
                  TEACHER,
                  LEAD_TEACHER,
                  CLUSTER_MANAGER,
                ]}
              >
                <NavItem active={MATCHES_ACTIVITIES.test(pathname)}>
                  <Link to={RouteConstants.overview.route}>
                    {RouteConstants.overview.label}
                  </Link>
                </NavItem>
                <NavItem active={MATCHES_CLASSES.test(pathname)}>
                  <Link to="/classes">Classes &amp; Groups</Link>
                </NavItem>
                <NavItem active={MATCHES_USERS.test(pathname)}>
                  <Link to="/users">Users</Link>
                </NavItem>
                <NavItem active={MATCHES_RECURRING_ACTIVITIES.test(pathname)}>
                  <Link to={RouteConstants.recurringActivities.route}>
                    {RouteConstants.recurringActivities.label}
                  </Link>
                </NavItem>
                <PermissionRequired
                  hideIfNoPermission
                  requiredRoles={[LEAD_TEACHER]}
                >
                  <NavItem active={MATCHES_SCHOOL_SETTINGS.test(pathname)}>
                    <Link to={RouteConstants.schoolSettings.route}>
                      {RouteConstants.schoolSettings.label}
                    </Link>
                  </NavItem>
                </PermissionRequired>
                <NavItem>
                  <Link
                    style={{ color: '#F4511E' }}
                    to={{ pathname: RouteConstants.support.route }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {RouteConstants.support.label}
                  </Link>
                </NavItem>
                {loggedInUserIsClusterManager(loggedInUser) && (
                  <NavItem active={isClusterModalOpen}>
                    <Button
                      type="link"
                      className="p-0 cluster-user-link"
                      onClick={() => setClusterModalOpen(true)}
                    >
                      {CLUSTER_ADMIN_LABELS.navTitle}
                    </Button>
                  </NavItem>
                )}
              </PermissionRequired>
            </>
          )}
          {!isLoggingOut && (
            <PermissionRequired
              hideIfNoPermission
              requiredRoles={[MIMOVE_ADMIN]}
            >
              <NavItem active={MATCHES_SCHOOLS.test(pathname)}>
                <Link to={RouteConstants.schools.route}>Schools</Link>
              </NavItem>
              <NavItem active={MATCHES_CLUSTER.test(pathname)}>
                <Link to={RouteConstants.cluster.route}>
                  {RouteConstants.cluster.label}
                </Link>
              </NavItem>
            </PermissionRequired>
          )}
        </ul>
        {loggedInUser && (
          <div className="nav-profile text-align-right">
            <div className="nav-profile-name">
              {loggedInUser.first_name} {loggedInUser.last_name}
              {loggedInUser.school_name && (
                <>
                  |
                  <Button
                    type="link"
                    className="p-0 school-link"
                    onClick={() => setSchoolModalOpen(true)}
                  >
                    {loggedInUser.school_name}
                  </Button>
                </>
              )}
            </div>
            <div className="nav-profile-buttons">
              <PermissionRequired
                hideIfNoPermission
                requiredRoles={[TEACHER, LEAD_TEACHER]}
              >
                <Link
                  className="padding-right-small"
                  to={`/users/edit-user/${loggedInUser.id}`}
                >
                  <ProfileIcon />
                  Profile
                </Link>
              </PermissionRequired>
              <LogoutButton />
              <span className="padding-left-small font-weight-light">
                {loggedInUser.role_display}
              </span>
            </div>
            {isSchoolModalOpen && (
              <SchoolModal
                id={loggedInUser.school || ''}
                isTeacher={
                  loggedInUserIsTeacher(loggedInUser) ||
                  loggedInUserIsClusterManager(loggedInUser)
                }
                onClose={() => {
                  setSchoolModalOpen(false);
                }}
              />
            )}
            {isClusterModalOpen && (
              <AddClusterModal
                parentTreeID={null}
                clusterID={loggedInUser.cluster_id}
                closeModal={() => {
                  setClusterModalOpen(false);
                }}
                clusterManager={loggedInUser}
              />
            )}
          </div>
        )}
      </Container>
    </NavBar>
  );
}

function mapStateToProps({
  loggedInUser,
  currentSchool,
  responses,
}: StoreState) {
  return {
    loggedInUser,
    currentSchool,
    isLoggingOut: isPending(responses, LOGOUT),
  };
}

export default connect(mapStateToProps)(Nav);

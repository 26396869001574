import { FilterOption } from './types';

export const ADD_FILTER_OPTION = 'ADD_FILTER_OPTION';
export const REMOVE_FILTER_OPTION = 'REMOVE_FILTER_OPTION';
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE';

function changeFilterOption(
  type: string,
  filter: string,
  option: FilterOption
) {
  return {
    type,
    payload: {
      filter,
      option,
    },
  };
}

export function addFilterOption(filter: string, option: FilterOption) {
  return changeFilterOption(ADD_FILTER_OPTION, filter, option);
}

export function removeFilterOption(filter: string, option: FilterOption) {
  return changeFilterOption(REMOVE_FILTER_OPTION, filter, option);
}

export function updateSearchValue(searchQuery: string) {
  return {
    type: UPDATE_SEARCH_VALUE,
    payload: { searchQuery },
  };
}

import * as React from 'react';

interface ExternalProps {
  text?: string;
}

const ErrorMessage = (props: ExternalProps) => {
  const errorMessage =
    props.text ||
    'Sorry, something has gone wrong. Please refresh the page or try again later.';
  return <p className="error">{errorMessage}</p>;
};

export default ErrorMessage;

import { SimpleRecord } from '@dabapps/simple-records';

export interface SchoolSettingsList {
  id: string;
  year_group: string;
  own_class: boolean;
  own_year: boolean;
  own_groups: boolean;
  all: boolean;
}

export const SchoolSettingsList = SimpleRecord<SchoolSettingsList>({
  id: '',
  year_group: '',
  own_class: false,
  own_year: false,
  own_groups: false,
  all: true,
});

import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import React, { useEffect } from 'react';
import useAPI from '^/api-services/useApi';

import './activities-table-styles.less';
import ProfileApi from '^/api-services/profile/profile.service';
import { List, Space } from 'antd';
import Text from 'antd/lib/typography/Text';
import { AboutYouIdentifier } from '^/api-services/profile/types';
import { ActivitiesNames, DayOptions } from '^/common/activities-constants';
import './styles.less';
interface Props {
  userId: string;
}

export const Profile: React.FC<Props> = (props: Props) => {
  const { callAPI: getStudentProfile, ...profileResponse } = useAPI(
    ProfileApi.getStudentProfile
  );

  useEffect(() => {
    if (props.userId) {
      getStudentProfile(props.userId);
    }
  }, [props.userId]);

  const { response, loading, error } = profileResponse;

  return loading || !response ? (
    <LoadingSpinner />
  ) : error ? (
    <div className="padding-vertical-large">
      <ErrorMessage />
    </div>
  ) : (
    <List
      className="student-profile"
      itemLayout="horizontal"
      dataSource={response.results}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta description={item.question.text} />
          <Space direction="vertical">
            {item.choices.map((answer) => {
              if (item.question.identifier === AboutYouIdentifier.ACTIVITY) {
                let activityName = ActivitiesNames.find(
                  (activity) => activity.type === answer.text
                )?.label;
                activityName = activityName ? activityName : answer.text;
                return <Text>{activityName}</Text>;
              } else if (item.question.identifier === AboutYouIdentifier.WHEN) {
                const day = DayOptions.find((day) => day.id == answer.text)
                  ?.name;
                return <Text>{day ? day : answer.text}</Text>;
              } else {
                return <Text>{answer.text}</Text>;
              }
            })}
          </Space>
        </List.Item>
      )}
    />
  );
};

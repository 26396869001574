import * as React from 'react';

import { Column, Row } from '@dabapps/roe';
import { TRows } from '../types';

export interface IProps<T> {
  items: TRows<T>;
  changePage: (pageNumber: number) => void;
  pageSize: number;
  page: number;
  itemCount: number;
}

export default class AdminListPagination<T> extends React.PureComponent<
  IProps<T>,
  {}
> {
  public render() {
    const { pageSize, page, itemCount, items } = this.props;

    const loadedItemsCount = items ? items.length : 0;
    const firstItemNumber =
      loadedItemsCount === 0 ? 0 : (page - 1) * pageSize + 1;
    const lastItemNumber = Math.max(
      firstItemNumber + Math.min(pageSize, loadedItemsCount) - 1,
      0
    );

    const numberOfPages = Math.ceil(itemCount / pageSize);
    const isFirstPage = page === 1;
    const isLastPage = numberOfPages === 0 || page === numberOfPages;

    return (
      <Row className="pagination">
        <Column xs={12}>
          <div className="margin-vertical-base">
            <p>
              Showing {firstItemNumber} to {lastItemNumber} of {itemCount}{' '}
              entries.
            </p>
          </div>
        </Column>
        <Column xs={12}>
          <div className="margin-vertical-base">
            {this.renderPaginationLink(1, '<< First', isFirstPage)}
            {this.renderPaginationLink(page - 1, '< Previous', isFirstPage)}
            {this.renderPageList(page, numberOfPages)}
            {this.renderPaginationLink(page + 1, 'Next >', isLastPage)}
            {this.renderPaginationLink(numberOfPages, 'Last >>', isLastPage)}
          </div>
        </Column>
      </Row>
    );
  }

  private getPaginationLinkClassName(
    isActive: boolean,
    isDisabled: boolean | undefined
  ) {
    if (isActive) {
      return 'active button';
    }
    return isDisabled ? 'disabled button' : 'button';
  }

  private changePage(page: number, activeLink: boolean) {
    if (activeLink) {
      this.props.changePage(page);
    }
  }

  private renderPaginationLink(
    page: number,
    name: number | string,
    isDisabled: boolean | undefined = false,
    isActive: boolean | undefined = false,
    key: number | string | undefined = name
  ) {
    const className = this.getPaginationLinkClassName(isActive, isDisabled);
    return (
      <a
        key={key}
        className={className}
        /* tslint:disable-next-line:jsx-no-lambda */
        onClick={() => this.changePage(page, !isActive && !isDisabled)}
      >
        {name}
      </a>
    );
  }

  private getPageListStartPageNumber(
    activePage: number,
    numberOfPages: number,
    numberOfPagesToRender: number
  ) {
    const idealStartPage = Math.max(
      activePage - Math.floor(numberOfPagesToRender / 2),
      1
    );
    return idealStartPage + numberOfPagesToRender - 1 <= numberOfPages
      ? idealStartPage
      : Math.max(1, numberOfPages - numberOfPagesToRender + 1);
  }

  private renderPageList(activePage: number, numberOfPages: number) {
    const pageListStartPageNumber = this.getPageListStartPageNumber(
      activePage,
      numberOfPages,
      5
    );
    return [0, 1, 2, 3, 4].map((idx: number) => {
      const pageNumber = pageListStartPageNumber + idx;
      return (
        pageNumber <= numberOfPages &&
        this.renderPaginationLink(
          pageNumber,
          pageNumber,
          false,
          pageNumber === activePage
        )
      );
    });
  }
}

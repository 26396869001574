import * as React from 'react';

import Select from 'react-select';
import FieldGroup from '../FieldGroup';
import { TChoice, TChoices, TWrappedFieldProps } from './types';
import { getChoiceLabel, isMultipleChoiceValue } from './utils';

/* Displays a multi-select with choices passed in.
 * Similar to FieldSelect. The choices should be passed
 * in the form:
 *  { value: <uuid>, display_name: <whatever string to display> }
 *
 * The field will set an array of UUIDs in onChange to post to the endpoint.
*/

export default class FieldMany extends React.PureComponent<
  React.HTMLProps<JSX.Element> & TWrappedFieldProps,
  {}
> {
  public render() {
    const { readOnly, config, name, errors, value } = this.props;

    const { choices = [], placeholder, clearable = true } = config;

    if (readOnly) {
      // find matching choices and transform to display names
      const chosenItems =
        choices &&
        choices
          .filter(choice => value.indexOf(choice.value) !== -1)
          .map(choice => choice.display_name);

      return (
        <FieldGroup config={config} errors={errors}>
          <ul className="input readonly-many-list">
            {chosenItems && chosenItems.map(item => <li key={item}>{item}</li>)}
          </ul>
        </FieldGroup>
      );
    }

    const options = [...choices];

    return (
      <FieldGroup config={config} errors={errors}>
        <Select
          isClearable={clearable}
          className="select"
          getOptionLabel={getChoiceLabel}
          options={options}
          value={this.getValue()}
          name={name}
          isMulti
          placeholder={placeholder}
          onChange={this.onChange}
        />
      </FieldGroup>
    );
  }

  private getValue = () => {
    const {
      value,
      config: { choices = [] },
    } = this.props;
    return choices.filter(choice => value.indexOf(choice.value) !== -1);
  };

  private onChange = (value: TChoice | TChoices | null | undefined) => {
    if (!value) {
      return this.props.onChange(value);
    } else if (isMultipleChoiceValue(value)) {
      return this.props.onChange(value.map(each => each.value));
    } else {
      return this.props.onChange([value.value]);
    }
  };
}

import classNames from 'classnames';
import * as React from 'react';

export interface IProps {
  className?: string;
}

export default class Loading extends React.PureComponent<IProps, {}> {
  public render() {
    const divClass = classNames('indicator', 'loading', this.props.className);
    return (
      <div className={divClass}>
        <svg width="36" height="36" viewBox="0 0 36 36">
          <circle cx="18" cy="18" r="17" strokeWidth="2" fill="none" />
          <circle cx="18" cy="18" r="11" strokeWidth="2" fill="none" />
        </svg>
      </div>
    );
  }
}

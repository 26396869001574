export const toTitleCase = (str: string, delimiter: string) => {
  return str
    .split(delimiter)
    .map((ele) => ele.charAt(0).toUpperCase() + ele.substr(1).toLowerCase())
    .join(' ');
};
export const toReverseTitleCase = (str: string, delimiter: string) => {
  return str
    .split(' ')
    .map((ele) => ele.toUpperCase())
    .join(delimiter);
};

export const toSentenceCase = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
};

// WIP indian system remove if not needed
export const toReadableNumberIndian = (val: number) => {
  const SI_SYMBOL = ['', 'k', 'L', 'C'];
  const tier = (Math.log10(val) / 3) | 0;
  // if (val.toString().length >= 6) {
  //   tier = (Math.log10(val) / 2) | 0;
  // }
  if (tier == 0) return val.toString();
  const suffix = SI_SYMBOL[tier];
  const length = val.toString().length;
  const power = length % 2 === 0 ? length - 1 : length - 2;
  const scale = Math.pow(10, power);
  const scaled = val / scale;
  return +scaled.toFixed(1) + suffix;
};

// International system
export const toReadableNumber = (val: number) => {
  const SI_SYMBOL = ['', 'k', 'M'];
  const tier = (Math.log10(val) / 3) | 0;
  if (tier == 0) return val.toString();
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = val / scale;
  return +scaled.toFixed(1) + suffix;
};

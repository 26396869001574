import React, { ReactNode } from 'react';
import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

import './modal-popup-styles.less';

interface Props extends ModalProps {
  children: ReactNode;
}

export const ModalPopup: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <Modal className="antd-modal-container" {...props} centered={true}>
      <div>{children}</div>
    </Modal>
  );
};

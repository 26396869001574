import {
  GET_COLLECTION,
  getCollectionByName,
} from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import { Column, Container, ContentBox } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { formValueSelector } from 'redux-form';

import {
  GET_ITEM,
  getItemByName,
} from '@dabapps/redux-api-collections/dist/items';
import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import {
  createUserFormConfig,
  createUserFormFields,
} from '^/app/authenticated/users/user-management/config';
import {
  EDIT_USER,
  editUser,
  editUserAndLoadUser,
} from '^/app/authenticated/users/user-management/edit-user/actions';
import {
  ClassGroupOption,
  EditCreateUser,
} from '^/app/authenticated/users/user-management/types';
import { isLoggedInUser } from '^/app/authenticated/users/user-management/utils';
import { AdminEditCreate, FormErrors } from '^/chadmin';
import { LoggedInUser, ROLES } from '^/common/authentication/types';
import { collections } from '^/common/collections';
import LoadingSpinner from '^/common/loading-spinner';
import { convertBooleanChoices } from '^/common/utils/convert-boolean-choices';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { loggedInUserIsAdmin } from '^/common/utils/roles';
import { StoreState } from '^/store/types';
import { RouteComponentProps } from 'react-router';
import CommonApis from '^/api-services/common/common.service';
import { SelectOption } from '^/app/components';
import { BasicSchool } from '^/app/authenticated/school-switcher/types';

const {
  actions: { getCollection, getItem },
} = collections;

interface DispatchProps {
  getCollection: typeof getCollection;
  getItem: typeof getItem;
  editUser: typeof editUser;
  editUserAndLoadUser: typeof editUser;
}

interface StateProps {
  classGroups: ReadonlyArray<ClassGroupOption>;
  currentSchool: string | null;
  selectedRole?: ROLES;
  hasFailedRequest: boolean;
  initialValues: Partial<EditCreateUser>;
  errors?: ResponseErrors;
  isLoading: boolean;
  editUserIsPending: boolean;
  loggedInUser: LoggedInUser | null;
  currentSchoolData?: BasicSchool;
}

export interface RouteParamProps {
  userId: string;
}

type Props = DispatchProps & StateProps & RouteComponentProps<RouteParamProps>;

interface IState {
  yearGroups: SelectOption[];
  isSSO: boolean;
}

const breadcrumbs = (isEditingSelf: boolean) => [
  { label: 'Users', path: '/users' },
  { label: isEditingSelf ? 'Profile' : 'Edit User', path: '' },
];

const setPendingUploadInForm = () => null;

export class EditUser extends React.PureComponent<Props, IState> {
  // class variable to store initial yeargroups from BE
  initialYearGroup: SelectOption[] = [];
  constructor(props: Props) {
    super(props);
    this.state = {
      yearGroups: [],
      isSSO: false,
    };
  }
  public componentDidMount() {
    const userId = this.props.match.params.userId;
    const currentSchool = this.props.currentSchool;

    if (currentSchool) {
      this.fetchClassGroups(currentSchool);
      this.fetchYearGroups(currentSchool);
      if (userId) {
        this.fetchInitialData(userId);
      }
      this.fetchCurrentSchool(this.props.currentSchool);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.currentSchoolData != prevProps.currentSchoolData) {
      this.setState({ isSSO: this.props.currentSchoolData?.sso != 'NONE' });
    }
  }

  fetchCurrentSchool = async (school: string) => {
    this.props.getItem('schools/school-switcher', school);
  };

  fetchYearGroups = async (school: string) => {
    const resp = await CommonApis.getYearGroups({
      school,
    });
    this.setState({
      yearGroups: [...resp.results],
    });
    this.initialYearGroup = [...resp.results];
  };

  addYearGroup = (newYearGroup: SelectOption, cb: () => void) => {
    this.setState({
      yearGroups: [...this.initialYearGroup, newYearGroup],
    });
    return cb();
  };

  public render() {
    const {
      classGroups,
      selectedRole,
      hasFailedRequest,
      initialValues,
      errors,
      isLoading,
      editUserIsPending,
      loggedInUser,
    } = this.props;
    const userId = this.props.match.params.userId;
    const isEditingSelf = isLoggedInUser(loggedInUser, userId);
    const { yearGroups } = this.state;

    return (
      <Container className="margin-top-large">
        <Breadcrumbs breadcrumbs={breadcrumbs(isEditingSelf)} />
        <Column sm={10} md={8} lg={6} smPush={1} mdPush={2} lgPush={3}>
          <ContentBox className="position-relative">
            {isLoading ? (
              <div className="padding-vertical-large">
                <LoadingSpinner />
              </div>
            ) : isEditingSelf && loggedInUserIsAdmin(loggedInUser) ? (
              <p>Admin users cannot edit their own profile.</p>
            ) : (
              <>
                <AdminEditCreate
                  item={convertBooleanChoices(initialValues)}
                  patchItem={this.editUser}
                  fields={createUserFormFields(
                    selectedRole,
                    isEditingSelf,
                    this.state.isSSO
                  )}
                  itemOptions={createUserFormConfig(
                    classGroups,
                    yearGroups,
                    this.addYearGroup,
                    undefined,
                    selectedRole,
                    loggedInUser
                  )}
                  formName={userId}
                  setPendingUploadInForm={setPendingUploadInForm}
                  saveButtonText={'Save'}
                  itemErrors={errors as FormErrors}
                  itemHasFailed={hasFailedRequest}
                  itemIsPending={editUserIsPending}
                />
                {isEditingSelf && (
                  <Link
                    className="change-password-link"
                    to={`/users/edit-user/${userId}/change-password`}
                  >
                    Change Password
                  </Link>
                )}
              </>
            )}
          </ContentBox>
        </Column>
      </Container>
    );
  }

  public editUser = async (data: Partial<EditCreateUser>) => {
    const isEditingSelf = isLoggedInUser(
      this.props.loggedInUser,
      this.props.match.params.userId
    );
    const { currentSchool } = this.props;
    if (isEditingSelf) {
      const { first_name, last_name, username, id } = data;
      this.props.editUserAndLoadUser({ first_name, last_name, username, id });
    } else {
      const yearGroupID = data.year_group;
      if (yearGroupID && currentSchool) {
        const yearGroupIndex = this.initialYearGroup.findIndex(
          (ele) => ele.id === yearGroupID || ele.name === yearGroupID
        );
        if (yearGroupIndex === -1) {
          // create year group object in BE
          const resp = await CommonApis.createYearGroups(
            yearGroupID,
            currentSchool
          );
          if (resp) {
            data = { ...data, year_group: resp.id };
            this.initialYearGroup.push({ id: resp.id, name: resp.name });
          }
        } else
          data = {
            ...data,
            year_group: this.initialYearGroup[yearGroupIndex].id,
          };
      }
      this.props.editUser(data);
    }
  };

  /*
  NOTE: Currently, these methods are being used to retrieve all of the objects
  from their respective endpoints. Later on, this should be moved to a search
  in order to improve performance and allow schools to have more than 100
  classes.
  */

  public fetchClassGroups = (school: string) => {
    const userId = this.props.match.params.userId;

    this.props.getCollection(
      'cohorts/class-group-options',
      {
        filters: { school },
        pageSize: 100,
      },
      userId
    );
  };

  private fetchInitialData = (user: string) => {
    if (this.props.currentSchool) {
      this.props.getItem('users', user);
    }
  };
}

function mapStateToProps(state: StoreState, ownProps: Props): StateProps {
  const classGroupCollection = getCollectionByName(
    state.collections,
    'cohorts/class-group-options',
    ownProps.match.params.userId
  );
  const initialValues = getItemByName(state.items, 'users') as Partial<
    EditCreateUser
  >;
  const classGroupRequestHasFailed = hasFailed(
    state.responses,
    GET_COLLECTION,
    'cohorts/class-group'
  );
  const initialValuesRequestHasFailed = hasFailed(
    state.responses,
    GET_ITEM,
    'users'
  );

  return {
    loggedInUser: state.loggedInUser,
    currentSchool: state.currentSchool,
    selectedRole: formValueSelector(ownProps.match.params.userId)(
      state,
      'role'
    ),
    classGroups: classGroupCollection.results as ReadonlyArray<
      ClassGroupOption
    >,
    hasFailedRequest:
      classGroupRequestHasFailed || initialValuesRequestHasFailed,
    initialValues,
    isLoading: isPending(state.responses, GET_ITEM, 'users'),
    editUserIsPending: isPending(state.responses, EDIT_USER),
    errors: getResponseErrors(state.responses, EDIT_USER),
    currentSchoolData: getItemByName(
      state.items,
      'schools/school-switcher'
    ) as BasicSchool,
  };
}

export default connect(mapStateToProps, {
  getCollection,
  getItem,
  editUser,
  editUserAndLoadUser,
})(EditUser);

import * as Cookies from 'js-cookie';

import { SchoolOption } from '^/app/authenticated/school-switcher/types';
import { CURRENT_SCHOOL_COOKIE_KEY } from '^/common/constants';

export function isSingularSchoolOption(
  value: SchoolOption | SchoolOption[]
): value is SchoolOption {
  return !Array.isArray(value);
}

export function setCurrentSchoolAndReload(currentSchool: string) {
  Cookies.set(CURRENT_SCHOOL_COOKIE_KEY, currentSchool);
  window.location.replace('/'); // NOTE: we want the app to remount and reload all data
}

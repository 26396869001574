import { SimpleRecord } from '@dabapps/simple-records';

export interface BasicUser {
  readonly id: string;
  readonly username: string;
}
export const BasicUser = SimpleRecord<BasicUser>({
  id: '',
  username: '',
});

export interface AddClassUserListUser {
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
}
export const AddClassUserListUser = SimpleRecord<AddClassUserListUser>({
  id: '',
  first_name: '',
  last_name: '',
});

export interface UserListUser {
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly role_display: string;
  readonly created: string;
  readonly role: string;
}
export const UserListUser = SimpleRecord<UserListUser>({
  id: '',
  first_name: '',
  last_name: '',
  role_display: '',
  created: '',
  role: '',
});

// new types
export interface IGetUsersRequest extends IActivityFilters {
  pageNumber: number;
  school: string;
  pageSize?: number;
  search?: string;
}

export interface IActivityFilters {
  gender: string;
  is_send_registered: string;
  ethnicity: string;
  is_eal: string;
  latest_assessment__status: string;
  is_pupil_premium: string;
}

export interface IGetUserResponse<T = IUserGeneric> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export interface IUserGeneric {
  id: string;
  first_name: string;
  last_name: string;
  role_display: string;
  created: string;
}

export interface IUserStudent {
  id: string;
  first_name: string;
  last_name: string;
  class_group_name: string;
  latest_assessment_status: string;
  latest_activity?: Latestactivity;
  activity_count: number;
  total_activity_minutes?: number;
}
interface Latestactivity {
  activity: string;
  other_activity_type?: any;
  duration_minutes: number;
  date: string;
}

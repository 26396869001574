import { StatsPeriod } from '^/app/authenticated/activities/overview/stats/types';
import moment from 'moment';

export function formatPeriod(year: number, month: number) {
  return `${year}-${month < 10 ? `0${month}` : month}`;
}

export function getStatsPeriodFromMoment(date: moment.Moment): StatsPeriod {
  const month = date.month() + 1; // NOTE: as moment months are indexes
  return {
    year: date.year(),
    month,
    periodString: formatPeriod(date.year(), month),
  };
}

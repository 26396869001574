import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { EditCreateUser } from '^/app/authenticated/users/user-management/types';
import { getLoggedInUser } from '^/common/authentication/actions';
import { isSuccessResponse } from '^/common/utils/responses';

export const EDIT_USER = makeAsyncActionSet('EDIT_USER');
export function editUser(data: Partial<EditCreateUser>) {
  return (dispatch: Dispatch) => {
    return request(EDIT_USER, `/api/users/${data.id}/`, 'PATCH', data)(
      dispatch
    ).then(response => {
      if (response && isSuccessResponse(response)) {
        dispatch(push('/users'));
        toast.success(`User ${data.username} updated`);
      }
    });
  };
}

export function editUserAndLoadUser(data: Partial<EditCreateUser>) {
  return (dispatch: Dispatch) => {
    return editUser(data)(dispatch).then(() => {
      getLoggedInUser()(dispatch);
    });
  };
}

import { Button, Column, Container, Row, SpacedGroup } from '@dabapps/roe';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button as AntdButton, Tooltip } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { IActivityPageUserDetail } from '^/api-services/users/types';

import AssessmentModal from '^/app/authenticated/assessments/assessment-modal';
import MessageModal from '^/app/authenticated/messaging/message-modal';
import {
  SelectFilter,
  SelectOption,
} from '^/app/components/filters/select-filter';
import { UserRoles } from '^/common/authentication/types';
import { openModal } from '^/common/modals/actions';
import PermissionRequired from '^/common/permissions';
import { IUserStudent } from '../../users/types';
import { ICON_COLOR } from '^/common/colors';
import { ACTIVITY_NAVIGATION } from '.';
import { ACTIVITY_PAGE_NAVIGATION } from '^/common/labels-english';
import { PAGE_SIZE } from '^/app/authenticated/activities/activity-page/messages-table';

interface ExternalProps {
  userDetail: IActivityPageUserDetail;
  students: IUserStudent[];
  onStudentDropdownChange: (val: string) => void;
  selectedStudentID: string;
  navigateStudentList: (direction: ACTIVITY_NAVIGATION) => void;
  totalCount: number;
  currentPage: number;
  onPageChange: (val: number) => void;
  onSearch: (val: string) => void;
  resetOptions: () => void;
  searchFieldVisibility: boolean;
}

interface DispatchProps {
  openModal: typeof openModal;
}

type Props = ExternalProps & DispatchProps;

export class Title extends React.PureComponent<Props> {
  mapStudentToOptions = () => {
    const options: SelectOption[] = [];
    (this.props.students || []).forEach((ele) => {
      options.push({
        name: `${ele.first_name} ${ele.last_name}`,
        id: ele.id,
      });
    });
    return options;
  };

  iconStyle = {
    color: ICON_COLOR,
    margin: 'auto',
    fontSize: 18,
  };

  computeDropdownWidth = () => {
    // Trial and error method
    const perWidth = 35;
    if (Math.ceil(this.props.totalCount / PAGE_SIZE) <= 6) {
      return 7 * perWidth;
    }
    return 9 * perWidth;
  };

  public render() {
    const {
      userDetail,
      onStudentDropdownChange,
      selectedStudentID,
      navigateStudentList,
      totalCount,
      currentPage,
      onPageChange,
      onSearch,
      resetOptions,
      students,
      searchFieldVisibility,
    } = this.props;
    return (
      <Container>
        <div className="padding-vertical-large">
          <Row>
            <Column sm={12} md={4}>
              <h2 className="font-size-large line-height-large margin-vertical-none no-wrap">
                <span>
                  {`${userDetail.first_name} ${userDetail.last_name}`.toUpperCase()}
                </span>
                {/* <span className="margin-left-small font-color-grey-light">
                  ASSESSMENT AND ACTIVITIES
                </span> */}
              </h2>
            </Column>
            <Column sm={12} md={3} className="d-flex">
              {searchFieldVisibility && (
                <div className="d-flex align-items-center">
                  <Tooltip title={ACTIVITY_PAGE_NAVIGATION.previousStudent}>
                    <AntdButton
                      type="link"
                      icon={<LeftOutlined style={this.iconStyle} />}
                      onClick={() =>
                        navigateStudentList(ACTIVITY_NAVIGATION.PREVIOUS)
                      }
                      disabled={
                        currentPage === 1 &&
                        (students || []).findIndex(
                          (ele) => ele.id === selectedStudentID
                        ) === 0
                      }
                    ></AntdButton>
                  </Tooltip>
                  <SelectFilter
                    className="dropdown-paginated-container"
                    style={{ minWidth: '14em' }}
                    dropdownMatchSelectWidth={this.computeDropdownWidth()}
                    optionList={this.mapStudentToOptions()}
                    totalCount={totalCount}
                    currentPageNumber={currentPage}
                    onPageChange={onPageChange}
                    onChange={(data) => onStudentDropdownChange(data)}
                    value={selectedStudentID}
                    showSearch
                    onSearch={onSearch}
                    paginationFooter={ACTIVITY_PAGE_NAVIGATION.filterApplied}
                    onClear={resetOptions}
                  />
                  <Tooltip title={ACTIVITY_PAGE_NAVIGATION.nextStudent}>
                    <AntdButton
                      type="link"
                      onClick={() =>
                        navigateStudentList(ACTIVITY_NAVIGATION.NEXT)
                      }
                      icon={<RightOutlined style={this.iconStyle} />}
                      disabled={
                        currentPage === Math.ceil(totalCount / PAGE_SIZE) &&
                        (students || []).findIndex(
                          (ele) => ele.id === selectedStudentID
                        ) ===
                          (students.length || PAGE_SIZE) - 1
                      }
                    ></AntdButton>
                  </Tooltip>
                </div>
              )}
            </Column>
            <Column sm={12} md={5} className="text-align-right">
              <SpacedGroup className="d-block">
                <Link
                  to={`/users/edit-user/${userDetail.id}`}
                  className="button pill hollow"
                >
                  Edit user
                </Link>
                <PermissionRequired
                  hideIfNoPermission
                  requiredRoles={[UserRoles.TEACHER, UserRoles.LEAD_TEACHER]}
                >
                  <Button
                    onClick={this.openMessageModal}
                    className="pill hollow"
                  >
                    Message
                  </Button>
                  <Button onClick={this.openAssessmentModal} className="pill">
                    Assess
                  </Button>
                </PermissionRequired>
              </SpacedGroup>
            </Column>
          </Row>
        </div>
      </Container>
    );
  }

  private openAssessmentModal = () => {
    const { userDetail } = this.props;
    if (userDetail) {
      const studentName = `${userDetail.first_name} ${userDetail.last_name}`;
      this.props.openModal(
        <AssessmentModal
          studentId={userDetail.id}
          studentName={studentName}
          isActivityPage
        />
      );
    }
  };

  private openMessageModal = () => {
    const { userDetail } = this.props;
    if (userDetail) {
      const studentName = `${userDetail.first_name} ${userDetail.last_name}`;
      this.props.openModal(
        <MessageModal studentName={studentName} studentIds={[userDetail.id]} />
      );
    }
  };
}

export default connect(undefined, {
  openModal,
})(Title);

import React from 'react';
import { CloseCircleFilled } from '@ant-design/icons';

import { FilterOption } from './types';

interface Props {
  option: FilterOption;
  filter: string;
  onRemove(filter: string, option: FilterOption): void;
}

export default class SelectedFilter extends React.PureComponent<Props> {
  public render() {
    const { option } = this.props;
    return (
      <div className="selected-filter" onClick={this.onRemove}>
        <span className="filter-title">{option.label}</span>{' '}
        <CloseCircleFilled className="icon small" />
      </div>
    );
  }

  private onRemove = () => {
    const { filter, option } = this.props;
    this.props.onRemove(filter, option);
  };
}

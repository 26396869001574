import { Checkbox, Col, DatePicker, Form, Input, Row, Switch } from 'antd';
import moment, { Moment } from 'antd/node_modules/moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ActivityApi from '^/api-services/activities/activities.service';
import { IAddActivityRequest } from '^/api-services/activities/types';
import useAPI from '^/api-services/useApi';

import { ModalPopup, SelectFilter, SelectOption } from '^/app/components';
import {
  ActivitiesNames,
  ActivityLocationNames,
  ActivityTimeOptions,
  DayOptions,
} from '^/common/activities-constants';
import { DATE_RANGE_FORMAT } from '^/common/constants';
import { ADD_ACTIVITY_LABELS } from '^/common/labels-english';

import './add-activity-modal-styles.less';

type Props = {
  studentIds: string[];
  onClose: () => void;
};
export const AddActivityModal: React.FC<Props> = (props: Props) => {
  const activityList = () => {
    return ActivitiesNames.map((activity) => {
      return {
        id: activity.type,
        name: activity.label,
      };
    });
  };
  const timeOptions = (): SelectOption[] => {
    return ActivityTimeOptions.map((time) => {
      return {
        id: time.duration,
        name: time.label,
      };
    });
  };
  const locationOptions = (): SelectOption[] => {
    return ActivityLocationNames.map((location) => {
      return {
        id: location.type,
        name: location.label,
      };
    });
  };
  const [recurringDates, setRecurringDates] = useState<string[]>([]);
  const [form] = Form.useForm();

  // disable dates prior to 3 months and after 3 months
  const disabledDate = (current: Moment, isEndDate?: boolean) => {
    return (
      current < moment(moment().subtract(3, 'M').toDate(), DATE_RANGE_FORMAT) ||
      current > moment(moment().add(3, 'M').toDate(), DATE_RANGE_FORMAT) ||
      (isEndDate &&
        form.getFieldValue('date') &&
        current <= form.getFieldValue('date'))
    );
  };

  const generateRecurringDates = () => {
    const days = form.getFieldValue('recurringDays');
    const startDate = form.getFieldValue('date');
    const endDate = form.getFieldValue('recurringEndDate');
    const dates = [];
    if (days?.length && startDate && endDate) {
      let date = new Date(startDate);
      let currentWeek = true;
      while (date <= new Date(endDate)) {
        for (const day of days.sort()) {
          // consider the upcoming days of the current week
          if (currentWeek && parseInt(day) <= date.getDay()) {
            continue;
          }
          if (date <= new Date(endDate)) {
            dates.push(date.toDateString());
          } else {
            break;
          }
          const next = (7 + parseInt(day) - date.getDay()) % 7;
          date = new Date(date.setDate(date.getDate() + (next ? next : 7)));
        }
        currentWeek = false;
      }
    }
    setRecurringDates(dates);
    form.setFieldsValue({ ['recurringDates']: dates });
  };

  const [isModalOpen, setIsModalOpen] = useState(true);

  const labels = ADD_ACTIVITY_LABELS.formLabels;
  const { callAPI: addActivity, ...addActivityResponse } = useAPI<
    IAddActivityRequest,
    IAddActivityRequest
  >(ActivityApi.addActivity);

  const onFormSubmit = (values: any) => {
    addActivity({
      students: props.studentIds,
      activity: values.activity,
      location: values.location,
      duration_minutes: values.duration,
      other_activity_type: values.otherActivity,
      recurring_dates: values.recurringDates
        ? values.recurringDates
        : [values.date],
    });
  };

  useEffect(() => {
    if (addActivityResponse.response) {
      toast.success(ADD_ACTIVITY_LABELS.successMsg);
      setIsModalOpen(false);
      props.onClose();
    }
  }, [addActivityResponse.response]);

  return (
    <ModalPopup
      title={ADD_ACTIVITY_LABELS.title(props.studentIds.length)}
      visible={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        props.onClose();
      }}
      okText={ADD_ACTIVITY_LABELS.submitBtn}
      onOk={() => form.submit()}
      confirmLoading={addActivityResponse.loading}
    >
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        colon={false}
        labelAlign="left"
        validateMessages={{ required: ADD_ACTIVITY_LABELS.required }}
        onFinish={onFormSubmit}
      >
        <Form.Item
          name="activity"
          label={labels.activity}
          rules={[{ required: true }]}
        >
          <SelectFilter bordered showSearch optionList={activityList()} />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.activity !== currentValues.activity
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue('activity') ===
              ActivitiesNames[ActivitiesNames.length - 1].type ? (
              <Form.Item
                name="otherActivity"
                label={labels.otherActivity}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            ) : null;
          }}
        </Form.Item>
        <Form.Item
          name="duration"
          label={labels.duration}
          rules={[{ required: true }]}
        >
          <SelectFilter bordered showSearch optionList={timeOptions()} />
        </Form.Item>
        <Form.Item
          name="location"
          label={labels.location}
          rules={[{ required: true }]}
        >
          <SelectFilter bordered showSearch optionList={locationOptions()} />
        </Form.Item>
        <Form.Item name="date" label={labels.date} rules={[{ required: true }]}>
          <DatePicker
            onChange={() => generateRecurringDates()}
            format={DATE_RANGE_FORMAT}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          name="isRecurring"
          label={labels.isRecurring}
          labelCol={{ span: 0 }}
          wrapperCol={{ span: 0 }}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.isRecurring !== currentValues.isRecurring
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue('isRecurring') ? (
              <>
                <Form.Item
                  name="recurringDays"
                  label={labels.recurringDays}
                  rules={[{ required: true, type: 'array' }]}
                >
                  <SelectFilter
                    mode="multiple"
                    bordered
                    optionList={DayOptions}
                    onChange={() => generateRecurringDates()}
                  />
                </Form.Item>
                <Form.Item
                  name="recurringEndDate"
                  label={labels.recurringEndDate}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    onChange={() => generateRecurringDates()}
                    format={DATE_RANGE_FORMAT}
                    disabledDate={(value) => disabledDate(value, true)}
                    showToday={false}
                  />
                </Form.Item>
              </>
            ) : null;
          }}
        </Form.Item>
        {recurringDates.length ? (
          <Form.Item
            name="recurringDates"
            className="mb-0"
            wrapperCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <Row>
                {recurringDates.map((date) => (
                  <Col span={8} key={date}>
                    <Checkbox value={date}>{date}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        ) : null}
      </Form>
    </ModalPopup>
  );
};

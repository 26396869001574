import React from 'react';
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  TooltipProps,
  Legend,
  LegendProps,
} from 'recharts';
import { ActivityLocationNames } from '^/common/activities-constants';

import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';
import './graph-container-styles.less';
import { IActivityLocationGraphProps } from './types';

export const ActivityLocationsPieGraph: React.FC<IActivityLocationGraphProps> = ({
  data,
  handleClick,
}: IActivityLocationGraphProps) => {
  return (
    <>
      <div className="pie-chart-container">
        {Array.isArray(data) && data.length ? (
          <ResponsiveContainer height="100%" width="100%">
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                stroke=""
                onClick={(e) =>
                  handleClick
                    ? handleClick({
                        type: 'ACTIVITY_LOCATION',
                        selectedType: { value: e.name },
                      })
                    : null
                }
                style={{ cursor: 'pointer' }}
              >
                {data.map((entry, index) => (
                  <Cell key={`pie-chart-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip content={<PieChartToolTip />} />
              <Legend
                content={<PieChartLegend />}
                layout="vertical"
                verticalAlign="middle"
                align="right"
              />
            </PieChart>
          </ResponsiveContainer>
        ) : (
          <p className="text-center">{DASHBOARD_PAGE_LABELS.no_data}</p>
        )}
      </div>
    </>
  );
};

const PieChartToolTip: React.FC<TooltipProps> = ({
  active,
  payload,
}: TooltipProps) => {
  if (active && Array.isArray(payload)) {
    const { value, name } = payload[0];
    return (
      <div className="tooltip-container">
        <span>
          {
            ActivityLocationNames.find((location) => location.type === name)
              ?.label
          }
          : {value}
        </span>
      </div>
    );
  }
  return <> </>;
};

const PieChartLegend: React.FC<LegendProps> = ({ payload }: LegendProps) => {
  if (Array.isArray(payload)) {
    return (
      <div className="legend-container">
        {payload.map((data, index) => {
          const { color, value } = data;
          return (
            <div className="legend-item" key={`pie-chart-lengend-${index}`}>
              <div className="circle" style={{ backgroundColor: color }}></div>
              <span className="legend-text">
                {
                  ActivityLocationNames.find(
                    (location) => location.type === value
                  )?.label
                }
              </span>
            </div>
          );
        })}
      </div>
    );
  }
  return <> </>;
};

import moment from 'moment';

const HOURS_IN_A_DAY = 24;
const MINUTES_IN_AN_HOUR = 60;

export const DOTFORMAT = 'DD/MM/YYYY';
export const MONTHYEARFORMAT = 'MMMM YYYY';

function convertDaysToHours(days: number) {
  return days * HOURS_IN_A_DAY;
}

function convertHoursToMinutes(hours: number) {
  return hours * MINUTES_IN_AN_HOUR;
}

export function formatDuration(durationMinutes: number) {
  const duration = moment.duration(durationMinutes, 'minutes');

  const days = Math.max(Math.floor(duration.asDays()), 0);
  const daysInHours = convertDaysToHours(days);
  const hours = Math.max(Math.floor(duration.asHours() - daysInHours), 0);
  const daysInMinutes = convertHoursToMinutes(daysInHours);
  const hoursInMinutes = convertHoursToMinutes(hours);
  const minutes = Math.max(
    Math.round(duration.asMinutes() - daysInMinutes - hoursInMinutes),
    0
  );

  const formattedDays = days !== 1 ? `${days} days` : `${days} day`;
  const formattedHours = hours !== 1 ? `${hours} hrs` : `${hours} hr`;
  const formattedMinutes = minutes !== 1 ? `${minutes} mins` : `${minutes} min`;
  if (days && hours && minutes) {
    return `${formattedDays} ${formattedHours} ${formattedMinutes}`;
  }

  if (days && hours) {
    return `${formattedDays} ${formattedHours}`;
  }

  if (hours && minutes) {
    return `${formattedHours} ${formattedMinutes}`;
  }

  if (days && minutes) {
    return `${formattedDays} ${formattedMinutes}`;
  }

  if (days) {
    return formattedDays;
  }

  if (hours) {
    return formattedHours;
  }

  return formattedMinutes;
}

import * as React from 'react';

import { IColumn } from '../types';

import { getIn } from '../utils';

export interface IProps<T> {
  field: IColumn<T>;
  item: T;
}

export default class AdminItemField<T> extends React.PureComponent<
  IProps<T>,
  {}
> {
  public render() {
    const { item, field } = this.props;
    const { type, name, customItemHandler } = field;
    const value = name && getIn(item, name);

    switch (type) {
      case 'custom':
        return <span>{customItemHandler && customItemHandler(item)}</span>;
      case 'integer':
        return <span>{value}</span>;
      case 'text':
      default:
        return <span>{value || '--'}</span>;
    }
  }
}

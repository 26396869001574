import React from 'react';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ActivitiesNames } from '^/common/activities-constants';
import {
  GRAPH_AXIS_LABEL_MAXLENGTH,
  GRAPH_TOOLTIP_COLOR,
} from '^/common/constants';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';
import { toTitleCase } from '^/common/utils/text-utils';
import {
  EmotionalBarGraphDataKeys,
  IEmotionalResponseGraphProps,
} from './types';

type Props = IEmotionalResponseGraphProps;

export const EmotionalResponseBarGraph: React.FC<Props> = (props: Props) => {
  const { data, colors, handleClick } = props;

  const YAxisTickFormater = (val: string) => {
    const returnVal = val
      ? val !== 'OTHERS'
        ? ActivitiesNames.find((activity) => activity.type === val)?.label
        : 'Other'
      : '';
    if (returnVal && returnVal.length > GRAPH_AXIS_LABEL_MAXLENGTH) {
      return returnVal.substr(0, GRAPH_AXIS_LABEL_MAXLENGTH - 4) + '...';
    }
    return returnVal;
  };

  const underScoreFormater = (val: string) => {
    return toTitleCase(val, '_');
  };

  const tooltipLabel = (val: string) => {
    return val
      ? val !== 'OTHERS'
        ? ActivitiesNames.find((activity) => activity.type === val)?.label
        : 'Other'
      : '';
  };

  return (
    <div className="stacked-bar-chart-container">
      {Array.isArray(data) && data.length ? (
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            data={data}
            layout="vertical"
            margin={{ top: 10, bottom: 10, right: 10, left: 10 }}
            stackOffset="expand"
            barSize={40}
          >
            <Bar
              dataKey={EmotionalBarGraphDataKeys.GREAT}
              stackId="a"
              fill={colors[0]}
              onClick={(e) =>
                handleClick
                  ? handleClick({
                      type: 'EMOTIONAL_RESPONSE',
                      selectedType: {
                        value: e.name == 'OTHERS' ? 'OTHER' : e.name,
                        emotion: EmotionalBarGraphDataKeys.GREAT,
                      },
                    })
                  : null
              }
              style={{ cursor: 'pointer' }}
            />
            <Bar
              dataKey={EmotionalBarGraphDataKeys.GOOD}
              stackId="a"
              fill={colors[1]}
              onClick={(e) =>
                handleClick
                  ? handleClick({
                      type: 'EMOTIONAL_RESPONSE',
                      selectedType: {
                        value: e.name == 'OTHERS' ? 'OTHER' : e.name,
                        emotion: EmotionalBarGraphDataKeys.GOOD,
                      },
                    })
                  : null
              }
              style={{ cursor: 'pointer' }}
            />
            <Bar
              dataKey={EmotionalBarGraphDataKeys.OK}
              stackId="a"
              fill={colors[2]}
              onClick={(e) =>
                handleClick
                  ? handleClick({
                      type: 'EMOTIONAL_RESPONSE',
                      selectedType: {
                        value: e.name == 'OTHERS' ? 'OTHER' : e.name,
                        emotion: EmotionalBarGraphDataKeys.OK,
                      },
                    })
                  : null
              }
              style={{ cursor: 'pointer' }}
            />
            <Bar
              dataKey={EmotionalBarGraphDataKeys.NOT_GOOD}
              stackId="a"
              fill={colors[3]}
              onClick={(e) =>
                handleClick
                  ? handleClick({
                      type: 'EMOTIONAL_RESPONSE',
                      selectedType: {
                        value: e.name == 'OTHERS' ? 'OTHER' : e.name,
                        emotion: EmotionalBarGraphDataKeys.NOT_GOOD,
                      },
                    })
                  : null
              }
              style={{ cursor: 'pointer' }}
            />
            <Bar
              dataKey={EmotionalBarGraphDataKeys.HORRIBLE}
              stackId="a"
              fill={colors[4]}
              onClick={(e) =>
                handleClick
                  ? handleClick({
                      type: 'EMOTIONAL_RESPONSE',
                      selectedType: {
                        value: e.name == 'OTHERS' ? 'OTHER' : e.name,
                        emotion: EmotionalBarGraphDataKeys.HORRIBLE,
                      },
                    })
                  : null
              }
              style={{ cursor: 'pointer' }}
            />
            <XAxis type="number" hide={true} />
            <YAxis
              type="category"
              tickFormatter={YAxisTickFormater}
              interval={0}
              width={85}
              dataKey="name"
              axisLine={false}
              tickLine={false}
            ></YAxis>
            <Tooltip
              formatter={(value, name) => [
                underScoreFormater(value.toString()),
                underScoreFormater(name.toString()),
              ]}
              cursor={{ fill: GRAPH_TOOLTIP_COLOR }}
              labelFormatter={(val) => tooltipLabel(val.toString())}
              itemStyle={{ margin: 0 }}
              wrapperStyle={{ padding: 0 }}
            />
            <Legend
              verticalAlign="top"
              layout="horizontal"
              iconType="circle"
              formatter={underScoreFormater}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <p className="text-center">{DASHBOARD_PAGE_LABELS.no_data}</p>
      )}
    </div>
  );
};

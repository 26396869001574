export type Feeling = 'GREAT' | 'GOOD' | 'OK' | 'NOT_GOOD' | 'HORRIBLE';

export const FEELING_NAMES: Readonly<{ [K in Feeling]: string }> = {
  GREAT: 'Great',
  GOOD: 'Good',
  OK: 'OK',
  NOT_GOOD: 'Not Good',
  HORRIBLE: 'Horrible',
};

export type Location =
  | 'INSIDE_SCHOOL_PE'
  | 'INSIDE_SCHOOL_CLUB'
  | 'INSIDE_SCHOOL_BREAK'
  | 'OUTSIDE_SCHOOL_CLUB'
  | 'OUTSIDE_SCHOOL_FREE_PLAY'
  | 'OUTSIDE_SCHOOL_HOLIDAY_CAMP';

export const LOCATION_NAMES: Readonly<{ [K in Location]: string }> = {
  INSIDE_SCHOOL_PE: 'School PE',
  INSIDE_SCHOOL_CLUB: 'School Club',
  INSIDE_SCHOOL_BREAK: 'School Break',
  OUTSIDE_SCHOOL_CLUB: 'Outside School Club',
  OUTSIDE_SCHOOL_FREE_PLAY: 'Outside School Free Time',
  OUTSIDE_SCHOOL_HOLIDAY_CAMP: 'Outside School Holiday Camp',
};

import { ActivityPageUserDetail } from '^/app/authenticated/activities/activity-page/types';
import { EditClassFormClass } from '^/app/authenticated/classes/class-management/types';
import { BasicSchool } from '^/app/authenticated/school-switcher/types';
import { BasicUser } from '^/app/authenticated/users/types';
import { SchoolSettingsList } from '^/app/pages/school-settings/types';

export interface Items {
  'cohorts/class-groups': EditClassFormClass;
  users: BasicUser; // TODO - route and type for edit user?
  'users/activity-page-user-detail': ActivityPageUserDetail;
  'schools/school-switcher': BasicSchool;
  'schools/schools-list': BasicSchool;
  'profile/social-settings-list': SchoolSettingsList;
}

export const itemToRecordMapping = {
  'cohorts/class-groups': EditClassFormClass,
  users: BasicUser,
  'users/activity-page-user-detail': ActivityPageUserDetail,
  'schools/school-switcher': BasicSchool,
  'schools/schools-list': BasicSchool,
  'profile/social-settings-list': SchoolSettingsList,
};

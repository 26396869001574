import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { StoreState } from '^/store/types';
import { ImportUsersData } from './types';

export const QUEUE_IMPORT_USER = makeAsyncActionSet('QUEUE_IMPORT_USER');
export function queueImportUser(data: Partial<ImportUsersData>) {
  const formData = new FormData();

  if (data.school) {
    formData.append('school', data.school);
  }

  if (data.csv_file) {
    formData.append('csv_file', data.csv_file[0]);
  }

  return request(
    QUEUE_IMPORT_USER,
    '/api/users/bulk-import/',
    'POST',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
}

export const GET_IMPORT_STATUS = makeAsyncActionSet('GET_IMPORT_STATUS');
export function getImportStatus(importId: string) {
  return request(
    GET_IMPORT_STATUS,
    `/api/users/bulk-import/${importId}/`,
    'GET'
  );
}

const POLL_IMPORT_STATUS_INTERVAL = 2000;
export function pollImportStatus(importId: string) {
  return (dispatch: Dispatch, getState: () => StoreState) => {
    return getImportStatus(importId)(dispatch).then(
      (response: void | AxiosResponse) => {
        if (response) {
          if (!response.data.is_complete && !response.data.has_failed) {
            setTimeout(
              () => pollImportStatus(importId)(dispatch, getState),
              POLL_IMPORT_STATUS_INTERVAL
            );
          }

          if (response.data.is_complete) {
            toast.success('User import successful!');
          }
        }
      }
    );
  };
}

export function importUsersAndBeginPolling(data: Partial<ImportUsersData>) {
  return (dispatch: Dispatch, getState: () => StoreState) => {
    return queueImportUser(data)(dispatch).then(
      (response: void | AxiosResponse) => {
        if (response) {
          pollImportStatus(response.data.id)(dispatch, getState);
          toast.success('User import queued.');
        }
      }
    );
  };
}

import { Emoji } from '^/app/authenticated/messaging/types';

export const EMOJI_TO_CLASSNAME: { [K in Emoji]: string } = {
  THUMBS_UP: 'thumbs-up',
  SMILE: 'smile',
  LAUGHING: 'laughing',
  HEART_EYES: 'heart-eyes',
  TONGUE_OUT: 'tongue-out',
};

export const EMOJI_LIST: ReadonlyArray<Emoji> = [
  'TONGUE_OUT',
  'HEART_EYES',
  'LAUGHING',
  'SMILE',
  'THUMBS_UP',
];

export const MESSAGE_PRESETS: ReadonlyArray<string> = [
  'I notice that you’ve been far more active recently. Great job. Keep it up!',
  'I notice that you’ve been less active recently. This happens to everyone at times but let’s have a chat to see if there’s anything I can do to help.',
  'Judging by the number of smiley faces on your miMove posts, it looks like you’ve really been enjoying your activity lately. I’m absolutely delighted. #happyteacher',
  'It’s great to see how many different activities you’ve been taking part in.',
  'Well done everyone! miMove is showing that your class/your year /our school is much more active than you/we were last month/year. #beproud',
  'Take a minute to think about scheduling some activity next week. Let’s think about what you can do –where, when, who with? Plan it - Do it!',
  'Reminder – we’ve a new club starting [insert details of day, time, place]. It would be great if you could join us. It will be fun.',
  'You should be super proud of yourself for overcoming challenges to take part in physical activity.',
  'You’re great at supporting others. Well done.',
  'You’ve consistently taken part in regular physical activity this month/term. Great job!',
  'You’re building a great streak. Continue setting your targets and meeting them!',
  'I’m pleased to see how committed you are to physical activity.',
  'Your enthusiasm for physical activity is fantastic to see.',
];

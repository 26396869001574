import {
  GET_COLLECTION,
  getCollectionResultsByName,
} from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import {
  Alert,
  Button,
  ContentBoxFooter,
  ContentBoxHeader,
  FormGroup,
} from '@dabapps/roe';
import { UserRoles } from '^/common/authentication/types';
import { ClassListUser } from '^/app/authenticated/classes/types';
import { collections } from '^/common/collections';
import ErrorMessage from '^/common/error-handling/error-message';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import SearchSelect from '^/common/forms/fields/search-select';
import LoadingSpinner from '^/common/loading-spinner';
import { ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';
import React from 'react';
import { connect } from 'react-redux';
import {
  Field,
  Form,
  getFormValues,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';
import _ from 'underscore';
import { EditClassFormClass } from '../types';
import { Row, Space, Switch } from 'antd';

const {
  actions: { getCollection, clearCollection },
} = collections;

interface ExternalProps {
  classDetails?: EditClassFormClass;
  errors?: ResponseErrors;
  saveText: string;
  isLoading: boolean;
}

interface DispatchProps {
  getCollection: typeof getCollection;
  clearCollection: typeof clearCollection;
}

interface StateProps {
  studentsIsLoading: boolean;
  formState: Partial<EditClassFormClass>;
  availableStudents: ReadonlyArray<ClassListUser>;
  selectedStudents: ReadonlyArray<ClassListUser>;
  currentSchool: string;
  studentsRequestHasFailed: boolean;
}

export type Props = DispatchProps &
  StateProps &
  ExternalProps &
  InjectedFormProps<EditClassFormClass, ExternalProps>;

type StudentCollectionName = 'selected-students' | 'available-students';

const ON_INPUT_CHANGE_DEBOUNCE_MS = 500;

export class EditCreateClassForm extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.selectOnInputChange = _.debounce(
      this.selectOnInputChange,
      ON_INPUT_CHANGE_DEBOUNCE_MS
    );
  }

  public componentDidMount() {
    const { classDetails, formState } = this.props;

    if (classDetails && classDetails.users.length) {
      this.searchStudentsByStudentIds(classDetails.users, 'selected-students');
    } else {
      this.props.clearCollection(
        'cohorts/class-user-list',
        'selected-students'
      );
    }

    const excludeIds = formState ? formState.users : [];
    this.searchStudentsByQueryString('', 'available-students', excludeIds);
  }

  public render() {
    const {
      handleSubmit,
      studentsIsLoading,
      studentsRequestHasFailed,
      errors,
      saveText,
      isLoading,
      classDetails,
      formState,
    } = this.props;

    if (studentsRequestHasFailed) {
      return <ErrorMessage />;
    }

    const studentsAssignedToAnotherClass = this.getStudentsAssignedToAnotherClass();
    const shouldShowStudentAssignmentWarning =
      Boolean(studentsAssignedToAnotherClass.length) &&
      !classDetails?.is_extracurricular &&
      !formState?.is_extracurricular;

    return (
      <Form onSubmit={handleSubmit}>
        <ContentBoxHeader className="padding-bottom-large padding-top-base">
          <FormGroup className="margin-vertical-none" block>
            <label>Class or Group Name</label>
            <Field name="name" component="input" />
            <FormErrorRenderer formErrors={errors} formErrorsKey="name" />
          </FormGroup>
          <FormGroup block className="margin-bottom-base">
            <Row>
              <Field
                name="is_extracurricular"
                id="is_extracurricular"
                className="mt-1"
                component={({ input }) => (
                  <Space className="card-header small-header">
                    <span>{'Class'}</span>
                    <Space>
                      <Switch
                        defaultChecked={false}
                        onChange={
                          !Boolean(classDetails) ? input.onChange : undefined
                        }
                        checked={input.value ? true : false}
                      />
                      <span>{'Group'}</span>
                    </Space>
                  </Space>
                )}
              />
            </Row>
          </FormGroup>
          <Alert className="notes padding-vertical-base">
            The Class is the main group you wish your students to be organised
            in. Groups are other groups students might be in e.g. clubs,
            pastoral groups, other teaching groups.
            <br />
            <strong>Please Note:</strong>
            <ul className="margin-none">
              <li key="l1" className="margin-none">
                A student can only be in one Class.
              </li>
              <li key="l2" className="margin-none">
                A student can be in multiple Groups.
              </li>
              <li key="l3" className="margin-none">
                If you add a student to any Group, they will remain in their
                main Class{' '}
              </li>
            </ul>
          </Alert>
        </ContentBoxHeader>
        <FormGroup className="margin-top-base margin-bottom-large" block>
          <h3 className="margin-top-none margin-bottom-base">Students</h3>
          <Field
            name="users"
            isLoading={studentsIsLoading}
            options={this.getStudentOptions()}
            onSelectChange={this.onChange}
            onInputChange={this.selectOnInputChange}
            component={SearchSelect}
          />
          {shouldShowStudentAssignmentWarning && (
            <Alert className="warning padding-vertical-base">
              <strong>Warning!</strong> The following students are already
              assigned to a class and will be reassigned to this class once you
              save it:
              <ul>
                {studentsAssignedToAnotherClass.map((student) => (
                  <li key={student.id} className="margin-none">
                    {student.first_name} {student.last_name}
                  </li>
                ))}
              </ul>
            </Alert>
          )}
          <FormErrorRenderer formErrors={errors} formErrorsKey="users" />
        </FormGroup>
        <ContentBoxFooter className="padding-vertical-base">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <Button className="primary float-right pill" type="submit">
              {saveText}
            </Button>
          )}
        </ContentBoxFooter>
      </Form>
    );
  }

  private getStudentsAssignedToAnotherClass = () => {
    const currentClassId = this.props.classDetails
      ? this.props.classDetails.id
      : null;
    const selectedStudents = this.props.selectedStudents;
    return selectedStudents.filter(
      (student) => student.class_group && student.class_group !== currentClassId
    );
  };

  private searchStudents = (
    query: string,
    studentIds: ReadonlyArray<string>,
    collectionName: StudentCollectionName,
    excludeIds: ReadonlyArray<string>
  ) => {
    const { currentSchool } = this.props;

    if (currentSchool) {
      this.props.getCollection(
        'cohorts/class-user-list',
        {
          filters: {
            school: currentSchool,
            role: UserRoles.STUDENT,
            search: query,
            id: studentIds.join(','),
            id__not__in: excludeIds.join(','),
          },
          pageSize: 100,
        },
        collectionName
      );
    }
  };

  private searchStudentsByQueryString(
    query: string,
    collectionName: StudentCollectionName,
    excludeIds: ReadonlyArray<string> = []
  ) {
    this.searchStudents(query, [], collectionName, excludeIds);
  }

  private searchStudentsByStudentIds(
    studentIds: ReadonlyArray<string>,
    collectionName: StudentCollectionName,
    excludeIds: ReadonlyArray<string> = []
  ) {
    this.searchStudents('', studentIds, collectionName, excludeIds);
  }

  private selectOnInputChange = (query: string) => {
    const formState = this.props.formState;
    const excludeIds = formState ? formState.users : [];
    this.searchStudentsByQueryString(query, 'available-students', excludeIds);
  };

  private transformStudentsToOptions = (
    students: ReadonlyArray<ClassListUser>
  ) => {
    return students.map((student) => ({
      value: student.id,
      label: `${student.first_name} ${student.last_name}`,
    }));
  };

  private getAllStudents = () => {
    const { availableStudents, selectedStudents } = this.props;

    const allStudents = availableStudents.concat(selectedStudents);
    const allStudentsUnique = _.uniq(allStudents, (student) => student.id);

    return allStudentsUnique;
  };

  private getStudentOptions = () => {
    const allStudents = this.getAllStudents();
    return this.transformStudentsToOptions(allStudents);
  };

  private onChange = (selectedStudentIds: ReadonlyArray<string>) => {
    if (selectedStudentIds.length) {
      this.searchStudentsByStudentIds(selectedStudentIds, 'selected-students');
    } else {
      this.props.clearCollection(
        'cohorts/class-user-list',
        'selected-students'
      );
    }
  };
}

function mapStateToProps(state: StoreState) {
  return {
    formState: getFormValues('edit-create-class')(state),
    currentSchool: state.currentSchool,
    availableStudents: getCollectionResultsByName(
      state.collections,
      'cohorts/class-user-list',
      'available-students'
    ) as ReadonlyArray<ClassListUser>,
    selectedStudents: getCollectionResultsByName(
      state.collections,
      'cohorts/class-user-list',
      'selected-students'
    ) as ReadonlyArray<ClassListUser>,
    studentsIsLoading: isPending(
      state.responses,
      GET_COLLECTION,
      'cohorts/class-user-list'
    ),
    studentsRequestHasFailed: hasFailed(
      state.responses,
      GET_COLLECTION,
      'cohorts/class-user-list'
    ),
  };
}

const ConnectedEditCreateClassForm = connect(mapStateToProps, {
  getCollection,
  clearCollection,
})(EditCreateClassForm);

export default reduxForm<EditClassFormClass, ExternalProps>({
  form: 'edit-create-class',
})(ConnectedEditCreateClassForm);

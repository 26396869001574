import { TableCell, TableRow } from '@dabapps/roe';
import classNames from 'classnames';
import * as React from 'react';

import AdminItemField from '../detail/AdminItemField';
import { TColumns } from '../types';

export interface IProps<T> {
  item: T;
  fields?: TColumns<T>;
  isSelected: boolean;
}

export default class AdminListItem<T> extends React.PureComponent<
  IProps<T>,
  {}
> {
  public render() {
    const { item, fields, isSelected } = this.props;

    return (
      <TableRow className={classNames(isSelected && 'selected')}>
        {fields &&
          fields.map((field, idx) => (
            <TableCell key={idx}>
              <AdminItemField key={idx} item={item} field={field} />
            </TableCell>
          ))}
      </TableRow>
    );
  }
}

import { hasFailed, isPending } from '@dabapps/redux-requests';
import { Button, Modal, ModalCloseIcon, ModalHeader } from '@dabapps/roe';
import { AxiosPromise } from 'axios';
import classnames from 'classnames';
import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import _ from 'underscore';

import {
  ADD_ASSESSMENT,
  addAssessment,
  AddAssessmentData,
  changeFullAssessmentPage,
  toggleAssessmentType,
} from '^/app/authenticated/assessments/actions';
import {
  ASSESSMENT_TYPES,
  NUMBER_OF_FULL_ASSESSMENT_PAGES,
} from '^/app/authenticated/assessments/constants';
import FullAssessmentForm, {
  FULL_ASSESSMENT_FORM,
  FullAssessmentValues,
} from '^/app/authenticated/assessments/full-assessment-form';
import LightAssessmentForm, {
  LIGHT_ASSESSMENT_FORM,
} from '^/app/authenticated/assessments/light-assessment-form';
import { AssessmentType, Status } from '^/app/authenticated/assessments/types';
import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import { closeModal } from '^/common/modals/actions';
import { StoreState } from '^/store/types';

interface ExternalProps {
  studentId: string;
  studentName: string;
  isActivityPage?: boolean;
}

interface StateProps {
  isFullAssessment: boolean;
  fullAssessmentPageNumber: number;
  fullAssessmentFormValues: FullAssessmentValues;
  lightAssessmentValue?: Status;
  isLoading: boolean;
  hasFailedAssessment: boolean;
}

interface DispatchProps {
  closeModal(): void;
  toggleAssessmentType(): void;
  changeFullAssessmentPage(pageNumber?: number): void;
  addAssessment(
    data: AddAssessmentData,
    type: AssessmentType,
    studentName: string
  ): AxiosPromise;
}

type Props = ExternalProps & StateProps & DispatchProps;

export class AssessmentModal extends React.PureComponent<Props> {
  public componentWillMount() {
    if (this.props.isFullAssessment) {
      this.props.toggleAssessmentType();
    }
  }

  public render() {
    const {
      studentName,
      isFullAssessment,
      isLoading,
      fullAssessmentFormValues,
      lightAssessmentValue,
      hasFailedAssessment,
    } = this.props;
    const buttonClassNames = 'pill';
    return (
      <Modal onClickOutside={_.noop}>
        <ModalHeader>
          <ModalCloseIcon onClick={this.props.closeModal}>
            <CloseOutlined />
          </ModalCloseIcon>
          <h2 className="font-size-large margin-vertical-none">
            ASSESS {studentName.toUpperCase()}
          </h2>
          <div className="display-block margin-top-small lg-margin-top-large">
            <Button
              onClick={this.props.toggleAssessmentType}
              className={classnames(
                buttonClassNames,
                'margin-right-small',
                'float-left',
                isFullAssessment && 'hollow'
              )}
            >
              Light Assessment{' '}
              <span className="button-help-text">Pen Portrait</span>
            </Button>
            <Button
              onClick={this.props.toggleAssessmentType}
              className={classnames(
                buttonClassNames,
                !isFullAssessment && 'hollow'
              )}
            >
              Full Assessment{' '}
              <span className="button-help-text">3 Domains (MAT)</span>
            </Button>
          </div>
        </ModalHeader>
        {isLoading ? (
          <div className="padding-vertical-large">
            <LoadingSpinner />
          </div>
        ) : hasFailedAssessment ? (
          <div className="padding-vertical-large">
            <ErrorMessage />
          </div>
        ) : isFullAssessment ? (
          <FullAssessmentForm
            onSubmit={this.submitFullAssessment}
            assessmentValues={fullAssessmentFormValues}
          />
        ) : (
          <LightAssessmentForm
            onSubmit={this.submitLightAssessment}
            assessmentValue={lightAssessmentValue}
          />
        )}
      </Modal>
    );
  }

  private submitLightAssessment = () => {
    this.props.addAssessment(
      {
        status: this.props.lightAssessmentValue,
        student_id: this.props.studentId,
      },
      ASSESSMENT_TYPES.LIGHT,
      this.props.studentName
    );
  };

  private submitFullAssessment = () => {
    if (this.props.fullAssessmentPageNumber < NUMBER_OF_FULL_ASSESSMENT_PAGES) {
      this.props.changeFullAssessmentPage();
    } else {
      this.props.addAssessment(
        {
          ...this.props.fullAssessmentFormValues,
          is_full_assessment: true,
          student_id: this.props.studentId,
        },
        ASSESSMENT_TYPES.FULL,
        this.props.studentName
      );
    }
  };
}

function mapStateToProps(state: StoreState) {
  const { isFullAssessment, fullAssessmentPageNumber } = state.assessmentModal;
  const fullAssessmentSelector = formValueSelector(FULL_ASSESSMENT_FORM);
  return {
    isFullAssessment,
    fullAssessmentPageNumber,
    fullAssessmentFormValues: {
      ...fullAssessmentSelector(
        state,
        'movement_status',
        'thinking_status',
        'attitude_status'
      ),
    },
    lightAssessmentValue: formValueSelector(LIGHT_ASSESSMENT_FORM)(
      state,
      'status'
    ),
    isLoading: isPending(state.responses, ADD_ASSESSMENT),
    hasFailedAssessment: hasFailed(state.responses, ADD_ASSESSMENT),
  };
}

export default connect(
  mapStateToProps,
  {
    addAssessment,
    closeModal,
    toggleAssessmentType,
    changeFullAssessmentPage,
  }
)(AssessmentModal);

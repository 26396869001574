import * as React from 'react';

import { Column, Row } from '@dabapps/roe';

import { TColumns, TRows } from '../types';

import Loading from '../common/Loading';
import Table from '../common/Table';

import AdminListHeader from './AdminListHeader';
import AdminListItem from './AdminListItem';
import AdminListPagination from './AdminListPagination';

export interface IAdminListItemsProps<T> {
  items: TRows<T>;
  columns: TColumns<T>;
  isLoading?: boolean;
  changePage?: (pageNumber: number) => void;
  noItemsMessage?: string;
  pageSize: number;
  title?: string;
  page: number;
  itemCount: number;
  selectedItems?: TRows<T>;
}

export default class AdminListItems<T> extends React.PureComponent<
  IAdminListItemsProps<T>,
  {}
> {
  public constructor(props: IAdminListItemsProps<T>) {
    super(props);
  }

  public render() {
    const {
      title,
      items,
      noItemsMessage,
      columns,
      pageSize,
      isLoading,
      changePage,
      itemCount,
      page,
      selectedItems,
    } = this.props;

    return (
      <div className="admin-list-container">
        <Row>
          <Column xs={12}>
            {title && (
              <div className="col-1-2">
                <h3>{title}</h3>
              </div>
            )}
          </Column>
        </Row>

        {isLoading ? (
          <Loading />
        ) : (
          <Table
            /* tslint:disable-next-line:jsx-no-lambda */
            renderRow={(item, idx: number, fields) => (
              <AdminListItem
                key={idx}
                item={item}
                fields={fields}
                isSelected={
                  selectedItems ? selectedItems.indexOf(item) !== -1 : false
                }
              />
            )}
            /* tslint:disable-next-line:jsx-no-lambda */
            renderHeaders={headers =>
              headers.map((header, idx) => (
                <AdminListHeader key={idx} header={header} />
              ))
            }
            rows={items}
            columns={columns}
            emptyMessage={noItemsMessage || 'None Found.'}
          />
        )}

        {changePage && (
          <AdminListPagination
            items={items}
            pageSize={pageSize}
            changePage={changePage}
            page={page}
            itemCount={itemCount}
          />
        )}
      </div>
    );
  }
}

import { isPending } from '@dabapps/redux-requests';
import { Column, Container, ContentBox, Row } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';

import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import {
  CREATE_CLASS,
  createClass,
} from '^/app/authenticated/classes/class-management/add-class/actions';
import { NewClass } from '^/app/authenticated/classes/class-management/types';
import { collections } from '^/common/collections';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';
import EditCreateClassForm from '../form';

const {
  actions: { getCollection },
} = collections;

interface DispatchProps {
  getCollection: typeof getCollection;
  createClass: typeof createClass;
}

interface StateProps {
  createClassIsLoading: boolean;
  currentSchool: string | null;
  errors?: ResponseErrors;
}

type Props = DispatchProps & StateProps;

const breadcrumbs = [
  { label: 'Classes & Groups', path: '/classes' },
  { label: 'Add Class or Group', path: '' },
];

export class AddClass extends React.PureComponent<Props> {
  public render() {
    const { createClassIsLoading, errors } = this.props;

    return (
      <Container className="margin-top-large">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Row>
          <Column sm={10} md={8} lg={6} smPush={1} mdPush={2} lgPush={3}>
            <ContentBox>
              <EditCreateClassForm
                onSubmit={this.createClass}
                isLoading={createClassIsLoading}
                errors={errors}
                saveText="Save"
              />
            </ContentBox>
          </Column>
        </Row>
      </Container>
    );
  }

  private createClass = (data: NewClass) => {
    if (this.props.currentSchool) {
      const dataToSend = {
        ...data,
        school: this.props.currentSchool,
      };
      this.props.createClass(dataToSend);
    }
  };
}

function mapStateToProps(state: StoreState): StateProps {
  return {
    createClassIsLoading: isPending(state.responses, CREATE_CLASS),
    currentSchool: state.currentSchool,
    errors: getResponseErrors(state.responses, CREATE_CLASS),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  createClass,
})(AddClass);

import * as React from 'react';

import { TableHeader } from '@dabapps/roe';
import { IColumn } from '../types';

export interface IAdminListHeaderProps<T> {
  header: IColumn<T>;
}

export default class AdminListHeader<T> extends React.PureComponent<
  IAdminListHeaderProps<T>,
  {}
> {
  public render() {
    const { header } = this.props;
    const { display_name } = header;

    return <TableHeader>{display_name}</TableHeader>;
  }
}

import { RecordWithConstructor, SimpleRecord } from '@dabapps/simple-records';
import { Status } from '^/app/authenticated/assessments/types';
import { Activity } from '^/common/constants';

export type DashboardStudentListLatestActivity = Readonly<{
  readonly activity: Activity;
  readonly other_activity_type: string | null;
  readonly duration_minutes: number;
  readonly date: string;
}>;

export const DashboardStudentListLatestActivity = SimpleRecord<
  DashboardStudentListLatestActivity
>({
  activity: 'ACTIVE_PLAY',
  other_activity_type: null,
  duration_minutes: 0,
  date: '2019-01-01',
});

export type DashboardStudentListUserInput = Readonly<{
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly class_group_name: string;
  readonly latest_assessment_status: Status | null;
  readonly latest_activity: DashboardStudentListLatestActivity | null;
  readonly activity_count: number | null;
  readonly total_activity_minutes: number | null;
}>;
export type DashboardStudentListUser = Readonly<{
  readonly id: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly class_group_name: string;
  readonly latest_assessment_status: Status | null;
  readonly latest_activity: DashboardStudentListLatestActivity | null;
  readonly activity_count: number;
  readonly total_activity_minutes: number;
}>;

export const DashboardStudentListUser = RecordWithConstructor<
  DashboardStudentListUserInput,
  DashboardStudentListUser
>(
  {
    id: '',
    first_name: '',
    last_name: '',
    class_group_name: '',
    latest_assessment_status: null,
    latest_activity: null,
    activity_count: 0,
    total_activity_minutes: 0,
  },
  (input) => ({
    ...input,
    latest_activity:
      input.latest_activity &&
      DashboardStudentListLatestActivity(input.latest_activity),
    activity_count: input.activity_count || 0,
    total_activity_minutes: input.total_activity_minutes || 0,
  })
);

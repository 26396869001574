import * as React from 'react';

import { FormGroup } from '@dabapps/roe';
import FieldGroup from '../FieldGroup';
import { TWrappedFieldProps } from './types';

export const FieldRadio = ({
  config,
  name,
  errors,
  value,
  onChange,
}: React.HTMLProps<JSX.Element> & TWrappedFieldProps) => {
  return (
    <FieldGroup config={config} errors={errors}>
      {config.choices &&
        config.choices.map((choice, key) => {
          const choiceId = `${choice.value}_${key}`;
          return (
            <FormGroup key={key} className="input">
              <input
                id={choiceId}
                onChange={onChange}
                type="radio"
                name={name}
                value={choice.value}
                checked={value === choice.value}
              />
              <label htmlFor={choiceId}>{choice.display_name}</label>
            </FormGroup>
          );
        })}
    </FieldGroup>
  );
};

export default FieldRadio;

import { PENDING_ACTIVITY_COLUMNS } from '^/app/authenticated/activities/activity-page/config';
import { AdminList } from '^/chadmin';
import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import React, { useEffect, useState } from 'react';
import ActivityApi from '^/api-services/activities/activities.service';
import {
  IActivityListItem,
  IStudentPendingActivityRequest,
  Pagination,
} from '^/api-services/activities/types';
import useAPI from '^/api-services/useApi';

import './activities-table-styles.less';

export const PAGE_SIZE = 10;
interface Props {
  userId: string;
}

export const PendingTable: React.FC<Props> = (props: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const {
    callAPI: getStudentPendingActivities,
    ...studentActivitiesResponse
  } = useAPI<IStudentPendingActivityRequest, Pagination<IActivityListItem>>(
    ActivityApi.getStudentPendingActivities
  );

  const fetchActivities = (page = 1) => {
    getStudentPendingActivities({
      studentID: props.userId,
      page: page,
      page_size: PAGE_SIZE,
    });
  };

  useEffect(() => {
    if (props.userId) {
      fetchActivities();
    }
  }, [props.userId]);

  const { response, loading, error } = studentActivitiesResponse;

  const changePage = (page: number) => {
    setCurrentPage(page);
    fetchActivities(page);
  };

  return loading || !response ? (
    <LoadingSpinner />
  ) : error ? (
    <div className="padding-vertical-large">
      <ErrorMessage />
    </div>
  ) : (
    <div className="table-top-align student-activities">
      <AdminList
        items={response.results}
        itemCount={response.count}
        columns={PENDING_ACTIVITY_COLUMNS}
        listName="list"
        changePage={changePage}
        page={currentPage}
        pageSize={PAGE_SIZE}
      />
    </div>
  );
};

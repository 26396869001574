import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { isSuccessResponse } from '^/common/utils/responses';
import { ClassListClass } from '../../types';

export const EDIT_CLASS = makeAsyncActionSet('EDIT_CLASS');
export function editClass(data: Partial<ClassListClass>, classId: string) {
  return (dispatch: Dispatch) => {
    return request(
      EDIT_CLASS,
      `/api/cohorts/class-groups/${classId}/`,
      'PATCH',
      data
    )(dispatch).then((response) => {
      if (response && isSuccessResponse(response)) {
        dispatch(push('/classes'));
        const data = response.data;
        if (data.is_extracurricular) {
          toast.success(`Group ${data.name} saved`);
        } else {
          toast.success(`Class ${data.name} saved`);
        }
      }
    });
  };
}

export const DELETE_CLASS = makeAsyncActionSet('DELETE_CLASS');
export function deleteClass(classId: string) {
  return (dispatch: Dispatch) => {
    return request(
      DELETE_CLASS,
      `/api/cohorts/class-groups/${classId}/`,
      'DELETE'
    )(dispatch).then((response) => {
      if (response && isSuccessResponse(response)) {
        dispatch(push('/classes'));
        toast.success(`Class successfully deleted.`);
      }
    });
  };
}

import { IItemConfig, TChoices } from '^/chadmin';

import { ClassGroupOption } from '^/app/authenticated/users/user-management/types';
import { LoggedInUser, ROLES, UserRoles } from '^/common/authentication/types';
import {
  ETHNICITIES,
  ETHNICITY_DISPLAY,
  GENDER_DISPLAY,
  GENDERS,
} from '^/common/users/constants';
import { SelectOption } from '^/app/components';
import { loggedInUserIsTeacher } from '^/common/utils/roles';

export const createUserFormFields = (
  selectedRole?: ROLES,
  isEditingSelf?: boolean,
  isSSO?: boolean
) => {
  const BASE_FIELDS = ['first_name', 'last_name', 'role', 'username'];
  if (!isSSO) {
    BASE_FIELDS.push('temporary_password');
  }

  if (isEditingSelf) {
    return ['first_name', 'last_name', 'username'];
  }
  if (selectedRole === UserRoles.STUDENT) {
    return [
      ...BASE_FIELDS,
      'year_group',
      'class_group',
      'extracurricular_class_group',
      'gender',
      'ethnicity',
      'is_send_registered',
      'is_eal',
      'is_pupil_premium',
    ];
  }
  return BASE_FIELDS;
};

export const changePasswordFormFields = [
  'old_password',
  'new_password1',
  'new_password2',
];

export const createUserFormConfig = (
  classGroups: ReadonlyArray<ClassGroupOption>,
  year_group: SelectOption[],
  addYearGroup: (yearGroup: SelectOption, cb: () => void) => void,
  addClassGroup:
    | ((classGroup: SelectOption, cb: () => void) => void)
    | undefined,
  selectedRole?: ROLES,
  loggedInUser?: LoggedInUser | null
): IItemConfig => {
  const formatFieldValues = (
    input: ReadonlyArray<ClassGroupOption>
  ): TChoices => {
    return input.map((item) => {
      return { value: item.id, display_name: item.name };
    });
  };
  const formattedClassGroups = formatFieldValues(classGroups);
  const formattedYearGroups = formatFieldValues(year_group);
  const { TEACHER, STUDENT, LEAD_TEACHER } = UserRoles;

  return {
    first_name: {
      label: 'First Name',
      max_length: 255,
      type: 'text',
      placeholder: 'This is used as a salutation in the app',
      fullWidth: true,
    },
    last_name: {
      label: 'Last Name',
      max_length: 255,
      type: 'text',
      fullWidth: true,
    },
    username: {
      label: selectedRole === TEACHER ? 'Email Address' : 'Username',
      max_length: 255,
      type: 'text',
      placeholder: 'This is used when they log in',
      fullWidth: true,
    },
    temporary_password: {
      label: 'Password',
      max_length: 255,
      type: 'text',
      placeholder: 'Only if Single SignOn is not enabled',
      fullWidth: true,
    },
    role: {
      label: 'Type',
      max_length: 255,
      type: 'text',
      choices: [
        { value: STUDENT, display_name: 'Student' },
        { value: TEACHER, display_name: 'Teacher' },
        ...(loggedInUserIsTeacher(loggedInUser || null)
          ? []
          : [{ value: LEAD_TEACHER, display_name: 'Lead Teacher' }]),
      ],
      fullWidth: true,
      clearable: false,
    },
    year_group: {
      label: 'Year',
      max_length: 255,
      type: 'text',
      choices: formattedYearGroups,
      placeholder: 'Used for filtering',
      fullWidth: true,
      clearable: false,
      canManuallyAdd: true,
      manuallAddCB: addYearGroup,
    },
    class_group: {
      label: 'Class',
      max_length: 255,
      type: 'text',
      choices: formattedClassGroups,
      placeholder: 'Used for filtering',
      fullWidth: true,
      clearable: false,
      canManuallyAdd: true,
      manuallAddCB: addClassGroup,
    },
    extracurricular_class_group: {
      label: 'Groups:',
      max_length: 1024,
      type: 'text',
      fullWidth: true,
      clearable: false,
      readOnly: true,
      format: (val) => {
        if (val && val.length > 0) {
          return `<ul style="margin-top:5px;margin-bottom:10px;">
            ${val
              .map(
                (t: any) =>
                  '<li style="margin-top:2px;margin-bottom:2px;">' +
                  t.name +
                  '</li>'
              )
              .join('')}
            </ul>`;
        }
        return 'None';
      },
    },
    gender: {
      label: 'Gender',
      max_length: 255,
      type: 'text',
      choices: GENDERS.map((gender) => ({
        value: gender,
        display_name: GENDER_DISPLAY[gender],
      })),
      placeholder: 'Used for filtering',
      fullWidth: true,
      clearable: false,
    },
    is_pupil_premium: {
      label: 'Pupil Premium',
      type: 'text',
      choices: [
        { value: 'true', display_name: 'Yes' },
        { value: 'false', display_name: 'No' },
      ],
      placeholder: 'Optional',
      fullWidth: true,
      clearable: false,
    },
    ethnicity: {
      label: 'Ethnicity',
      type: 'text',
      choices: ETHNICITIES.map((ethnicity) => ({
        value: ethnicity,
        display_name: ETHNICITY_DISPLAY[ethnicity],
      })),
      placeholder: 'Optional',
      fullWidth: true,
      clearable: false,
    },
    is_send_registered: {
      label: 'SEND',
      type: 'text',
      choices: [
        { value: 'true', display_name: 'On SEND register' },
        { value: 'false', display_name: 'Not on SEND register' },
      ],
      placeholder: 'Optional',
      fullWidth: true,
      clearable: false,
    },
    is_eal: {
      label: 'EAL',
      type: 'text',
      choices: [
        { value: 'true', display_name: 'EAL' },
        { value: 'false', display_name: 'N/A' },
      ],
      placeholder: 'Optional',
      fullWidth: true,
      clearable: false,
    },
  };
};

export const changePasswordConfig: IItemConfig = {
  old_password: {
    label: 'Old Password',
    max_length: 255,
    type: 'password',
    fullWidth: true,
  },
  new_password1: {
    label: 'New Password',
    max_length: 255,
    type: 'password',
    fullWidth: true,
  },
  new_password2: {
    label: 'Re-enter New Password',
    max_length: 255,
    type: 'password',
    fullWidth: true,
  },
};

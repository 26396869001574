import { SimpleRecord } from '@dabapps/simple-records';

export enum UserRoles {
  MIMOVE_ADMIN = 'MIMOVE_ADMIN',
  TEACHER = 'TEACHER',
  CLUSTER_ADMIN = 'CLUSTER_ADMIN',
  STUDENT = 'STUDENT',
  CLUSTER_MANAGER = 'CLUSTER_MANAGER',
  LEAD_TEACHER = 'LEAD_TEACHER',
}

export type ROLES =
  | UserRoles.MIMOVE_ADMIN
  | UserRoles.TEACHER
  | UserRoles.CLUSTER_ADMIN
  | UserRoles.STUDENT
  | UserRoles.CLUSTER_MANAGER
  | UserRoles.LEAD_TEACHER;

export interface LoggedInUser {
  readonly id: string;
  readonly username: string;
  readonly role: ROLES;
  readonly role_display: string;
  readonly email: string | null;
  readonly first_name: string | null;
  readonly last_name: string | null;
  readonly gender: string | null;
  readonly ethnicity: string | null;
  readonly is_send_registered: boolean | null;
  readonly is_eal: boolean | null;
  readonly is_pupil_premium: boolean | null;
  readonly school: string | null;
  readonly school_name: string | null;
  readonly year_group: string | null;
  readonly latest_assessment: string | null;
  readonly latest_activity: string | null;
  readonly has_temporary_password: boolean;
  readonly cluster_id: string | null;
}

export const LoggedInUser = SimpleRecord<LoggedInUser>({
  id: '',
  username: '',
  role: UserRoles.TEACHER,
  role_display: '',
  email: null,
  first_name: null,
  last_name: null,
  gender: null,
  ethnicity: null,
  is_send_registered: null,
  is_eal: null,
  is_pupil_premium: null,
  school: null,
  school_name: null,
  year_group: null,
  latest_assessment: null,
  latest_activity: null,
  has_temporary_password: false,
  cluster_id: null,
});

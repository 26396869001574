import { isPending } from '@dabapps/redux-requests';
import { Column, Container, ContentBox } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import * as _ from 'underscore';

import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import {
  changePasswordConfig,
  changePasswordFormFields,
} from '^/app/authenticated/users/user-management/config';
import {
  hasTemporaryPassword,
  isLoggedInUser,
} from '^/app/authenticated/users/user-management/utils';
import { AdminEditCreate, FormErrors } from '^/chadmin';
import {
  CHANGE_PASSWORD,
  changePasswordAndRedirectToProfile,
  ChangePasswordData,
  changePasswordFromTemporaryPassword,
} from '^/common/authentication/actions';
import { LoggedInUser } from '^/common/authentication/types';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import LoadingSpinner from '^/common/loading-spinner';
import { CurrentSchool } from '^/common/schools/reducers';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';

import { StoreState } from '^/store/types';

interface DispatchProps {
  changePasswordAndRedirectToProfile: typeof changePasswordAndRedirectToProfile;
  changePasswordFromTemporaryPassword: typeof changePasswordFromTemporaryPassword;
}

interface StateProps {
  errors?: ResponseErrors;
  isLoading: boolean;
  currentSchool: CurrentSchool;
  loggedInUser: LoggedInUser | null;
}

export interface RouteParamProps {
  userId: string;
}

type Props = DispatchProps & StateProps & RouteComponentProps<RouteParamProps>;

const breadcrumbs = (userId: string) => [
  { label: 'Users', path: '/users' },
  { label: 'Profile', path: `/users/edit-user/${userId}` },
  { label: 'Change Password', path: '' },
];

export class ChangePassword extends React.PureComponent<Props> {
  public render() {
    const { errors, isLoading, loggedInUser } = this.props;
    const userId = this.props.match.params.userId;
    const isEditingSelf = isLoggedInUser(loggedInUser, userId);
    return (
      <Container className="margin-top-large">
        <Breadcrumbs breadcrumbs={breadcrumbs(userId)} />
        <Column sm={10} md={8} lg={6} smPush={1} mdPush={2} lgPush={3}>
          <ContentBox>
            {isLoading ? (
              <div className="padding-vertical-large">
                <LoadingSpinner />
              </div>
            ) : isEditingSelf ? (
              <div className="change-password-form">
                <AdminEditCreate
                  createItem={this.changePassword}
                  fields={changePasswordFormFields}
                  itemOptions={changePasswordConfig}
                  formName={`change-password-${userId}`}
                  setPendingUploadInForm={_.noop}
                  saveButtonText={'Save'}
                  itemErrors={errors as FormErrors}
                />
                <FormErrorRenderer
                  formErrors={errors}
                  formErrorsKey="non_field_errors"
                />
              </div>
            ) : (
              <p>You may only change your own password.</p>
            )}
          </ContentBox>
        </Column>
      </Container>
    );
  }

  public changePassword = (data: ChangePasswordData) => {
    const { loggedInUser, match } = this.props;
    const userId = match.params.userId;
    if (hasTemporaryPassword(loggedInUser)) {
      this.props.changePasswordFromTemporaryPassword(data, '/');
    } else {
      this.props.changePasswordAndRedirectToProfile(data, userId);
    }
  };
}

function mapStateToProps({
  loggedInUser,
  responses,
  currentSchool,
}: StoreState): StateProps {
  return {
    errors: getResponseErrors(responses, CHANGE_PASSWORD),
    isLoading: isPending(responses, CHANGE_PASSWORD),
    loggedInUser,
    currentSchool,
  };
}

export default connect(mapStateToProps, {
  changePasswordAndRedirectToProfile,
  changePasswordFromTemporaryPassword,
})(ChangePassword);

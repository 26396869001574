import { Button, InputGroup } from '@dabapps/roe';
import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

export default class FileField extends React.PureComponent<WrappedFieldProps> {
  private fileInputRef: HTMLInputElement | null = null;

  public render() {
    const { input } = this.props;

    const { value, ...inputProps } = input;

    return (
      <div className="file-upload-field">
        <input
          className="display-none"
          id={inputProps.name}
          name={inputProps.name}
          type="file"
          ref={fileInput => (this.fileInputRef = fileInput)}
          {...inputProps}
        />
        <label htmlFor={inputProps.name} onClick={this.handleClick}>
          <InputGroup>
            <input
              type="text"
              value={value.length ? value[0].name : value}
              placeholder="Select a CSV file to upload"
              className="display-inline-block"
            />
            <Button className="font-weight-bold display-inline-block hollow">
              Choose file
            </Button>
          </InputGroup>
        </label>
      </div>
    );
  }

  private handleClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (this.fileInputRef) {
      this.fileInputRef.click();
    }
  };
}

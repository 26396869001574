import ApiServiceBase from './apiServiceBase';
import { ServiceType } from './serviceTypes';
import { HttpContentType } from './httpContentType';
import { QueryPath } from './queryPath';
import axios from './axiosInstance';

export default class ApiService extends ApiServiceBase {
  protected readonly serviceType: ServiceType;
  protected readonly hideFeedback: boolean;

  public constructor(serviceType: ServiceType, hideFeedback?: boolean) {
    super(serviceType);
    this.serviceType = serviceType;
    this.hideFeedback = !!hideFeedback;
  }

  public get<T = void>(
    path: QueryPath,
    isBlob?: boolean,
    cancelToken?: axios.CancelTokenSource
  ): Promise<T> {
    const config = this.getConfig(HttpContentType.Json, cancelToken?.token);
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance
        .get(
          this.getUrl(path),
          isBlob ? { ...config, responseType: HttpContentType.Blob } : config
        )
        .then(
          (res: any) => {
            isBlob
              ? resolve(({
                  headers: res.headers,
                  data: res.data.data ? res.data.data : res.data,
                } as unknown) as T)
              : resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            reject(this.processError(err));
          }
        )
    );
  }

  public post<T = void>(path: QueryPath, body: any): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);

    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance
        .post(this.getUrl(path), body, config) //Adding headers
        .then(
          (res: any) => {
            resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            reject(this.processError(err));
          }
        )
    );
  }

  public patch<T = void>(path: QueryPath, body: any): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);

    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance
        .patch(this.getUrl(path), body, config) //Adding headers
        .then(
          (res: any) => {
            resolve(res.data.data ? res.data.data : res.data);
          },
          (err: any) => {
            reject(this.processError(err));
          }
        )
    );
  }

  public put<T = void>(path: QueryPath, body?: any): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);

    const instance = this.getAxiosInstance();

    return new Promise<T>((resolve, reject) =>
      instance.put(this.getUrl(path), body, config).then(
        (res: any) => {
          resolve(res.data.data ? res.data.data : res.data);
        },
        (err: any) => {
          reject(this.processError(err));
        }
      )
    );
  }

  public delete<T = void>(path: QueryPath): Promise<T> {
    const config = this.getConfig(HttpContentType.Json);
    const instance = this.getAxiosInstance();
    return new Promise<T>((resolve, reject) =>
      instance.delete(this.getUrl(path), config).then(
        (res: any) => {
          // pass empty object for delete API which will have 204 status code but no response
          resolve(res.data.data ? res.data.data : res.data || {});
        },
        (err: any) => {
          reject(this.processError(err));
        }
      )
    );
  }

  public postMultipart<T = void>(path: QueryPath, data: any): Promise<T> {
    const config = this.getConfig(HttpContentType.MultipartFormData);

    const instance = this.getAxiosInstance();

    // Creating a Form Data
    const formData = new FormData();
    //adding all the values obtained to form data
    Object.keys(data).map((item) => formData.append(item, data[item]));

    return new Promise<T>((resolve, reject) => {
      return instance.post(this.getUrl(path), formData, config).then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          reject(this.processError(err));
        }
      );
    });
  }
}

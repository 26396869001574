import ApiService from '../apiService';
import { ServiceType } from '../serviceTypes';
import { SchoolSettingsResponse, StudentProfileResponse } from './types';

class ProfileApiClass {
  service: ApiService = new ApiService(ServiceType.PROFILE);
  public getStudentProfile = (
    id: string
  ): Promise<StudentProfileResponse> | StudentProfileResponse => {
    return this.service.get({
      route: ['question-response', id],
      query: {
        pageSize: 100,
      },
    });
  };
  public getSocialSettingsList = ():
    | Promise<SchoolSettingsResponse>
    | SchoolSettingsResponse => {
    return this.service.get({
      route: ['social-settings-list'],
      query: {},
    });
  };
}

const ProfileApi = new ProfileApiClass();

export default ProfileApi;

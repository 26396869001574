import { getCollectionByName } from '@dabapps/redux-api-collections';
import { GET_COLLECTION } from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import * as React from 'react';
import { connect } from 'react-redux';

import { MESSAGES_COLUMNS } from '^/app/authenticated/activities/activity-page/config';
import { MessageListItem } from '^/app/authenticated/messaging/types';
import { AdminList } from '^/chadmin';
import { collections } from '^/common/collections';
import { PAGE_SIZE } from '^/common/constants';
import ErrorMessage from '^/common/error-handling/error-message';
import LoadingSpinner from '^/common/loading-spinner';
import { StoreState } from '^/store/types';

const {
  actions: { getCollection },
} = collections;

export const MESSAGES_LIST_ROUTE = 'messages';

interface ExternalProps {
  userId: string;
}

interface StateProps {
  messages: ReadonlyArray<MessageListItem>;
  isLoading: boolean;
  count: number;
  currentPage: number;
  hasFailedMessages: boolean;
}

interface DispatchProps {
  getCollection: typeof getCollection;
}

type Props = ExternalProps & StateProps & DispatchProps;

export class MessagesTable extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.userId && this.fetchMessages();
  }

  componentDidUpdate(prevProps: Props) {
    const { userId } = this.props;
    if (prevProps.userId !== userId) {
      this.fetchMessages();
    }
  }
  public render() {
    const {
      messages,
      isLoading,
      count,
      currentPage,
      hasFailedMessages,
    } = this.props;
    return isLoading ? (
      <LoadingSpinner />
    ) : hasFailedMessages ? (
      <div className="padding-vertical-large">
        <ErrorMessage />
      </div>
    ) : (
      <div className="table-top-align">
        <AdminList
          items={messages}
          itemCount={count}
          columns={MESSAGES_COLUMNS}
          listName="list"
          changePage={this.changePage}
          page={currentPage}
          pageSize={PAGE_SIZE}
        />
      </div>
    );
  }

  private changePage = (page: number) => {
    this.fetchMessages(page);
  };

  private fetchMessages = (page = 1) => {
    this.props.getCollection(
      MESSAGES_LIST_ROUTE,
      {
        filters: {
          student: this.props.userId,
        },
        pageSize: PAGE_SIZE,
        page,
      },
      this.props.userId
    );
  };
}

function mapStateToProps(state: StoreState, props: ExternalProps) {
  const messagesCollection = getCollectionByName(
    state.collections,
    MESSAGES_LIST_ROUTE,
    props.userId
  );
  return {
    messages: messagesCollection.results,
    count: messagesCollection.count,
    currentPage: messagesCollection.page,
    isLoading: isPending(state.responses, GET_COLLECTION, MESSAGES_LIST_ROUTE),
    hasFailedMessages: hasFailed(
      state.responses,
      GET_COLLECTION,
      MESSAGES_LIST_ROUTE
    ),
  };
}

export default connect(mapStateToProps, {
  getCollection,
})(MessagesTable);

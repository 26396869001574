import { ISchool } from '^/api-services/schools/types';
import { LoggedInUser } from '^/common/authentication/types';

export interface IAddClusterModal {
  closeModal: () => void;
  parentTreeID: null | string;
  clusterID: null | string;
  clusterManager: null | LoggedInUser;
}

export interface ISchoolModal {
  closeModal: () => void;
  clusterID: string;
}

export enum ClusterAdminColumnsIndex {
  KEY = 'key',
  FNAME = 'FName',
  LNAME = 'LName',
  EMAIL = 'Email',
  TEMP = 'TempPassword',
  ISMANAGER = 'IsManager',
  OPERATION = 'Operation',
  TYPE = 'type',
}

export enum ClusterAdminTableTypes {
  ADD = 'add',
  DELETE = 'delete',
  EDIT = 'edit',
  EXISTING = 'existing',
}

export interface IClusterAdminRecord {
  [ClusterAdminColumnsIndex.KEY]: string;
  [ClusterAdminColumnsIndex.FNAME]: string;
  [ClusterAdminColumnsIndex.LNAME]: string;
  [ClusterAdminColumnsIndex.TEMP]: string;
  [ClusterAdminColumnsIndex.EMAIL]: string;
  [ClusterAdminColumnsIndex.ISMANAGER]: boolean;
  [ClusterAdminColumnsIndex.TYPE]: ClusterAdminTableTypes;
}

export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'string' | 'email' | 'boolean';
  record: IClusterAdminRecord;
  index: number;
  children: React.ReactNode;
}

export enum ITreeNodeTypes {
  CLUSTER = 'cluster', // used as add cluster
  SCHOOL = 'school',
  EDIT_CLUSTER = 'edit cluster',
  DELETE_CLUSTER = 'delete cluster',
}
export interface ITreeNode {
  title: string;
  key: string;
  currentNodeKey: string;
  nodeType: ITreeNodeTypes;
  isLeaf?: boolean;
  children?: ITreeNode[];
}
export interface ISchoolRecord extends ISchool {
  [ClusterAdminColumnsIndex.TYPE]: ClusterAdminTableTypes;
}

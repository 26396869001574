import * as React from 'react';

import { Column, Row } from '@dabapps/roe';
import classNames from 'classnames';
import * as _ from 'underscore';
import Loading from '../common/Loading';
import { FormErrors, IBasicDictionary } from '../types';
import { getIn } from '../utils';
import { IItemConfig } from './dynamic-forms/field-types/types';
import Form from './dynamic-forms/Form';

export interface IProps<T extends {}> {
  className?: string;
  createItem?: (data: T) => void;
  defaultValues?: Partial<T>;
  fields: ReadonlyArray<string>;
  formName: string;
  getFields?: (item: T) => ReadonlyArray<string>;
  getInitialValues?: (item: T) => IBasicDictionary;
  getTitle?: (item: T) => string;
  hideMessages?: boolean;
  item?: T;
  itemOptions: IItemConfig;
  itemIsPending?: boolean;
  itemHasSucceeded?: boolean;
  itemHasFailed?: boolean;
  itemErrors?: FormErrors;
  patchItem?: (data: Partial<T>) => void;
  readOnly?: boolean;
  loading?: boolean;
  saveButtonText?: string;
  setPendingUploadInForm: (file?: File | string | undefined) => void;
  title?: string;
  transformDataToSubmit?: (data: T) => Partial<T>;
  additionalSuccessMessage?: React.ReactNode;
  additionalFailureMessage?: React.ReactNode;
}

export default class AdminEditCreate<T> extends React.PureComponent<
  IProps<T>,
  {}
> {
  public constructor(props: IProps<T>) {
    super(props);
    this.isEditing = this.isEditing.bind(this);
    this.submitData = this.submitData.bind(this);
    this.getFields = this.getFields.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  public render() {
    const { item, itemOptions, loading } = this.props;
    const failedLoading = !itemOptions || (this.isEditing() && !item);

    if (loading) {
      return <Loading />;
    } else if (failedLoading) {
      return <div>Failed to load item.</div>;
    } else {
      return this.renderItem();
    }
  }

  private isEditing() {
    return !!this.props.item;
  }

  private submitData(data: T) {
    const {
      defaultValues,
      transformDataToSubmit,
      patchItem,
      createItem,
    } = this.props;
    const dataToSubmit = transformDataToSubmit
      ? transformDataToSubmit(data)
      : data;

    if (this.isEditing()) {
      if (patchItem) {
        patchItem(dataToSubmit);
      }
    } else {
      const defaultedData = _.extend({}, defaultValues, data);
      if (createItem) {
        createItem(defaultedData);
      }
    }
  }

  private getFields() {
    const { getFields, item, fields } = this.props;
    if (item && getFields) {
      return getFields(item);
    }
    return fields;
  }

  private getInitialValues(): IBasicDictionary {
    const { item, getInitialValues } = this.props;

    if (!item || !this.isEditing()) {
      return {};
    }

    if (getInitialValues) {
      return getInitialValues(item);
    }

    return item;
  }

  private renderItem() {
    const {
      item,
      itemOptions,
      title,
      getTitle,
      hideMessages,
      formName,
      readOnly,
      className,
      saveButtonText,
      itemIsPending,
      itemHasSucceeded,
      itemHasFailed,
      itemErrors,
      additionalSuccessMessage,
      additionalFailureMessage,
    } = this.props;

    const fields = this.getFields();

    const fieldConfig: IItemConfig = _.mapObject(
      _.object(fields, []),
      /* tslint:disable-next-line:variable-name */
      (_val: any, field: string) => getIn(itemOptions, field, {})
    );

    return (
      <div>
        <Row>
          <Column xs={12}>
            <Form
              title={getTitle && item ? getTitle(item) : title}
              className={classNames('admin-edit-create-form', className)}
              fieldClassName="admin-edit-create-field"
              onSubmit={this.submitData}
              fields={fields}
              form={formName}
              formName={formName}
              initialValues={this.getInitialValues()}
              fieldConfig={fieldConfig}
              setPendingUploadInForm={this.props.setPendingUploadInForm}
              readOnly={readOnly}
              isPending={itemIsPending}
              hasSucceeded={itemHasSucceeded}
              hasFailed={itemHasFailed}
              errors={itemErrors}
              isEditing={this.isEditing()}
              saveButtonText={
                saveButtonText || (this.isEditing() ? 'Save' : 'Create')
              }
              hideMessages={hideMessages}
              additionalSuccessMessage={additionalSuccessMessage}
              additionalFailureMessage={additionalFailureMessage}
            />
          </Column>
        </Row>
      </div>
    );
  }
}

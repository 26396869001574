import { Button, Column, FormGroup, ModalFooter, Row } from '@dabapps/roe';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import {
  ASSESSMENT_STATUS_DETAILS,
  ASSESSMENT_STATUSES,
} from '^/app/authenticated/assessments/constants';
import { Status } from '^/app/authenticated/assessments/types';
import StatusPill from '^/common/status-pill';

export const LIGHT_ASSESSMENT_FORM = 'light-assessment';

interface LightAssessmentValue {
  status: Status;
}

interface ExternalProps {
  assessmentValue?: Status;
}

export type LightAssessmentFormProps = ExternalProps &
  InjectedFormProps<LightAssessmentValue, ExternalProps>;

export function LightAssessmentForm({
  handleSubmit,
  assessmentValue,
}: LightAssessmentFormProps) {
  return (
    <form onSubmit={handleSubmit} className="assessment-form">
      <div className="padding-vertical-base">
        <h3>Pen Portrait</h3>
        <p>
          Consider which pen portrait best fits and provides the most accurate
          description for how each child is currently engaging with physical
          activity. Draw upon what you know about the child from the data that
          they have logged together with your professional judgment.
        </p>
      </div>
      {ASSESSMENT_STATUSES.map((status: Status) => (
        <FormGroup>
          <Row>
            <Column sm={1} md={2}>
              <Field
                name="status"
                component="input"
                type="radio"
                value={status}
              />
            </Column>
            <Column sm={11} md={10}>
              <StatusPill status={status} />
              <p>{ASSESSMENT_STATUS_DETAILS[status].notes}</p>
            </Column>
          </Row>
        </FormGroup>
      ))}
      <ModalFooter>
        <Button
          type="submit"
          className="small pill"
          disabled={!assessmentValue}
        >
          Submit
        </Button>
      </ModalFooter>
    </form>
  );
}

export default reduxForm<LightAssessmentValue, ExternalProps>({
  form: LIGHT_ASSESSMENT_FORM,
})(LightAssessmentForm);

import { Checkbox, Form, Input, Row, Switch, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SchoolsApi from '^/api-services/schools/schools.service';
import axios from '^/api-services/axiosInstance';
import {
  ICreateSchoolRequest,
  IMessageRules,
} from '^/api-services/schools/types';
import useAPI from '^/api-services/useApi';

import { ModalPopup } from '^/app/components';
import { SCHOOLS_LABELS } from '^/common/labels-english';
import { toSentenceCase } from '^/common/utils/text-utils';
import { Pagination } from '^/api-services/activities/types';
const { Option } = Select;
type Props = {
  id: string;
  isTeacher?: boolean;
  onClose: (refresh?: boolean) => void;
};

export const SchoolModal: React.FC<Props> = (props: Props) => {
  const { id, isTeacher, onClose } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);
  const labels = SCHOOLS_LABELS.modal;
  const [form] = Form.useForm();
  const validator = [
    {
      required: true,
      max: 255,
    },
  ];
  const { callAPI: createSchool, ...createSchoolResponse } = useAPI(
    SchoolsApi.createSchool
  );
  const { callAPI: updateSchool, ...updateSchoolResponse } = useAPI(
    SchoolsApi.updateSchool
  );
  const { callAPI: fetchSchool, ...schoolResponse } = useAPI(
    SchoolsApi.getSchoolDetails
  );
  const { callAPI: fetchRules, ...rulesResponse } = useAPI<
    void,
    Pagination<IMessageRules>
  >(SchoolsApi.getMessageRules);

  useEffect(() => {
    fetchRules();
  }, []);

  useEffect(() => {
    if (rulesResponse.response && !id) {
      // for create school, enable all the rules by default
      form.setFieldsValue({
        ['automated_message_rules_opted_in']: rulesResponse.response.results.map(
          (rule) => rule.id
        ),
      });
    }
  }, [rulesResponse.response]);

  useEffect(() => {
    if (id) {
      // edit school. Fetch school details
      fetchSchool(id);
    }
  }, [id]);

  const onFormSubmit = (values: ICreateSchoolRequest) => {
    if (id) {
      updateSchool({ id: id, ...values });
    } else {
      createSchool(values);
    }
  };

  const onSuccess = (message: string) => {
    toast.success(message);
    setIsModalOpen(false);
    onClose(true);
  };

  const onError = ({ error }: { error: axios.AxiosError }) => {
    const data = error?.response?.data;
    const errorKey = data ? Object.keys(data)[0] : '';
    if (errorKey && Array.isArray(data[errorKey]) && data[errorKey].length) {
      data[errorKey][0] && toast.error(toSentenceCase(data[errorKey][0]));
    }
  };

  useEffect(() => {
    if (createSchoolResponse.response) {
      onSuccess(labels.onCreateSuccessMsg);
    } else if (createSchoolResponse.error) {
      onError(
        (createSchoolResponse.error as unknown) as { error: axios.AxiosError }
      );
    }
  }, [createSchoolResponse]);

  useEffect(() => {
    if (updateSchoolResponse.response) {
      onSuccess(labels.onUpdateSuccessMsg);
    } else if (updateSchoolResponse.error) {
      onError(
        (updateSchoolResponse.error as unknown) as { error: axios.AxiosError }
      );
    }
  }, [updateSchoolResponse]);

  return (
    <ModalPopup
      title={labels.title(id)}
      visible={isModalOpen}
      width="50%"
      onCancel={() => {
        setIsModalOpen(false);
        onClose(false);
      }}
      okText={labels.submitBtn}
      onOk={() => form.submit()}
      confirmLoading={
        createSchoolResponse.loading || updateSchoolResponse.loading
      }
    >
      {!schoolResponse.loading && (
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          colon={false}
          labelAlign="left"
          validateMessages={{ required: labels.required }}
          onFinish={onFormSubmit}
          initialValues={schoolResponse?.response}
          preserve={false}
        >
          {!isTeacher && (
            <>
              <Form.Item
                name="name"
                label={labels.schoolName}
                rules={validator}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="location"
                label={labels.location}
                rules={validator}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="country"
                label={labels.country}
                rules={validator}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="has_student_photo_uploads_enabled"
                label={labels.photoUpload}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item name="sso" label={labels.sso} rules={validator}>
                <Select placeholder="Please an option">
                  <Option value="MICROSOFT">Microsoft</Option>
                  <Option value="GOOGLE">Google</Option>
                  <Option value="NONE">None</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="wonde_id"
                label={labels.wonde_id}
                rules={[
                  {
                    required: false,
                    max: 50,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="automated_message_rules_opted_in"
            label={labels.ruleSet}
            wrapperCol={{ span: 24 }}
            labelCol={{ span: 24 }}
          >
            <Checkbox.Group>
              <Row>
                {rulesResponse.response?.results.map((rule) => (
                  <Checkbox className="w-100" key={rule.id} value={rule.id}>
                    {rule.description}
                  </Checkbox>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
        </Form>
      )}
    </ModalPopup>
  );
};

import { Footer } from '@dabapps/roe';
import moment from 'moment';
import * as React from 'react';
import { Link } from 'react-router-dom';

export default function NavFooter() {
  return (
    <Footer sticky>
      copyright {moment().format('YYYY')} miMove{' '}
      <Link to="/terms-and-conditions">terms & conditions</Link>{' '}
      {/* TODO: cleanup */}
      {/* <Link to="/privacy-policy">privacy policy</Link> */}
      <a
        href="https://mimoveapp.com/wp-content/uploads/2020/11/Privacy-Policy.pdf"
        target="_blank"
        rel="noreferrer"
      >
        privacy policy
      </a>
    </Footer>
  );
}

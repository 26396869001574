import {
  AsyncActionSet,
  getErrorData,
  ResponsesReducerState,
} from '@dabapps/redux-requests';
import { Dict } from '@dabapps/simple-records';
import * as _ from 'underscore';

export type ResponseErrorList = ReadonlyArray<string>;

export type ResponseError =
  | ResponseErrorList
  | string
  | ReadonlyArray<Dict<ReadonlyArray<string> | string>>;

export type ResponseErrors = Dict<ResponseError>;

export function getResponseErrors(
  state: ResponsesReducerState,
  actionSet: AsyncActionSet,
  tag?: string
): ResponseErrors | undefined {
  const data = getErrorData(state, actionSet, tag);
  if (data && data.response) {
    const errors = data.response.data;
    if (_.isObject(errors)) {
      return errors as any;
    }
  }
  return undefined;
}

export function isResponseErrorList(
  error: ResponseError
): error is ResponseErrorList {
  return Array.isArray(error);
}

import {
  ETHNICITIES,
  ETHNICITY_DISPLAY,
  GENDER_DISPLAY,
  GENDERS,
} from '^/common/users/constants';

import { generateBooleanFilterOptions } from './utils';

export const YEAR_FILTER = 'year_group';
export const CLASS_FILTER = 'class_group';
export const GROUP_FILTER = 'extracurricular_class_group';
export const GENDER_FILTER = 'gender';
export const SEND_FILTER = 'is_send_registered';
export const ETHNICITY_FILTER = 'ethnicity';
export const EAL_FILTER = 'is_eal';
export const ASSESSMENT_STATUS_FILTER = 'latest_assessment__status';
export const PUPIL_PREMIUM_FILTER = 'is_pupil_premium';

import {
  ASSESSMENT_STATUS_DETAILS,
  ASSESSMENT_STATUSES,
} from '^/app/authenticated/assessments/constants';
import { FilterOption } from './types';

export const genderOptions: ReadonlyArray<FilterOption> = GENDERS.map(
  (gender) => ({
    value: gender,
    label: GENDER_DISPLAY[gender],
  })
);

export const ethnicityOptions: ReadonlyArray<FilterOption> = ETHNICITIES.map(
  (gender) => ({
    value: gender,
    label: ETHNICITY_DISPLAY[gender],
  })
);

export const ealOptions = generateBooleanFilterOptions('E.A.L');
export const sendOptions = generateBooleanFilterOptions('SEND');
export const pupilPremiumOptions = generateBooleanFilterOptions(
  'Pupil Premium'
);

export const statusOptions = ASSESSMENT_STATUSES.map((status) => ({
  value: status,
  label: ASSESSMENT_STATUS_DETAILS[status].displayName,
}));

import * as React from 'react';
import * as _ from 'underscore';

import Select from 'react-select';
import FieldGroup from '../FieldGroup';
import { IWrappedFieldProps, TChoice, TChoices } from './types';
import { getChoiceLabel, isSingularChoiceValue } from './utils';
import { ADD_USER_YEAR_GROUP, ADD_CLASS } from '^/common/labels-english';

export default class FieldSelect extends React.PureComponent<
  React.HTMLProps<JSX.Element> & IWrappedFieldProps,
  unknown
> {
  state = {
    customMenuOpen: false,
  };
  inputWhenSearchEmpty = '';

  private getValue = () => {
    const {
      value,
      config: { choices = [] },
    } = this.props;
    return _.find(choices, (choice) => value === choice.value);
  };

  private onChange = (value: TChoice | TChoices | null | undefined) => {
    if (!value) {
      return this.props.onChange(value);
    } else if (isSingularChoiceValue(value)) {
      return this.props.onChange(value.value);
    }
    // We are not expecting multiple values here as this is a single select component so do nothing
  };

  private createableOnChange = (
    value: TChoice | TChoices | null | undefined
  ) => {
    const { choices, manuallAddCB } = this.props.config;
    const enteredValue = (value as any).value;
    const index = (choices || []).findIndex(
      (ele: any) => ele.value === enteredValue
    );
    if (index === -1 && manuallAddCB && value) {
      const newValue = { id: enteredValue, name: enteredValue };
      return manuallAddCB(newValue, () => {
        return this.onChange({
          display_name: newValue.name,
          value: newValue.id,
        });
      });
    }
    return this.onChange(value);
  };

  onSearchEmpty = (val: string) => {
    const { config } = this.props;
    this.inputWhenSearchEmpty = val;
    return config?.label === 'Class'
      ? ADD_CLASS(val)
      : ADD_USER_YEAR_GROUP(val);
  };

  onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.keyCode === 13 && this.inputWhenSearchEmpty) {
      e.preventDefault();
      this.createableOnChange({
        value: this.inputWhenSearchEmpty,
        display_name: this.inputWhenSearchEmpty,
      });
      this.setState({ customMenuOpen: false });
    }
  };

  public render() {
    const { readOnly, config, name, errors, value } = this.props;
    const { customMenuOpen } = this.state;

    const {
      choices = [],
      placeholder,
      clearable = true,
      canManuallyAdd,
      manuallAddCB,
    } = config;

    if (readOnly) {
      // find matching choice
      const [display = { value: '', display_name: value }] =
        choices && choices.filter((choice) => choice.value === value);

      return (
        <FieldGroup config={config} errors={errors}>
          <span className="input">{display && display.display_name}</span>
        </FieldGroup>
      );
    }

    const options = [...choices];

    if (canManuallyAdd && manuallAddCB) {
      return (
        <FieldGroup config={config} errors={errors}>
          <Select
            isClearable={clearable}
            className="select"
            getOptionLabel={getChoiceLabel}
            options={options}
            menuIsOpen={customMenuOpen}
            onMenuOpen={() => this.setState({ customMenuOpen: true })}
            onMenuClose={() => this.setState({ customMenuOpen: false })}
            value={this.getValue()}
            name={name}
            placeholder={placeholder}
            onChange={this.createableOnChange}
            noOptionsMessage={(obj) => this.onSearchEmpty(obj.inputValue)}
            onKeyDown={this.onKeyDown}
            filterOption={({ label }, input) => label.includes(input)}
          />
        </FieldGroup>
      );
    }

    return (
      <FieldGroup config={config} errors={errors}>
        <Select
          isClearable={clearable}
          className="select"
          getOptionLabel={getChoiceLabel}
          options={options}
          value={this.getValue()}
          name={name}
          placeholder={placeholder}
          onChange={this.onChange}
        />
      </FieldGroup>
    );
  }
}

export const OPEN_MODAL = 'OPEN_MODAL';
export function openModal(modal: React.ReactNode) {
  return {
    payload: modal,
    type: OPEN_MODAL,
  };
}

export const CLOSE_MODAL = 'CLOSE_MODAL';
export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

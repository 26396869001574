import {
  hasFailed,
  isPending,
  resetRequestState,
} from '@dabapps/redux-requests';
import { Modal, ModalCloseIcon, ModalHeader } from '@dabapps/roe';
import { closeModal } from '^/common/modals/actions';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';
import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { ValueType } from 'react-select/lib/types';
import { change } from 'redux-form';
import _ from 'underscore';
import { CREATE_MESSAGE, createMessage } from './actions';
import MessageForm, {
  CREATE_MESSAGE_FORM,
  MessagePresetOption,
} from './message-form';
import { NewMessage } from './types';
import { transformFilterOptionsToCollectionFilter } from '../activities/overview/students/filters/utils';
import { CurrentSchool } from '^/common/schools/reducers';

function isSingularMessageOption(
  value: MessagePresetOption | MessagePresetOption[]
): value is MessagePresetOption {
  return !Array.isArray(value);
}

interface StateProps extends Pick<StoreState, 'selectedDashboardFilters'> {
  currentSchool: CurrentSchool;
  hasMessageFailed: boolean;
  isLoading: boolean;
  errors?: ResponseErrors;
  preset?: string;
}

interface DispatchProps {
  createMessage: typeof createMessage;
  resetRequestState: typeof resetRequestState;
  change: typeof change;
  closeModal(): void;
}

interface ExternalProps {
  studentName?: string;
  studentIds: ReadonlyArray<string>;
  isAllStudents?: boolean;
  count?: number;
}

type Props = StateProps & DispatchProps & ExternalProps;

export class MessageModal extends React.PureComponent<Props> {
  public componentWillUnmount() {
    this.props.resetRequestState(CREATE_MESSAGE);
  }

  public render() {
    const {
      errors,
      hasMessageFailed,
      isLoading,
      studentName,
      studentIds,
      count,
    } = this.props;

    return (
      <Modal onClickOutside={_.noop} className="message-modal">
        <ModalHeader>
          <ModalCloseIcon onClick={this.props.closeModal}>
            <CloseOutlined />
          </ModalCloseIcon>
          <h2 className="font-size-large margin-vertical-none">
            MESSAGE{' '}
            {studentName
              ? studentName.toUpperCase()
              : studentIds && studentIds.length
              ? `${studentIds.length} STUDENTS`
              : count
              ? `${count} STUDENTS`
              : 'STUDENTS'}
          </h2>
        </ModalHeader>
        <MessageForm
          onSubmit={this.sendMessage}
          isLoading={isLoading}
          hasFailed={hasMessageFailed}
          errors={errors}
          updateMessageText={this.updateMessageText}
        />
      </Modal>
    );
  }

  private sendMessage = (data: NewMessage) => {
    const { selectedDashboardFilters } = this.props;
    const transformedFilters = transformFilterOptionsToCollectionFilter(
      selectedDashboardFilters
    );
    this.props.createMessage(data, this.props.studentIds, {
      ...transformedFilters,
      school: this.props.currentSchool,
      search: selectedDashboardFilters.searchQuery,
    });
  };

  private updateMessageText = (
    messagePresetOption: ValueType<MessagePresetOption>
  ) => {
    if (messagePresetOption && isSingularMessageOption(messagePresetOption)) {
      this.props.change(CREATE_MESSAGE_FORM, 'text', messagePresetOption.value);
    }
  };
}

export function mapStateToProps({
  currentSchool,
  responses,
  selectedDashboardFilters,
}: StoreState) {
  return {
    errors: getResponseErrors(responses, CREATE_MESSAGE),
    isLoading: isPending(responses, CREATE_MESSAGE),
    hasMessageFailed: hasFailed(responses, CREATE_MESSAGE),
    selectedDashboardFilters,
    currentSchool,
  };
}

export default connect(mapStateToProps, {
  change,
  createMessage,
  closeModal,
  resetRequestState,
})(MessageModal);

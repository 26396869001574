import { Collections as CollectionsReducerFactory } from '@dabapps/redux-api-collections';

import { DashboardStudentListUser } from '^/app/authenticated/activities/overview/students/types';
import { AssessmentListItem } from '^/app/authenticated/assessments/types';
import {
  ClassListClass,
  ClassListUser,
} from '^/app/authenticated/classes/types';
import { MessageListItem } from '^/app/authenticated/messaging/types';
import { BasicSchool } from '^/app/authenticated/school-switcher/types';
import {
  AddClassUserListUser,
  UserListUser,
} from '^/app/authenticated/users/types';
import { ClassGroupOption } from '^/app/authenticated/users/user-management/types';
import { SchoolSettingsList } from '^/app/pages/school-settings/types';
import { Items, itemToRecordMapping } from '^/common/items';

export interface Collections {
  assessments: AssessmentListItem;
  messages: MessageListItem;
  users: UserListUser;
  'users/user-options': AddClassUserListUser;
  'users/students': DashboardStudentListUser;
  'schools/school-switcher': BasicSchool;
  'schools/schools-list': BasicSchool;
  'cohorts/class-groups': ClassListClass;
  'cohorts/class-group-options': ClassGroupOption;
  'cohorts/class-user-list': ClassListUser;
  'profile/social-settings-list': SchoolSettingsList;
}

export const collectionToRecordMapping = {
  assessments: AssessmentListItem,
  messages: MessageListItem,
  users: UserListUser,
  'users/user-options': AddClassUserListUser,
  'users/students': DashboardStudentListUser,
  'schools/school-switcher': BasicSchool,
  'schools/schools-list': BasicSchool,
  'cohorts/class-groups': ClassListClass,
  'cohorts/class-group-options': ClassGroupOption,
  'cohorts/class-user-list': ClassListUser,
  'profile/social-settings-list': SchoolSettingsList,
};

export const collections = CollectionsReducerFactory<Collections, Items>(
  collectionToRecordMapping,
  itemToRecordMapping
);

export const RouteConstants = {
  dashboard: {
    label: 'Dashboard',
    route: '/dashboard',
  },
  global: {
    label: 'Global Dashboard',
    route: '/global',
  },
  overview: {
    label: 'Activities',
    route: '/activities',
  },
  recurringActivities: {
    label: 'Recurring Activities',
    route: '/recurring-activities',
  },
  cluster: {
    label: 'Cluster Management',
    route: '/cluster',
  },
  schools: {
    label: 'Schools',
    route: '/schools',
  },
  schoolSettings: {
    label: 'School Settings',
    route: '/school-settings',
  },
  support: {
    label: 'miSupport',
    route: 'https://support.mimoveapp.com/home',
  },
};

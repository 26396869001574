import { Pagination } from '../activities/types';

export type StudentProfileResponse = Pagination<IQuestion>;

interface IQuestion {
  question: {
    choice_type: string;
    id: string;
    identifier: string | null;
    number: number;
    text: string;
  };
  choices: { id: string; text: string }[];
}

export type SchoolSettingsResponse = Pagination<ISchoolSettings>;
interface ISchoolSettings {
  id: string;
  year_group: string;
  own_class: boolean;
  own_year: boolean;
  own_groups: boolean;
  all: boolean;
}

export enum AboutYouIdentifier {
  ACTIVITY = 'ACTIVITIES',
  WHEN = 'WHEN',
}

export enum ChoiceType {
  MULTIPLE = 'MULTIPLE_CHOICE',
}
